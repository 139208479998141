import { state } from '@angular/animations';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { FilterModel } from 'src/app/models/filter.model';
import { AnalysisFilters, FetchGameTypeListRequest, FetchGameTypeListResponse, FetchSessionListRequest, FetchSessionListResponse } from 'src/app/models/session.model';

import { SessionService } from '../../services/session.service';
import { FetchUserStatsRequestAction } from '../actions/login.actions';

import * as ActionTypes from '../actions/session.actions';
import { StoreState } from '../store';

@Injectable()
export class SessionEffects {
  constructor(
    private actions$: Actions,
    private sessionService: SessionService,
    private store: Store<StoreState>
  ) {}

  fetchGameTypeList$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchGameTypeListRequestAction),
    switchMap(action => this.sessionService.fetchGameTypeList(action).pipe(
      map(res => ActionTypes.FetchGameTypeListResponseAction(res)),
    )),
  ));

  fetchGameTypeListResponse$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchGameTypeListResponseAction),
    filter((res: FetchGameTypeListResponse) => !res.error),
    tap((req: FetchGameTypeListResponse) => this.store.dispatch(FetchUserStatsRequestAction({
      userId: req.userId
    })))
  ), { dispatch: false });

  fetchPlayedGameTypeList$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchPlayedGameTypeListRequestAction),
    withLatestFrom(this.store),
    switchMap(([action, store]: [FetchGameTypeListRequest, StoreState]) =>
      this.sessionService.fetchPlayedGameList(action, store.session.gameTypeList).pipe(
        map(res => ActionTypes.FetchPlayedGameTypeListResponseAction(res)),
      )),
  ));

  fetchMachineList$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchMachineListRequestAction),
    switchMap(action => this.sessionService.fetchMachineList(action).pipe(
      map(res => ActionTypes.FetchMachineListResponseAction(res)),
    )),
  ));

  fetchMachineGroupList$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchMachineGroupListRequestAction),
    switchMap(action => this.sessionService.fetchMachineGroupList(action).pipe(
      map(res => ActionTypes.FetchMachineGroupListResponseAction(res)),
    )),
  ));

  fetchPlayedMachineGroupList$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchPlayedMachineGroupListRequestAction),
    switchMap(action => this.sessionService.fetchPlayedMachineGroupList(action).pipe(
      map(res => ActionTypes.FetchPlayedMachineGroupListResponseAction(res)),
    )),
  ));

  fetchSessionList$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchSessionListRequestAction),
    withLatestFrom(this.store),
    switchMap(([action, store]: [FetchSessionListRequest, StoreState]) => this.sessionService.fetchSessionList(action).pipe(
      map(res => {
        if (store.login.user?.esaData?.userTypeID === '3') {
          if ((!!action.filters && action.filters.usr && action.filters.usr !== store.session.currentUsername) ||
          ((!action.filters || !action.filters.usr) && store.session.currentUsername !== store.login.user?.esaId)) {
            this.store.dispatch(ActionTypes.FetchPlayedGameTypeListRequestAction({userId: action.userId, username: action.filters?.usr ? action.filters.usr : action.esaId}));
            this.store.dispatch(ActionTypes.FetchPlayedMachineGroupListRequestAction({userId: action.userId, username: action.filters?.usr ? action.filters.usr : action.esaId}));
          }
        }
        return ActionTypes.FetchSessionListResponseAction(res);
      }))
  )));

  fetchCoachedUserList$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchCoachedUserListRequestAction),
    switchMap(action => this.sessionService.fetchCoachedUserList(action).pipe(
      map(res => ActionTypes.FetchCoachedUserListResponseAction(res)),
    )),
  ));

  fetchSession$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchSessionRequestAction),
    switchMap(action => this.sessionService.fetchSession(action).pipe(
      map(res => ActionTypes.FetchSessionResponseAction(res)),
    )),
  ));
}
