import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, timer } from 'rxjs';
import { StoreState } from 'src/app/state-management/store';

import { State as SessionState } from 'src/app/state-management/reducers/session.reducer';
import { debounce, distinctUntilChanged, takeUntil, withLatestFrom } from 'rxjs/operators';
import { FetchPlayedGameTypeListRequestAction, FetchPlayedMachineGroupListRequestAction, FetchSessionListRequestAction } from 'src/app/state-management/actions/session.actions';

import * as moment from 'moment';
import { AnalysisFilters, GameTypeModel, SessionFilterUtil } from 'src/app/models/session.model';
import { FilterModel } from 'src/app/models/filter.model';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-analysis-filters',
  templateUrl: './analysis-filters.component.html',
  styleUrls: ['./analysis-filters.component.sass']
})
export class AnalysisFiltersComponent implements OnDestroy, OnInit, OnChanges {
  @Input() userId: number | null | undefined = null;
  @Input() username: string | undefined;
  @Input() esaId: string | undefined;
  @Input() fullName: string | undefined;
  @Input() isModal = false;
  @Input() clearTrigger = false;
  @Output() emitFormFilter = new EventEmitter<AnalysisFilters>();

  public session$: Observable<SessionState>;
  public fg: UntypedFormGroup;

  private cancelValueInit  = new Subject();
  private cancelValueInit$ = this.cancelValueInit.asObservable();

  private destroy  = new Subject();
  private destroy$ = this.destroy.asObservable();

  public maxDate = new Date();

  public durationArray = SessionFilterUtil.getIntervalList();

  private noDebouncePatch = false;
  private userChanged = false;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<StoreState>
  ) {
    this.session$ = this.store.select('session');

    this.fg = this.fb.group({
      machine: [''],
      product: [[]],
      duration: [''],
      code: [[]],
      dateRange: this.fb.group({
        start: [''],
        finish: ['']
      }),
      usr: []
    });

    // Manually set form values (used when mobile filter modal used)
    this.session$.pipe(
      distinctUntilChanged((x: SessionState, y: SessionState) => x.triggerFilterUpdate === y.triggerFilterUpdate),
      takeUntil(this.destroy$)
    ).subscribe(state => {
      if (state.triggerFilterUpdate) {
        this.noDebouncePatch = true;
        this.patchFormGroup(state.analysisFilter, state.gameTypeList);
      }
    });
  }

  ngOnInit(): void {
    this.session$.pipe(
      distinctUntilChanged((x: SessionState, y: SessionState) => x.analysisFilter === y.analysisFilter),
      takeUntil(this.cancelValueInit$)
    ).subscribe(state => {
      if (state.analysisFilter) {
        this.patchFormGroup(state.analysisFilter, state.gameTypeList);

        if (state.analysisFilter.usr && !this.isModal) {
          this.fg.reset();
          this.refreshResults(this.constructFilters(this.fg.value, state.analysisFilter));
          this.determineDuration([], []);
        }
      }
      this.cancelValueInit.next();
    });

    this.fg.valueChanges.pipe(
      debounce(() => timer(this.isModal || this.noDebouncePatch ? 0 : 1200)),
      withLatestFrom(this.store),
      takeUntil(this.destroy$)
    ).subscribe(([v, store]: [any, StoreState]) => {
      if (this.userId) {
        if (this.isModal) {
          this.emitFormFilter.emit(this.constructFilters(this.fg.value, store.session.analysisFilter));
        }
        else {
          const filters: {[id: string]: any} = this.constructFilters(this.fg.value, store.session.analysisFilter);
          if ((this.noDebouncePatch || FilterModel.determineFetchFilters(store.session.analysisFilter, filters)) && this.username && this.esaId) {
            this.store.dispatch(FetchSessionListRequestAction({
              userId: this.userId,
              esaUsername: this.username,
              esaId: this.esaId,
              filters
            }));
          }
          this.noDebouncePatch = false;
        }
      }
    });
  }

  ngOnChanges(c: SimpleChanges): void {
    if (c.clearTrigger?.currentValue === true) {
      this.clearFilters();
    }
  }

  patchFormGroup(filter: {[id: string]: any} | null, gameTypeList: Array<GameTypeModel> | null): void {
    if (filter) {
      this.fg.get('code')?.patchValue(this.getStoredFilter(filter, 'code', true));
      this.fg.get('machine')?.patchValue(this.getStoredFilter(filter, 'machine'));
      this.fg.get('product')?.patchValue(this.getStoredFilter(filter, 'product', true));
      this.fg.get('duration')?.patchValue(this.getStoredFilter(filter, 'duration'));
      this.fg.get('usr')?.patchValue(this.getStoredFilter(filter, 'usr'));
      this.fg.get('dateRange')?.patchValue({
        start: this.getStoredDateFilter(filter, 'start'),
        finish: this.getStoredDateFilter(filter, 'finish')
      });

      if (gameTypeList) {
        const filters = filter as unknown as AnalysisFilters;
        this.determineDuration(filters.code as Array<string>, gameTypeList);
      }
    }
  }

  handleUserSelection(event: MatSelectChange): void {
    this.userChanged = true;
    this.fg.get('code')?.setValue([]);
    this.fg.get('product')?.setValue([]);

    if (this.isModal && this.userId) {
      this.resetGameType(event.value ? event.value : this.esaId);
    }
  }

  getStoredFilter(filters: {[id: string]: any}, key: string, array = false): any {
    return filters[key] ? filters[key] : array ? [] : '';
  }
  getStoredDateFilter(dateRange: {[id: string]: any}, key: string): any {
    return dateRange[key] && dateRange[key] ? moment(dateRange[key], 'YYYY/MM/DD HH:mm:ss').toDate() : '';
  }

  constructFilters(filters: AnalysisFilters, currentFilters: {[id: string]: string} | null): AnalysisFilters {
    // make a copy so that dateRange isn't deleted below
    const modifiedFilters = {
      ...currentFilters,
      ...filters
    };
    const startMoment = modifiedFilters?.dateRange?.start ? moment(modifiedFilters.dateRange.start) : null;
    const endMoment = modifiedFilters?.dateRange?.finish ? moment(modifiedFilters.dateRange.finish) : null;

    if (startMoment && startMoment.isValid()) {
      modifiedFilters.start = startMoment.startOf('day').format('YYYY/MM/DD HH:mm:ss');
    }
    else {
      modifiedFilters.start = '';
    }
    if (endMoment && endMoment.isValid()) {
      modifiedFilters.finish = endMoment.endOf('day').format('YYYY/MM/DD HH:mm:ss');
    }
    else {
      modifiedFilters.finish = '';
    }

    delete modifiedFilters['dateRange'];

    return modifiedFilters;
  }

  determineGameType(event: MatSelectChange, gameTypeList: Array<GameTypeModel> | null): void {
    const value = event.value as Array<string>;
    this.determineDuration(value, gameTypeList);
  }

  determineDuration(value: Array<string>, gameTypeList: Array<GameTypeModel> | null): void {
    const foundGameType = value && gameTypeList ? gameTypeList.filter(g => value.find(v => v === g.code)) : null;
  }

  clearFilters(): void {
    this.fg.reset();
    this.resetGameType(this.esaId);
    this.determineDuration([], []);
  }

  refreshResults(filters: {[id: string]: any} | null): void {
    if (this.userId && this.username && this.esaId) {
      this.store.dispatch(FetchSessionListRequestAction({
        userId: this.userId,
        esaUsername: this.username,
        esaId: this.esaId,
        filters
      }));
    }
  }

  resetGameType(username: string | undefined | null): void {
    if (this.userChanged && this.userId && username) {
      this.store.dispatch(FetchPlayedGameTypeListRequestAction({userId: this.userId, username}));
      this.store.dispatch(FetchPlayedMachineGroupListRequestAction({userId: this.userId, username}));
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    if (!this.isModal) {
      this.resetGameType(this.esaId);
    }
  }
}
