export class APIHelper {
    static camelize(str: string) {
        return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr: string) => chr.toUpperCase());
    }
    static toSnake(str: string) {
      return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }

    static APItoJSON<T>(obj: {[id: string]: any}): T {
        const newObj: {[id: string]: any} = {};
        for (const key of Object.keys(obj)) {
          if (key.includes('_')) {
            newObj[APIHelper.camelize(key)] = obj[key];
          } else {
            newObj[key] = obj[key];
          }
        }
        return newObj as T;
    }

    static JSONtoAPI<T>(obj: {[id: string]: any}): T {
      const newObj: {[id: string]: any} = {};
      for (const key of Object.keys(obj)) {
        newObj[APIHelper.toSnake(key)] = obj[key];
      }
      return newObj as T;
    }

    static removePendingTask(id: string, keys: {[id: string]: boolean}): {[id: string]: boolean} {
      return Object.keys(keys).filter(k => k !== id).reduce((acc: {[id: string]: boolean}, curr: string)=> (acc[curr]=true, acc), {});
    }
}