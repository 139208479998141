import { AlertModel } from './alert.model';
import { APIFriendInvitation, FriendInvitationDetails } from './api-models/api-friend.model';
import { APIHelper } from './api-models/api-helper.model';

export interface FriendCommunity {
  id?: number;
  ownerUserId?: number;
  name: string;
  public: boolean;
  data: {[id: string]: string};
  users?: Array<FriendModel>;
}

export interface PostFriendInvitation {
  esaUsername: string;
  communityId: number;
  message: string;
  data: {[id:string]: string} | null;
}

export interface FriendInvitationData {
  communityId: number;
  userEmail: string;
  communityData: {[id: string]: string};
}
export class FriendInvitation {
  id!: number;
  type!: string;
  senderId!: number;
  recipientId!: number;
  recipientType!: string;
  senderDetails?: FriendInvitationDetails;
  recipientDetails?: FriendInvitationDetails;
  subject!: string;
  body!: string;
  readAt!: string;
  data?: FriendInvitationData;

  static fromApiData(apiData: APIFriendInvitation): FriendInvitation {
    const model = APIHelper.APItoJSON(apiData) as FriendInvitation;

    if (apiData.sender_details) {
      model.senderDetails = APIHelper.APItoJSON(apiData.sender_details);
    }
    if (apiData.recipient_details) {
      model.recipientDetails = APIHelper.APItoJSON(apiData.recipient_details);
    }

    if (apiData.data) {
      model.data = APIHelper.APItoJSON(apiData.data);
    }

    return model;
  }
}

export interface FriendModel {
  id?: number;
  name: string;
  esaId: number;
  esaUsername: string;
  email: string;
  photo?: string | null;
}

export interface FetchCommunityListRequest {
  userId:        number;
}
export interface FetchCommunityListResponse {
  error:         AlertModel | null;
  ownedCommunity: FriendCommunity | null;
  // linkedCommunityList: Array<FriendCommunity> | null;
  userId:        number;
}

export interface PostCommunityRequest {
  community: FriendCommunity;
  userId: number;
}
export interface PostCommunityResponse {
  error:     AlertModel | null;
  community: FriendCommunity | null;
}

export interface PatchCommunityRequest {
  communityId: number;
  name: string;
  userId: number;
}
export interface PatchCommunityResponse {
  error:       AlertModel | null;
  communityId: number | null;
  community:   FriendCommunity | null;
}

export interface FetchFriendListRequest {
  filters?: any;
}
export interface FetchFriendListResponse {
  friends: Array<FriendModel> | null;
  error: AlertModel | null;
}

export interface InviteUserToCommunityRequest {
  invitation: PostFriendInvitation | Array<PostFriendInvitation>;
}
export interface InviteUserToCommunityResponse {
  message: AlertModel | null;
  error: AlertModel | null;
}

export interface FetchUserInvitationListResponse {
  sentInvitations:     Array<FriendInvitation> | null;
  receivedInvitations: Array<FriendInvitation> | null;
  error:               AlertModel | null;
}

export interface RespondUserInvitationRequest {
  accept: boolean;
  invitationId: number;
  invitationType: string | null;
}
export interface RespondUserInvitationResponse {
  error: AlertModel | null;
  accept: boolean;
  invitationId: number;
  invitationType: string | null;
}

export interface DeleteInvitationRequest {
  invitationId: number;
}
export interface DeleteInvitationResponse {
  error: AlertModel | null;
  invitationId: number;
}

export interface RemoveFriendRequest {
  userId:      number;
}
export interface RemoveFriendResponse {
  error:  AlertModel | null;
  userId: number;
}

export interface RemoveUserFromCommunityRequest {
  communityId:   number;
  userId:        number;
  owned:         boolean;
  sessionUserId: number;
}
export interface RemoveUserFromCommunityResponse {
  error:  AlertModel | null;
  communityId: number;
  sessionUserId: number;
  userId: number;
}