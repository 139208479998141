import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, distinct, map, switchMap, toArray } from 'rxjs/operators';

import { ApiService } from './api.service';

import { AlertModel } from '../models/alert.model';
import {
  FriendCommunity,
  FetchCommunityListRequest,
  FetchCommunityListResponse,
  PostCommunityRequest,
  PostCommunityResponse,
  FetchFriendListRequest,
  FetchFriendListResponse,
  FriendModel,
  InviteUserToCommunityRequest,
  InviteUserToCommunityResponse,
  FriendInvitation,
  FetchUserInvitationListResponse,
  RespondUserInvitationRequest,
  RespondUserInvitationResponse,
  DeleteInvitationRequest,
  DeleteInvitationResponse,
  RemoveUserFromCommunityRequest,
  RemoveUserFromCommunityResponse,
  RemoveFriendRequest,
  RemoveFriendResponse,
  PostFriendInvitation,
  PatchCommunityRequest,
  PatchCommunityResponse,
} from '../models/friend.model';
import { FilterModel } from '../models/filter.model';
import { LoginService } from './login.service';
import { APIHelper } from '../models/api-models/api-helper.model';
import { APIFriendInvitation, APIFriendModel } from '../models/api-models/api-friend.model';

@Injectable({
  providedIn: 'root',
})
export class FriendService {
  constructor(
    private apiService: ApiService,
    private loginService: LoginService
  ) {
  }

  fetchCommunityList(req: FetchCommunityListRequest): Observable<FetchCommunityListResponse> {
    return this.apiService.apiGet<any>(`/user/${req.userId}/community/owned`).pipe(
      map((res: Array<unknown>): FetchCommunityListResponse => {
        const ownedResult: Array<FriendCommunity> = res.map((r: unknown): FriendCommunity => APIHelper.APItoJSON(r as FriendCommunity)).filter(r => r.name === 'friend-community');

        return {
          error: null,
          ownedCommunity: ownedResult.length ? ownedResult[0] : null,
          userId: req.userId
        };
        /* return this.apiService.apiGet<any>(`/user/${req.userId}/community/linked`).pipe(
          map((linkedRes: Array<unknown>): FetchCommunityListResponse => {
            const linkedResult: Array<FriendCommunity> = linkedRes.map(r => APIHelper.APItoJSON(r as FriendCommunity));
            return {
              error: null,
              ownedCommunityList: ownedResult,
              linkedCommunityList: linkedResult,
              userId: req.userId
            };
          }
        ));*/
      }),
      catchError((err: HttpErrorResponse): Observable<FetchCommunityListResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          ownedCommunity: null,
          userId: req.userId
        });
      }),
    );
  }
  postCommunity(req: PostCommunityRequest): Observable<PostCommunityResponse> {
    return this.apiService.apiPost<any>(`/user/${req.userId}/community/owned`, req.community).pipe(
      map((res: unknown): PostCommunityResponse => {
        return {
          community: res as FriendCommunity,
          error: null,
        };
      }),
      catchError((err: HttpErrorResponse): Observable<PostCommunityResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          community: null
        });
      }),
    );
  }

  patchCommunity(req: PatchCommunityRequest): Observable<PatchCommunityResponse> {
    return this.apiService.apiPatch<any>(`/user/${req.userId}/community/owned/${req.communityId}`, {name: req.name}).pipe(
      map((res: unknown): PatchCommunityResponse => {
        return {
          community: res as FriendCommunity,
          communityId: req.communityId,
          error: null,
        };
      }),
      catchError((err: HttpErrorResponse): Observable<PatchCommunityResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          communityId: null,
          community: null
        });
      }),
    );
  }

  fetchFriendUserList(req: FetchFriendListRequest): Observable<FetchFriendListResponse> {
    return this.apiService.apiGet<any>(`/current-user/friends`).pipe(
      map((res: Array<unknown>): FetchFriendListResponse => {
        const result: Array<FriendModel> = res.map((r: unknown) => APIHelper.APItoJSON(r as APIFriendModel));
        return {
          friends: result,
          error: null,
        };
      }),
      catchError((err: HttpErrorResponse): Observable<FetchFriendListResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          friends: null
        });
      }),
    );
  }

  inviteUserToCommunity(req: InviteUserToCommunityRequest): Observable<InviteUserToCommunityResponse> {
    return this.apiService.apiPost<any>(`/current-user/invite-to-community`, APIHelper.JSONtoAPI(req.invitation)).pipe(
      map((res: unknown): InviteUserToCommunityResponse => {
        return {
          message: AlertModel.handleApiMessage(`We've sent the invitiation to your friend, they should receive it shortly.`, 'info'),
          error: null,
        };
      }),
      catchError((err: HttpErrorResponse): Observable<InviteUserToCommunityResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          message: null
        });
      }),
    );
  }

  inviteMultipleUsersToCommunity(req: InviteUserToCommunityRequest): Observable<InviteUserToCommunityResponse> {
    return from(req.invitation as Array<PostFriendInvitation>).pipe(
      concatMap((i: PostFriendInvitation) => {
        return this.inviteUserToCommunity({invitation: i});
      }),
      catchError((err: HttpErrorResponse): Observable<InviteUserToCommunityResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          message: null
        });
      })
    ).pipe(
      toArray(),
      map((res: Array<InviteUserToCommunityResponse>): InviteUserToCommunityResponse => {
        const errorArray = res.filter(r => !!r.error).map(r => r.error as AlertModel);
        const errorString = errorArray.map(r => r.message).join(', ');
        return {
          error: errorString ? AlertModel.handleApiMessage(errorString, 'warning') : null,
          message: errorString ? null : AlertModel.handleApiMessage('Friends were invited successfully', 'info'),
        };
      })
    );
  }

  fetchUserInvitationList(): Observable<FetchUserInvitationListResponse> {
    return this.apiService.apiGet<any>('/current-user/invitation').pipe(
      switchMap((res: Array<unknown>): Observable<FetchUserInvitationListResponse> => {
        const recieved: Array<FriendInvitation> = res.map((r: unknown): FriendInvitation => FriendInvitation.fromApiData(r as APIFriendInvitation));

        return this.apiService.apiGet<any>('/current-user/invitation/sent').pipe(
          map((sentRes: Array<unknown>): FetchUserInvitationListResponse => {
            const sent: Array<FriendInvitation> = sentRes.map((r: unknown): FriendInvitation => FriendInvitation.fromApiData(r as APIFriendInvitation));
            return {
              error: null,
              receivedInvitations: recieved,
              sentInvitations: sent,
            };
          }
        ));
      }),
      catchError((err: HttpErrorResponse): Observable<FetchUserInvitationListResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          receivedInvitations: null,
          sentInvitations: null,
        });
      }),
    );
  }
  respondUserInvitation(req: RespondUserInvitationRequest): Observable<RespondUserInvitationResponse> {
    return this.apiService.apiPatch<any>(`/current-user/invitation/${req.invitationId}`, {accept: req.accept}).pipe(
      map((): RespondUserInvitationResponse => {
        return {
          invitationId: req.invitationId,
          invitationType: req.invitationType,
          accept: req.accept,
          error: null
        };
      }),
      catchError((err: HttpErrorResponse): Observable<RespondUserInvitationResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          accept: req.accept,
          invitationId: req.invitationId,
          invitationType: null
        });
      }),
    );
  }
  deleteUserInvitation(req: DeleteInvitationRequest): Observable<DeleteInvitationResponse> {
    return this.apiService.apiDelete<any>(`/current-user/invitation/sent/${req.invitationId}`).pipe(
      map((): DeleteInvitationResponse => {
        return {
          invitationId: req.invitationId,
          error: null
        };
      }),
      catchError((err: HttpErrorResponse): Observable<DeleteInvitationResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          invitationId: req.invitationId,
        });
      }),
    );
  }

  removeFriend(req: RemoveFriendRequest): Observable<RemoveFriendResponse> {
    return this.apiService.apiDelete<any>(`/current-user/friends/${req.userId}`).pipe(
      map((): RemoveFriendResponse => {
        return {
          userId: req.userId,
          error: null
        };
      }),
      catchError((err: HttpErrorResponse): Observable<RemoveFriendResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          userId: req.userId,
        });
      }),
    );
  }

  removeUserFromCommunity(req: RemoveUserFromCommunityRequest): Observable<RemoveUserFromCommunityResponse> {
    return this.apiService.apiDelete<any>(`/user/${req.userId}/community/${req.owned ? 'owned' : 'linked'}/${req.communityId}`).pipe(
      map((): RemoveUserFromCommunityResponse => {
        return {
          userId: req.userId,
          communityId: req.communityId,
          error: null,
          sessionUserId: req.sessionUserId
        };
      }),
      catchError((err: HttpErrorResponse): Observable<RemoveUserFromCommunityResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          communityId: req.communityId,
          userId: req.userId,
          sessionUserId: req.sessionUserId
        });
      }),
    );
  }
}