import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule }  from '@angular/forms';

import { LeaderboardFiltersComponent } from './leaderboard-filters.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

import { SyncButtonComponentModule } from '../../general/sync-button/sync-button.module';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSelectModule,
    SyncButtonComponentModule
  ],
  exports: [
    LeaderboardFiltersComponent
  ],
  declarations: [
    LeaderboardFiltersComponent
  ],
})
export class LeaderboardFiltersComponentModule { }
