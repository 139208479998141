import { Component, EventEmitter, Output } from '@angular/core';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LogoutAction } from 'src/app/state-management/actions/login.actions';
import { StoreState } from 'src/app/state-management/store';
import { State as LoginState } from '../../../../state-management/reducers/login.reducer';

@Component({
  selector: 'app-sidebar-contents',
  templateUrl: './sidebar-contents.component.html',
  styleUrls: ['./sidebar-contents.component.sass']
})
export class SidebarContentsComponent {
  @Output() toggleDrawer = new EventEmitter();

  public login$: Observable<LoginState>;

  public chevronDownIcon = faChevronDown;

  public items = [
    {
      title: 'Player', url: 'player', children: [
        {title: 'Profile', url: 'player', hasChildren: false},
        {title: 'Index', url: 'player/index', hasChildren: false},
        {title: 'Edit profile', url: 'player/edit', hasChildren: false},
        {title: 'Settings', url: 'player/settings', hasChildren: false},
      ]
    },
    {title: 'Analysis', url: 'analysis', children: null},
    {title: 'Leaderboard', url: 'leaderboard', children: null}
    /* {
      title: 'Friends', url: 'friends/all', children: [
        {title: 'All Friends', url: 'friends', hasChildren: false},
        {title: 'Leaderboards', url: 'friends/leaderboards', hasChildren: false},
        {title: 'Invitations', url: 'friends/invitations', hasChildren: false},
      ]
    }*/
  ];
  public expandedIndex = 0;

  constructor(
    private store: Store<StoreState>
  ) {
    this.login$ = this.store.select('login');
  }

  logout(): void {
    this.store.dispatch(LogoutAction({message: null}));
    this.toggleDrawer.emit();
  }
}
