import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';

import {
  catchError,
  distinctUntilChanged,
  map,
} from 'rxjs/operators';

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';

import { environment } from '../../environments/environment';

import { StoreState } from '../state-management/store';
import { State as LoginReducerState } from '../state-management/reducers/login.reducer';

import { LogoutAction } from '../state-management/actions/login.actions';
import { AlertModel } from '../models/alert.model';

import { EsaAccountEmailModalComponent } from '../views/modals/esa-account-email-modal/esa-account-email-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // Base url for all API calls
  private baseUrl: string = environment.api.baseUrl;

  // Reference to token for authorisation when making api calls
  private loginJwt: string | null = null;

  // Reference to the login state
  private login$: Observable<LoginReducerState>;

  constructor(
    private http: HttpClient,
    private store: Store<StoreState>,
    private dialog: MatDialog,
  ) {
    this.login$ = this.store.select('login');

    // Subscribe to loginDetails and update the stored loginLwt whenever it
    // changes
    this.login$.pipe(
      distinctUntilChanged((x: LoginReducerState, y: LoginReducerState) => x.token === y.token)
    ).subscribe((state: LoginReducerState) => {
      this.loginJwt = (state && state.token ? state.token : null);
    });
  }


  /**
   * Builds a complete API URI from a passed suffix/endpoint
   *
   * @param {string} uriSuffix API endpoint
   * @return {string} Full API URI
   */
  private buildUri(uriSuffix: string, bUseContext: boolean): string {
    const contextID = bUseContext ? this.getApiContextId() : null;
    return `${this.baseUrl}${bUseContext && contextID ? '/' + encodeURIComponent(contextID.toString()) : ''}${uriSuffix}`;
  }

  /**
   * Dispatches a LogoutAction whenever a 401 response is received
   * from the API.
   *
   * @param {number} statusCode
   */
  private checkStatus(statusCode: number) {
    if (statusCode === 401) {
      this.store.dispatch(LogoutAction({
        message: AlertModel.handleApiMessage('Your login session has expired', 'warning')
      }));
    }
    else if (statusCode === 422) {
      this.dialog.open(EsaAccountEmailModalComponent, {
        width: '420px',
        panelClass: 'branded-modal-dialog'
      });
    }
  }

  /**
   * Builds a HttpHeaders instance with the "Authorization" header set with the
   * current JWT
   *
   * @return {HttpHeaders}
   */
  private getRequestHeaders(): HttpHeaders {
    return (new HttpHeaders()).set('Authorization', `Bearer ${this.loginJwt}`);
  }

  /**
   * Gets an options object to be passed to HttpClient request methods
   *
   * @return {any}
   */
  private getRequestOptions(): any {
    return this.loginJwt ? { headers: this.getRequestHeaders() } : {};
  }

  /**
   * Checks environment variable for a default contextID; if none is
   * found the app will attempt to use the ID stored in localStorage.
   *
   * @return {number|null} ID for given context
   */
  getApiContextId(): number | null {
    if (environment.contextId) {
      return environment.contextId;
    }

    const key = `${environment.id}_context_id`;
    const cId = window.localStorage.getItem(key);
    return cId ? +cId : null;
  }


  /**
   * Performs an API DELETE to a specified endpoint. Uses the current JWT if
   * available. Can be used to return a generic type T.
   *
   * @param {string} uri  API route
   * @return {Observable<T>} Observable of the API response (type T if specified)
   */
  apiDelete<T>(uri: string, bUseContext: boolean = true): Observable<T> {
    return this.http.delete<T>(
      this.buildUri(uri, bUseContext),
      { ...this.getRequestOptions(), observe: 'response', responseType: 'json' }
    ).pipe(
      map((res: any): T => res.body), // eslint-disable-line
      catchError((err: HttpErrorResponse) => {
        this.checkStatus(err.status);
        throw err;
      }),
    );
  }

  /**
   * Performs an API GET to a specified endpoint. Uses the current JWT if
   * available. Can be used to return a generic type T.
   *
   * @param {string} uri  API route
   * @return {Observable<T>} Observable of the API response (type T if specified)
   */
  apiGet<T>(uri: string, bUseContext: boolean = true): Observable<T> {
    return this.http.get<T>(
      this.buildUri(uri, bUseContext),
      { ...this.getRequestOptions(), observe: 'response', responseType: 'json' }
    ).pipe(
      map((res: any): T => res.body), // eslint-disable-line
      catchError((err: HttpErrorResponse) => {
        this.checkStatus(err.status);
        throw err;
      }),
    );
  }

  /**
   * Performs an API PATCH to a specified endpoint with specified data. Uses
   * the current JWT if available. Can be used to return a generic type T.
   *
   * @param {string} uri  API route
   * @param {any} data Data to send in request
   * @return {Observable<T>} Observable of the API response (type T if specified)
   */
  apiPatch<T>(uri: string, data: any, bUseContext: boolean = true): Observable<T> {
    return this.http.patch<T>(
      this.buildUri(uri, bUseContext),
      data,
      { ...this.getRequestOptions(), observe: 'response', responseType: 'json' }
    ).pipe(
      map((res: any): T => res.body), // eslint-disable-line
      catchError((err: HttpErrorResponse) => {
        this.checkStatus(err.status);
        throw err;
      }),
    );
  }

  /**
   * Performs an API POST to a specified endpoint with specified data. Uses the
   * current JWT if available. Can be used to return a generic type T.
   *
   * @param {string} uri  API route
   * @param {any} data Data to send in request
   * @return {Observable<T>} Observable of the API response (type T if specified)
   */
  apiPost<T>(uri: string, data: any, bUseContext: boolean = true): Observable<T> {
    return this.http.post<T>(
      this.buildUri(uri, bUseContext),
      data,
      { ...this.getRequestOptions(), observe: 'response', responseType: 'json' }
    ).pipe(
      map((res: any): T => res.body), // eslint-disable-line
      catchError((err: HttpErrorResponse) => {
        this.checkStatus(err.status);
        throw err;
      }),
    );
  }

  /**
   * Performs an API PUT to a specified endpoint with specified data. Uses the
   * current JWT if available. Can be used to return a generic type T.
   *
   * @param {string} uri  API route
   * @param {any} data Data to send in request
   * @return {Observable<T>} Observable of the API response (type T if specified)
   */
  apiPut<T>(uri: string, data: any, bUseContext: boolean = true): Observable<T> {
    return this.http.put<T>(
      this.buildUri(uri, bUseContext),
      data,
      { ...this.getRequestOptions(), observe: 'response', responseType: 'json' }
    ).pipe(
      map((res: any): T => res.body), // eslint-disable-line
      catchError((err: HttpErrorResponse) => {
        this.checkStatus(err.status);
        throw err;
      }),
    );
  }

  /**
   * Performs an API GET to a specified endpoint and returns the response as a
   * Blob.
   *
   * @param {string} uri API route
   * @return {Observable<T>} Observable of the API response (type T if specified)
   */
  /* apiGetBlob(uri: string, bUseContext: boolean = true): Observable<Blob> {
    return this.http.get(
      this.buildUri(uri, bUseContext),
      { ...this.getRequestOptions(), responseType: 'blob' }
    );
  }*/

  /**
   * Performs an API GET to a specified endpoint and returns the response as
   * plain text.
   *
   * @param {string} uri API route
   * @return {Observable<T>} Observable of the API response (type T if specified)
   */
  /* apiGetText(uri: string, bUseContext: boolean = true): Observable<string> {
    return this.http.get(
      this.buildUri(uri, bUseContext),
      { ...this.getRequestOptions(), responseType: 'text' }
    );
  }*/
}