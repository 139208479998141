import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent }     from './app.component';

// Date picker components
import { ESA_DATE_FORMATS, CustomMomentDateAdapter } from './models/date-adapter.model';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

// Material
import { MatSidenavModule } from '@angular/material/sidenav';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatBadgeModule } from '@angular/material/badge';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

// Effects
import { LoginEffects } from './state-management/effects/login.effects';
import { SessionEffects } from './state-management/effects/session.effects';
import { LeaderboardEffects } from './state-management/effects/leaderboard.effects';
import { FriendEffects } from './state-management/effects/friend.effects';
import { NotificationEffects } from './state-management/effects/notification.effects';

// general components
import { PageHeaderComponent }      from './views/components/general/page-header/page-header.component';
import { PageFooterComponent }      from './views/components/general/page-footer/page-footer.component';
import { PartnerIconsComponent }    from './views/components/general/page-header/partner-icons/partner-icons.component';
import { SidebarContentsComponent } from './views/components/general/sidebar-contents/sidebar-contents.component';

// Store
import { store } from './state-management/store';
import { SubNavComponent } from './views/components/general/page-header/sub-nav/sub-nav.component';

import { ComponentCommonModule } from './module-common/component-common.module';
import { ShareIndexModalComponent } from './views/modals/share-index-modal/share-index-modal.component';
import { EsaAccountEmailModalComponentModule } from './views/modals/esa-account-email-modal/esa-account-email-modal.module';


@NgModule({
  declarations: [
    AppComponent,
    PageHeaderComponent,
    PageFooterComponent,
    SubNavComponent,
    PartnerIconsComponent,
    SidebarContentsComponent,
    ShareIndexModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatSidenavModule,
    CdkAccordionModule,
    MatCardModule,
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    MatBadgeModule,
    MatNativeDateModule,
    ComponentCommonModule,
    EsaAccountEmailModalComponentModule,
    StoreModule.forRoot(store, {}),
    EffectsModule.forRoot([
      LoginEffects,
      SessionEffects,
      LeaderboardEffects,
      FriendEffects,
      NotificationEffects
    ]),
    BrowserAnimationsModule,
    FontAwesomeModule
  ],
  providers: [
    {
      provide:  DateAdapter,
      useClass: CustomMomentDateAdapter,
      deps:     [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {provide: MAT_DATE_FORMATS, useValue: ESA_DATE_FORMATS},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
