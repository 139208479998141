import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject, timer } from 'rxjs';
import { StoreState } from 'src/app/state-management/store';

import { State as LoginState } from 'src/app/state-management/reducers/login.reducer';
import { State as LeaderboardState } from 'src/app/state-management/reducers/leaderboard.reducer';
import { State as SessionState } from 'src/app/state-management/reducers/session.reducer';

import { debounce, distinctUntilChanged, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { FetchLeaderboardDataRequestAction } from 'src/app/state-management/actions/leaderboard.actions';

import * as moment from 'moment';
import { LeaderboardFilters } from 'src/app/models/leaderboard.model';
import { GameTypeModel, SessionFilterUtil } from 'src/app/models/session.model';
import { APIEsaDataModel } from 'src/app/models/api-models/api-user.model';
import { FilterModel } from 'src/app/models/filter.model';
import { MatSelectChange } from '@angular/material/select';

interface CombinedReducers {
  login: LoginState;
  leaderboard: LeaderboardState;
  session: SessionState;
}

@Component({
  selector: 'app-leaderboard-filters',
  templateUrl: './leaderboard-filters.component.html',
  styleUrls: ['./leaderboard-filters.component.sass']
})
export class LeaderboardFiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() userId: number | null | undefined = null;
  @Input() username: string | undefined;
  @Input() fullName: string | undefined;
  @Input() isModal = false;
  @Input() clearTrigger = false;
  @Output() emitFormFilter = new EventEmitter<LeaderboardFilters>();

  // public leaderboard$: Observable<LeaderboardState>;
  public combinedReducers$: Observable<CombinedReducers>;

  public fg: UntypedFormGroup;

  private cancelValueInit  = new Subject();
  private cancelValueInit$ = this.cancelValueInit.asObservable();

  private destroy  = new Subject();
  private destroy$ = this.destroy.asObservable();

  public maxDate = new Date();

  public durationArray = SessionFilterUtil.getIntervalList();

  // Used to remove the debounce when the filters are updated from the modal
  private noDebouncePatch = false;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<StoreState>
  ) {
    // this.leaderboard$ = this.store.select('leaderboard');
    this.combinedReducers$ = combineLatest([
      this.store.select('login'),
      this.store.select('leaderboard'),
      this.store.select('session')
    ]).pipe(
      map(([login, leaderboard, session]): CombinedReducers => ({
        login,
        leaderboard,
        session
      }))
    );

    this.fg = this.fb.group({
      machine: [''],
      product: [[]],
      duration: [''],
      code: ['LEAD'],
      gender: [''],
      leaderboard: ['local'],
      age: [''],
      ownFilter: [''],
      dateRange: this.fb.group({
        start: [''],
        finish: ['']
      }),
      usr_id_list: []
    });

    this.combinedReducers$.pipe(
      distinctUntilChanged((x: CombinedReducers, y: CombinedReducers) => x.leaderboard.leaderboardFilter === y.leaderboard.leaderboardFilter),
      takeUntil(this.cancelValueInit$)
    ).subscribe(state => {
      if (state.leaderboard.leaderboardFilter) {
        this.patchFormGroup(state.leaderboard.leaderboardFilter, state.session.gameTypeList);
      }
      this.cancelValueInit.next();
    });

    // Manually set form values (used when mobile filter modal used)
    this.combinedReducers$.pipe(
      distinctUntilChanged((x: CombinedReducers, y: CombinedReducers) => x.leaderboard.triggerFilterUpdate === y.leaderboard.triggerFilterUpdate),
      takeUntil(this.destroy$)
    ).subscribe(state => {
      if (state.leaderboard.triggerFilterUpdate) {
        this.noDebouncePatch = true;
        this.patchFormGroup(state.leaderboard.leaderboardFilter, state.session.gameTypeList);
      }
    });
  }

  ngOnInit(): void {
    this.fg.valueChanges.pipe(
      debounce(() => timer(this.isModal || this.noDebouncePatch ? 0 : 1200)),
      withLatestFrom(this.store),
      takeUntil(this.destroy$)
    ).subscribe(([v, store]: [any, StoreState]) => {
      if (this.userId) {
        if (this.isModal) {
          this.emitFormFilter.emit(this.constructFilters(this.fg.value, store.leaderboard.leaderboardFilter, store.login.user?.esaData));
        }
        else {
          const filters: {[id: string]: any} = this.constructFilters(this.fg.value, store.leaderboard.leaderboardFilter, store.login.user?.esaData);
          if ((this.noDebouncePatch || FilterModel.determineFetchFilters(store.leaderboard.leaderboardFilter, filters)) && this.username) {
            this.store.dispatch(FetchLeaderboardDataRequestAction({
              filters,
              username: this.username,
              fullName: this.fullName ? this.fullName : this.username
            }));
          }
          this.noDebouncePatch = false;
        }
      }
    });
  }

  ngOnChanges(c: SimpleChanges): void {
    if (c.clearTrigger?.currentValue === true) {
      this.clearFilters();
    }
  }

  patchFormGroup(filter: {[id: string]: any} | null, gameTypeList: Array<GameTypeModel> | null): void {
    if (filter) {
      const ownFilterArray = [];
      for(const key of Object.keys(filter)) {
        if (key === 'nation' || key === 'position' || key === 'foot') {
          if (!!filter[key]) {
            ownFilterArray.push(key);
          }
        }
      }
      if (ownFilterArray.length) {
        this.fg.get('ownFilter')?.patchValue(ownFilterArray);
      }

      this.fg.get('code')?.patchValue(this.getStoredFilter(filter, 'code', true));
      this.fg.get('machine')?.patchValue(this.getStoredFilter(filter, 'machine'));
      this.fg.get('product')?.patchValue(this.getStoredFilter(filter, 'product', true));
      this.fg.get('duration')?.patchValue(this.getStoredFilter(filter, 'duration'));
      this.fg.get('gender')?.patchValue(this.getStoredFilter(filter, 'gender'));
      this.fg.get('age')?.patchValue(this.getStoredFilter(filter, 'ageMin'));
      // this.fg.get('leaderboard')?.patchValue(this.getStoredFilter(filter, 'leaderboard'));
      this.fg.get('usr_id_list')?.patchValue(this.getStoredFilter(filter, 'usr_id_list'));
      this.fg.get('dateRange')?.patchValue({
        start: this.getStoredDateFilter(filter, 'start'),
        finish: this.getStoredDateFilter(filter, 'finish')
      });

      if (filter.global === 1) {
        this.fg.get('leaderboard')?.patchValue('global');
      }
      else if(filter.leaderboardId !== null) {
        this.fg.get('leaderboard')?.patchValue(filter.leaderboardId);
      }
      else {
        this.fg.get('leaderboard')?.patchValue('local');
      }

      if (gameTypeList) {
        const filters = filter as unknown as LeaderboardFilters;
        this.determineDuration(filters.code as string, gameTypeList);
      }
    }
  }

  getStoredFilter(filters: {[id: string]: any}, key: string, array = false): any {
    return filters[key] ? filters[key] : array ? [] : '';
  }
  getStoredDateFilter(dateRange: {[id: string]: any}, key: string): any {
    return dateRange[key] && dateRange[key] ? moment(dateRange[key], 'YYYY/MM/DD HH:mm:ss').toDate() : '';
  }

  constructFilters(newFilters: LeaderboardFilters, currentFilters: {[id: string]: string} | null, esaData: APIEsaDataModel | undefined): LeaderboardFilters {
    // make a copy so that dateRange isn't deleted below
    let modifiedFilters = {
      ...currentFilters,
      ...newFilters
    };

    const startMoment = newFilters?.dateRange?.start ? moment(newFilters.dateRange.start) : null;
    const endMoment = newFilters?.dateRange?.finish ? moment(newFilters.dateRange.finish) : null;

    if (startMoment && startMoment.isValid()) {
      modifiedFilters.start = startMoment.startOf('day').format('YYYY/MM/DD HH:mm:ss');
    }
    else {
      modifiedFilters.start = '';
    }
    if (endMoment && endMoment.isValid()) {
      modifiedFilters.finish = endMoment.endOf('day').format('YYYY/MM/DD HH:mm:ss');
    }
    else {
      modifiedFilters.finish = '';
    }

    if (newFilters.age) {
      modifiedFilters.ageMin = newFilters.age;

      if (newFilters.age < 18) {
        modifiedFilters.ageMax = newFilters.age;
      }
      else {
        modifiedFilters.ageMax = undefined;
      }
    }
    else {
      modifiedFilters.ageMin = undefined;
      modifiedFilters.ageMax = undefined;
    }

    modifiedFilters.global = newFilters.leaderboard === 'global' ? 1 : 0;
    modifiedFilters.leaderboardId = newFilters.leaderboard !== 'global' && newFilters.leaderboard !== 'local' ? newFilters.leaderboard as number : null;


    if (this.isModal && !!esaData) {
      const arrayValue: Array<string> = this.fg.get('ownFilter')?.value;
      modifiedFilters = {
        ...modifiedFilters,
        nation: arrayValue && arrayValue.indexOf('nation') > -1 && esaData?.userNationality ? esaData.userNationality : null,
        foot: arrayValue && arrayValue.indexOf('foot') > -1 && esaData?.userPreferredFoot ? esaData.userPreferredFoot : null,
        position: arrayValue && arrayValue.indexOf('position') > -1 && esaData?.userFieldPosition ? esaData.userFieldPosition : null
      };
    }

    delete modifiedFilters['leaderboard'];
    delete modifiedFilters['dateRange'];
    delete modifiedFilters['ownFilter'];
    delete modifiedFilters['age'];

    return modifiedFilters;
  }

  determineGameType(event: MatSelectChange, gameTypeList: Array<GameTypeModel> | null): void {
    // const value = event.value as Array<string>;
    this.determineDuration(event.value, gameTypeList);
  }

  determineDuration(value: string, gameTypeList: Array<GameTypeModel> | null): void {
    const foundGameType = value && gameTypeList ? gameTypeList.filter(g => value === g.code) : null;
  }

  clearFilters(): void {
    this.fg.reset();
  }

  refreshResults(filters: {[id: string]: string} | null): void {
    if (this.username) {
      this.store.dispatch(FetchLeaderboardDataRequestAction({
        username: this.username,
        fullName: this.fullName ? this.fullName : this.username,
        filters
      }));
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}
