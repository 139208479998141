<h2 matDialogTitle>Share your Index</h2>
<mat-dialog-content *ngIf="loginReducer$ | async; let loginState">
    <a class="button primary hover-white" *ngIf="loginState?.indexAttachmentUrl" [href]="'https://www.facebook.com/sharer/sharer.php?u=' + loginState?.indexAttachmentUrl" target="_blank">
        <fa-icon [icon]="facebookIcon"></fa-icon>
    </a>
    <a class="button primary hover-white" *ngIf="loginState?.indexPageUrl" [href]="'https://twitter.com/intent/tweet?url=' + loginState?.indexPageUrl" target="_blank">
        <fa-icon [icon]="twitterIcon"></fa-icon>
    </a>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="button primary small hover-white" matDialogClose>Close</button>
</mat-dialog-actions>