import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertCardModule } from '../views/components/general/alert-card/alert-card.module';
import { LoadingCardModule } from '../views/components/general/loading-card/loading-card.module';
import { EditSettingModalModule } from '../views/modals/edit-setting-modal/edit-setting-modal.module';
import { EditCountryModalModule } from '../views/modals/edit-country-modal/edit-country-modal.module';
import { AnalysisFiltersComponentModule } from '../views/components/filters/analysis-filters/analysis-filters.module';
import { LeaderboardFiltersComponentModule } from '../views/components/filters/leaderboard-filters/leaderboard-filters.module';

@NgModule({
  declarations: [],
  exports: [
    AlertCardModule,
    LoadingCardModule,
    EditSettingModalModule,
    EditCountryModalModule,
    AnalysisFiltersComponentModule,
    LeaderboardFiltersComponentModule
  ],
  imports: [
    CommonModule
  ]
})
export class ComponentCommonModule { }
