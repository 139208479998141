import { createAction, props } from '@ngrx/store';

import {
  LoginRequest,
  LoginResponse,
  LogoutRequest,
  RequestResetPasswordRequest,
  RequestResetPasswordResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  TokenCheckRequest,
  TokenCheckResponse,
  ActivateAccountRequest,
  ActivateAccountResponse,
  LinkEsaAccountRequest,
  LinkEsaAccountResponse,
  FetchNotificationStatsResponse,
  FetchUserIndexRequest,
  FetchUserIndexResponse,
  StoreUserIndexRequest,
  StoreUserIndexResponse,
  FetchUserIndexAttachmentRequest,
  FetchUserIndexAttachmentResponse,
} from '../../models/login.model';

import {
  FetchUserStatsRequest,
  FetchUserStatsResponse,
  PatchUserProfileRequest,
  PatchUserProfileResponse,
} from '../../models/profile.model';

export const InitLoginState  = createAction('INIT_LOGIN_STATE');

export const CheckLoginTokenRequestAction  = createAction('CHECK_LOGIN_TOKEN_REQUEST', props<TokenCheckRequest>());
export const CheckLoginTokenResponseAction  = createAction('CHECK_LOGIN_TOKEN_RESPONSE', props<TokenCheckResponse>());

export const LoginRequestAction  = createAction('LOGIN_REQUEST', props<LoginRequest>());
export const LoginResponseAction  = createAction('LOGIN_RESPONSE', props<LoginResponse>());

export const ActivateAccountRequestAction  = createAction('ACTIVATE_ACCOUNT_REQUEST', props<ActivateAccountRequest>());
export const ActivateAccountResponseAction  = createAction('ACTIVATE_ACCOUNT_RESPONSE', props<ActivateAccountResponse>());

export const RequestResetPasswordRequestAction  = createAction('REQUEST_RESET_PASSWORD_REQUEST', props<RequestResetPasswordRequest>());
export const RequestResetPasswordResponseAction  = createAction('REQUEST_RESET_PASSWORD_RESPONSE', props<RequestResetPasswordResponse>());

export const ResetPasswordRequestAction  = createAction('RESET_PASSWORD_REQUEST', props<ResetPasswordRequest>());
export const ResetPasswordResponseAction  = createAction('RESET_PASSWORD_RESPONSE', props<ResetPasswordResponse>());

export const PatchUserProfileRequestAction  = createAction('PATCH_USER_PROFILE_REQUEST', props<PatchUserProfileRequest>());
export const PatchUserProfileResponseAction  = createAction('PATCH_USER_PROFILE_RESPONSE', props<PatchUserProfileResponse>());
export const RemovePatchedFlag  = createAction('REMOVE_PATCHED_FLAG');

export const LinkEsaAccountRequestAction = createAction('LINK_ESA_ACCOUNT_REQUEST', props<LinkEsaAccountRequest>());
export const LinkEsaAccountResponseAction = createAction('LINK_ESA_ACCOUNT_RESPONSE', props<LinkEsaAccountResponse>());

export const FetchUserStatsRequestAction = createAction('FETCH_USER_STATS_REQUEST_ACTION', props<FetchUserStatsRequest>());
export const FetchUserStatsResponseAction = createAction('FETCH_USER_STATS_RESPONSE_ACTION', props<FetchUserStatsResponse>());

export const FetchNotificationStatsRequestAction = createAction('FETCH_NOTIFICATION_STATS_REQUEST_ACTION');
export const FetchNotificationStatsResponseAction = createAction('FETCH_NOTIFICATION_STATS_RESPONSE_ACTION', props<FetchNotificationStatsResponse>());

export const FetchUserIndexRequestAction = createAction('FETCH_USER_INDEX_REQUEST_ACTION', props<FetchUserIndexRequest>());
export const FetchUserIndexResponseAction = createAction('FETCH_USER_INDEX_RESPONSE_ACTION', props<FetchUserIndexResponse>());

export const StoreUserIndexRequestAction = createAction('STORE_USER_INDEX_REQUEST_ACTION', props<StoreUserIndexRequest>());
export const StoreUserIndexResponseAction = createAction('STORE_USER_INDEX_RESPONSE_ACTION', props<StoreUserIndexResponse>());

export const FetchUserIndexAttachmentRequestAction = createAction('FETCH_USER_INDEX_ATTACHMENT_REQUEST_ACTION', props<FetchUserIndexAttachmentRequest>());
export const FetchUserIndexAttachmentResponseAction = createAction('FETCH_USER_INDEX_ATTACHMENT_RESPONSE_ACTION', props<FetchUserIndexAttachmentResponse>());

export const OpenShareIndexModal = createAction('OPEN_SHARE_INDEX_MODAL');

export const ResetDataAction = createAction('RESET_DATA_ACTION');
export const LogoutAction  = createAction('LOGOUT', props<LogoutRequest>());