import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { DeleteNotificationResponse, MarkNotificationReadResponse } from 'src/app/models/notification.model';

import { NotificationService } from '../../services/notification.service';
import { FetchNotificationStatsRequestAction } from '../actions/login.actions';

import * as ActionTypes from '../actions/notification.actions';
import { StoreState } from '../store';

@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private store: Store<StoreState>
  ) {}

  fetchNotificationList$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchNotificationListRequestAction),
    concatMap(action => this.notificationService.fetchNotificationList(action).pipe(
      map(res => ActionTypes.FetchNotificationListResponseAction(res)),
    )),
  ));

  markNotificationRead$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.MarkNotificationReadRequestAction),
    concatMap(action => this.notificationService.markNotificationRead(action).pipe(
      map(res => ActionTypes.MarkNotificationReadResponseAction(res)),
    )),
  ));

  markNotificationReadResponse$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.MarkNotificationReadResponseAction),
    filter((req: MarkNotificationReadResponse) => !req.error),
    tap((req: MarkNotificationReadResponse) => this.store.dispatch(FetchNotificationStatsRequestAction()))
  ), { dispatch: false });

  deleteNotification$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteNotificationRequestAction),
    concatMap(action => this.notificationService.deleteNotification(action).pipe(
      map(res => ActionTypes.DeleteNotificationResponseAction(res)),
    )),
  ));

  deleteNotificationResponse$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteNotificationResponseAction),
    filter((req: DeleteNotificationResponse) => !req.error),
    tap((req: DeleteNotificationResponse) => this.store.dispatch(FetchNotificationStatsRequestAction()))
  ), { dispatch: false });
}
