import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, distinct, map, switchMap } from 'rxjs/operators';

import { ApiService } from './api.service';

import { AlertModel } from '../models/alert.model';
import {
  GameTypeModel,
  TrainingSessionModel,
  FetchGameTypeListResponse,
  FetchSessionListRequest,
  FetchSessionListResponse,
  FetchGameTypeListRequest,
  FetchMachineListRequest,
  FetchMachineListResponse,
  MachineModel,
  FetchPlayedGameListResponse,
  FetchMachineGroupListResponse,
  FetchCoachedUserListRequest,
  FetchCoachedUserListResponse,
  FetchPlayedMachineGroupListResponse,
  FetchSessionResponse,
  FetchSessionRequest,
  SingleTrainingSessionModel
} from '../models/session.model';
import { FilterModel } from '../models/filter.model';
import { LoginService } from './login.service';
import { CoachedUserModel, FetchUserStatsResponse, UserStats } from '../models/profile.model';
import { APIHelper } from '../models/api-models/api-helper.model';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(
    private apiService: ApiService,
    private loginService: LoginService
  ) {
  }

   fetchGameTypeList(req: FetchGameTypeListRequest): Observable<FetchGameTypeListResponse> {
    return this.apiService.apiGet<any>('/game-type').pipe(
      switchMap((res): Observable<FetchGameTypeListResponse> => {
        const gameTypeList = (res as Array<GameTypeModel>).sort((a,b) => (a.fullName.toLowerCase() > b.fullName.toLowerCase()) ? 1 : ((b.fullName.toLowerCase() > a.fullName.toLowerCase()) ? -1 : 0));
        return this.fetchPlayedGameList(req, gameTypeList).pipe(
          map((result: FetchPlayedGameListResponse): FetchGameTypeListResponse => ({
            error: result.error,
            gameTypeList,
            playedGameTypeList: result.playedGameTypeList,
            userId: req.userId
          })
        ));
      }),
      catchError((err: HttpErrorResponse): Observable<FetchGameTypeListResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          gameTypeList: null,
          playedGameTypeList: null,
          userId:  req.userId
        });
      }),
    );
  }
  fetchPlayedGameList(req: FetchGameTypeListRequest, gametypes: Array<GameTypeModel> | null): Observable<FetchPlayedGameListResponse> {
    return this.apiService.apiGet<any>(`/user/${req.userId}/game-type${req.username ? '?usr=' + req.username : ''}`).pipe(
      map((res): FetchPlayedGameListResponse => {
        const response = res as {game_types: Array<string>};
        return {
          error: null,
          playedGameTypeList: gametypes ? gametypes.filter(g => response.game_types ? response.game_types.find(t => t === g.code) : g) : gametypes,
        };
      }),
      catchError((err: HttpErrorResponse): Observable<FetchPlayedGameListResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          playedGameTypeList: null,
        });
      }),
    );
  }

  fetchMachineList(req: FetchMachineListRequest): Observable<FetchMachineListResponse> {
    return this.apiService.apiGet<any>(`/machine`).pipe(
      map((res): FetchMachineListResponse => ({
        error: null,
        machineList: (res as Array<MachineModel>).filter(m => m.machineAlias).sort((a,b) => (a.machineAlias.toLowerCase() > b.machineAlias.toLowerCase()) ? 1 : ((b.machineAlias.toLowerCase() > a.machineAlias.toLowerCase()) ? -1 : 0)),
        userId:  req.userId
      })),
      catchError((err: HttpErrorResponse): Observable<FetchMachineListResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          machineList: null,
          userId:  req.userId
        });
      }),
    );
  }

  fetchMachineGroupList(req: FetchMachineListRequest): Observable<FetchMachineGroupListResponse> {
    return this.apiService.apiGet<any>(`/user/${req.userId}/machine-group?all=1`).pipe(
      switchMap((res): Observable<FetchMachineGroupListResponse> => {
        const response = res as {groups: Array<string>};
        return this.fetchPlayedMachineGroupList(req).pipe(
          map((result: FetchPlayedMachineGroupListResponse): FetchMachineGroupListResponse => ({
            error: result.error,
            machineGroupList: response.groups.map(g => ({name: g})),
            playedMachineGroupList: result.playedMachineGroupList,
          })
        ));
      }),
      catchError((err: HttpErrorResponse): Observable<FetchMachineGroupListResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          machineGroupList: null,
          playedMachineGroupList: null
        });
      }),
    );
  }

  fetchPlayedMachineGroupList(req: FetchMachineListRequest): Observable<FetchPlayedMachineGroupListResponse> {
    return this.apiService.apiGet<any>(`/user/${req.userId}/machine-group${req.username ? '?usr=' + req.username : ''}`).pipe(
      map((res): FetchPlayedMachineGroupListResponse => {
        const response = res as {groups: Array<string>};
        return {
          error: null,
          playedMachineGroupList: response.groups.map(g => ({name: g}))
        };
      }),
      catchError((err: HttpErrorResponse): Observable<FetchPlayedMachineGroupListResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          playedMachineGroupList: null
        });
      }),
    );
  }

  fetchSessionList(req: FetchSessionListRequest): Observable<FetchSessionListResponse> {
    const filters = {
      ...req.filters,
      count: '100',
    };
    return this.apiService.apiGet<any>(`/user/${req.userId}/training-session${FilterModel.constructFilterString(filters)}`).pipe(
      switchMap((res): Observable<FetchSessionListResponse> => {
        return this.loginService.fetchUserStats({userId: req.userId, filters}).pipe(
          map((stats: FetchUserStatsResponse): FetchSessionListResponse => ({
            error: stats.error,
            sessionList: (res as Array<TrainingSessionModel>).map((r: TrainingSessionModel) => {
              const percent = (r.hits !== null && r.sessionMisses !== null ? +((r.hits / (r.hits + +r.sessionMisses)) * 100).toFixed(2) : 0);
              let sessionScore = r.sessionScore; // r.sessionCode === 'SPR1' && r.sessionScore !== null ? (+r.sessionScore / 1000).toFixed(2) : r.sessionScore;

              if (r.scoreBy === 'min time' && r.sessionScore) {
                const s = moment.duration(+r.sessionScore).asSeconds();
                sessionScore = `${s && s < 0.1 ? 0.1 : s}`;
              }
              return {
                ...r,
                passPercentage: !!percent ? percent + '%' : 'N/A',
                sessionScore: sessionScore ? sessionScore : 'N/A'
              };
            }),
            userId: req.userId,
            sessionStats: stats.userStats,
            filters: req.filters
          })
        ));
      }),
      catchError((err: HttpErrorResponse): Observable<FetchSessionListResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          sessionList: null,
          userId: req.userId,
          sessionStats: null,
          filters: req.filters
        });
      })
    );
  }

  fetchCoachedUserList(req: FetchCoachedUserListRequest): Observable<FetchCoachedUserListResponse> {
    return this.apiService.apiGet<any>(`/user/${req.userId}/coached-user`).pipe(
      map((res): FetchCoachedUserListResponse => {
        const response = res as {users: Array<CoachedUserModel>};
        return {
          error: null,
          coachedUserList: response.users
        };
      }),
      catchError((err: HttpErrorResponse): Observable<FetchCoachedUserListResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          coachedUserList: null
        });
      }),
    );
  }

  fetchSession(req: FetchSessionRequest): Observable<FetchSessionResponse> {
    return this.apiService.apiGet<any>(`/user/${req.userId}/training-session/${req.sessionId}${req.usr ? `?usr=${req.usr}` : ''}`).pipe(
      map((res): FetchSessionResponse => {
        const session = SingleTrainingSessionModel.fromApiData(res);
        return {
          error: null,
          session
        };
      }),
      catchError((err: HttpErrorResponse): Observable<FetchSessionResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          session: null
        });
      }),
    );
  }
}
