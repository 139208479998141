import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SyncButtonComponent } from './sync-button.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    FontAwesomeModule
  ],
  exports: [
    SyncButtonComponent
  ],
  declarations: [
    SyncButtonComponent
  ],
})
export class SyncButtonComponentModule { }
