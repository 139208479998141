import { Component } from '@angular/core';

import { State as LoginState } from '../../../state-management/reducers/login.reducer';
import { StoreState } from 'src/app/state-management/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-share-index-modal',
  templateUrl: './share-index-modal.component.html',
  styleUrls: ['./share-index-modal.component.sass']
})
export class ShareIndexModalComponent {
  public loginReducer$: Observable<LoginState>;
  public facebookIcon = faFacebookF;
  public twitterIcon = faTwitter;

  constructor(
    private store: Store<StoreState>
  ) {
    this.loginReducer$ = this.store.select('login');
  }

}
