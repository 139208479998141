import { AlertModel } from './alert.model';
import { APIFriendCommunityModel, APIFriendInvitation, FriendInvitationDetails } from './api-models/api-friend.model';
import { APIHelper } from './api-models/api-helper.model';
import { APINotificationModel } from './api-models/api-notification.model';

export class NotificationModel {
  id!: string;
  type!: string;
  title!: string;
  message!: string;
  notifiableType!: string;
  notifiableId!: boolean;
  data!: {
    invitation?: APIFriendInvitation,
    recipient?: FriendInvitationDetails,
    message?: APIFriendInvitation,
    community?: APIFriendCommunityModel,
    sender?: FriendInvitationDetails
  };
  readAt!: string;
  createdAt!: string;
  updatedAt!: string;

  static fromApiData(apiData: APINotificationModel): NotificationModel {
    const model:NotificationModel = APIHelper.APItoJSON(apiData);

    model.type = model.type.replace('App\\Notifications\\', '');

    /**
     * InvitationAcceptedNotification
     * InvitationRejectedNotification
     * InvitationToJoinNotification
     * MessageNotification
     * PasswordResetNotification
     * RequestToJoinAcceptedNotification
     * RequestToJoinNotification
     * RequestToJoinRejectedNotification
     * StorageLimitNotification
     */
    switch(model.type) {
      case 'InvitationAcceptedNotification':
        if (model.data.invitation?.data.community_data.type === 'leaderboard') {
          model.title = 'A friend has joined your leaderboard!';
          model.message = `${model.data.recipient?.name} has joined your leaderboard`;
        }
        else {
          model.title = 'Friend request Accepted!';
          model.message = `${model.data.recipient?.name} has accepted your friend request`;
        }
        break;
      case 'InvitationRejectedNotification':
        if (model.data.invitation?.data.community_data.type === 'leaderboard') {
          model.title = 'A friend has declined the invitation your leaderboard!';
          model.message = `${model.data.recipient?.name} declined the request to join your leaderboard`;
        }
        else {
          model.title = 'Friend request refused';
          model.message = `${model.data.recipient?.name} has refused your friend request`;
        }
        break;
      case 'InvitationToJoinNotification':
        if (model.data.message?.data.community_data.type === 'friends') {
          model.title = 'New friend request';
          model.message = `${model.data.sender?.name} has invited you to become their friend`;
        }
        else {
          model.title = 'Invitation to join leaderboard';
          model.message = `${model.data.sender?.name} has invited you to join their leaderboard`;
        }
        break;
      default:
        model.title = model.type;
        model.message = "You've received a new notification";
    }
    return model;
  }
}

export interface FetchNotificationListRequest {
  unread?: boolean;
}
export interface FetchNotificationListResponse {
  error:  AlertModel | null;
  notificationArray: Array<NotificationModel> | null;
}

export interface MarkNotificationReadRequest {
  read: boolean;
  notificationId?: string;
}
export interface MarkNotificationReadResponse {
  error:  AlertModel | null;
  notificationId?: string;
  notificationResponse: NotificationModel | Array<NotificationModel> | null;
}

export interface DeleteNotificationRequest {
  notificationId?: string;
}
export interface DeleteNotificationResponse {
  error:  AlertModel | null;
  notificationId?: string;
}
