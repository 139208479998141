import { Component, Input } from '@angular/core';
import { AlertModel } from 'src/app/models/alert.model';

@Component({
  selector: 'app-loading-card',
  templateUrl: './loading-card.component.html',
  styleUrls: ['./loading-card.component.sass']
})
export class LoadingCardComponent {
  @Input() alert: AlertModel | null = null;
  @Input() centered = false;
}
