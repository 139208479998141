import { Component, Input, Output, EventEmitter} from '@angular/core';
import { NotificationStatCollection } from 'src/app/models/profile.model';

@Component({
  selector: 'app-sub-nav',
  templateUrl: './sub-nav.component.html',
  styleUrls: ['./sub-nav.component.sass']
})
export class SubNavComponent {
  @Input() currentParentPage: string = '';
  @Input() isChild: boolean = false;
  @Input() userId: number | undefined;
  @Input() username: string | undefined;
  @Input() esaId: string | undefined;
  @Input() stats: NotificationStatCollection | null | undefined;
  @Input() fullName: string | undefined;
  @Output() logout = new EventEmitter();
}
