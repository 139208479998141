import { Component, Output, EventEmitter } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { filter } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StoreState } from '../../../../state-management/store';
import { State as PageState } from '../../../../state-management/reducers/page.reducer';
import { State as LoginState } from '../../../../state-management/reducers/login.reducer';
import { LogoutAction } from 'src/app/state-management/actions/login.actions';
import { LoginService } from 'src/app/services/login.service';
import { UserProfile } from 'src/app/models/profile.model';


@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.sass']
})
export class PageHeaderComponent {
  @Output() toggleDrawer = new EventEmitter();
  public userIcon = faUser;
  public barIcon = faBars;
  public bellIcon = faBell;

  public page$: Observable<PageState>;
  public login$: Observable<LoginState>;

  // Store the parent page that's currently visible
  public parentPage = '';
  public isChild = false;

  constructor(
    public router: Router,
    private store: Store<StoreState>,
    private loginService: LoginService
  ) {
    // Create reference to login store
    this.page$ = this.store.select('page');
    this.login$ = this.store.select('login');

    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(e => {
      const event = e as NavigationEnd;
      this.parentPage = event.url ? event.url.replace('/', '').split('/')[0] : '';
      this.parentPage = this.parentPage.split('?')[0];

      this.isChild = event.url && event.url.split('/').length > 2 ? true : false;
    });
  }

  openUsernameModal(user: UserProfile): void {
    this.loginService.openLinkAccountModal(user);
  }

  logout(): void {
    this.store.dispatch(LogoutAction({message: null}));
  }
}
