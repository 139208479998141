import { Component } from '@angular/core';

@Component({
  selector: 'app-partner-icons',
  templateUrl: './partner-icons.component.html',
  styleUrls: ['./partner-icons.component.sass']
})
export class PartnerIconsComponent {
  public centerLogoUrls: Array<string> = [];

  constructor() {
    const iteratorArr: Array<number> = Array.apply(null, new Array(12)) as Array<number>;
    this.centerLogoUrls = iteratorArr.map((_: any, i: number) => `assets/logos/centers/esa-center-logo-${i}.png`);
  }
}
