import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialCommonModule } from '../../../../module-common/material-common.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { LoadingCardComponent } from './loading-card.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialCommonModule,
    FontAwesomeModule
  ],
  exports: [
    LoadingCardComponent
  ],
  declarations: [LoadingCardComponent],
})
export class LoadingCardModule { }
