import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { CheckLoginTokenRequestAction } from './state-management/actions/login.actions';

import { StoreState } from './state-management/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  constructor(
    private store: Store<StoreState>
  ) {
    const storedToken = window.localStorage.getItem(environment.id + '_login_token');
    if (storedToken) {
      this.store.dispatch(CheckLoginTokenRequestAction({
        token: storedToken
      }));
    }
  }
}
