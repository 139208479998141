import { Inject, Injectable, Optional } from '@angular/core';

// Date adapter
import { MomentDateAdapter  } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE    } from '@angular/material/core';

@Injectable()
export class CustomMomentDateAdapter extends MomentDateAdapter {
  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
  }
  getFirstDayOfWeek(): number {
    return 1;
  }
}

export const ESA_DATE_FORMATS = {
  display: {
    dateInput: 'DD/MM/YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
