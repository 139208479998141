import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { FilterModel } from 'src/app/models/filter.model';
import { FetchLeaderboardDataRequest } from 'src/app/models/leaderboard.model';
import { FriendService } from 'src/app/services/friend.service';
import { LeaderboardService } from 'src/app/services/leaderboard.service';

import * as ActionTypes from '../actions/leaderboard.actions';
import { StoreState } from '../store';

@Injectable()
export class LeaderboardEffects {
  constructor(
    private actions$: Actions,
    private leaderboardService: LeaderboardService,
    private friendService: FriendService,
    private store: Store<StoreState>
  ) {}

  fetchLeaderboardData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchLeaderboardDataRequestAction),
    withLatestFrom(this.store),
    switchMap(([action, store]: [FetchLeaderboardDataRequest, StoreState]) => this.leaderboardService.fetchLeaderboardData(action, store.session.gameTypeList, store.leaderboard.leaderboardFilter).pipe(
      map(res => ActionTypes.FetchLeaderboardDataResponseAction(res)),
    )),
  ));

  postCommunityLeaderboard$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PostCommunityLeaderboardRequestAction),
    switchMap((action) => this.friendService.postCommunity(action).pipe(
      map(res => ActionTypes.PostCommunityLeaderboardResponseAction(res)),
    )),
  ));

  fetchCommunityLeaderboardList$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchCommunityLeaderboardListRequestAction),
    switchMap(() => this.leaderboardService.fetchCommunityLeaderboardList().pipe(
      map(res => ActionTypes.FetchCommunityLeaderboardListResponseAction(res)),
    )),
  ));

  fetchCommunityLeaderboard$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchCommunityLeaderboardRequestAction),
    switchMap((action) => this.leaderboardService.fetchCommunityLeaderboard(action).pipe(
      map(res => ActionTypes.FetchCommunityLeaderboardResponseAction(res)),
    )),
  ));

  patchCommunityLeaderboard$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PatchCommunityLeaderboardRequestAction),
    switchMap((action) => this.friendService.patchCommunity(action).pipe(
      map(res => ActionTypes.PatchCommunityLeaderboardResponseAction(res)),
    )),
  ));
}
