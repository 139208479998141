import { AlertModel } from './alert.model';
import { APIHelper } from './api-models/api-helper.model';
import { SingleTrainingSessionAPIModel } from './api-models/api-session.model';
import { FilterModel } from './filter.model';
import { CoachedUserModel, UserStats } from './profile.model';
import * as moment from 'moment';

export class SessionFilterUtil {
  static getIntervalList(): Array<{label: string, value: number}> {
    return [
      {label: '10s', value: 10},
      {label: '20s', value: 20},
      {label: '30s', value: 30},
      {label: '40s', value: 40},
      {label: '50s', value: 50},
      {label: '60s', value: 60},
      {label: '70s', value: 70},
      {label: '80s', value: 80},
      {label: '90s', value: 90},
      {label: '100s', value: 100},
      {label: '110s', value: 110},
      {label: '120s', value: 120},
      {label: '3 mins', value: 180},
      {label: '4 mins', value: 240},
      {label: '5 mins', value: 300},
      {label: '6 mins', value: 360},
      {label: '7 mins', value: 420},
      {label: '8 mins', value: 480},
      {label: '9 mins', value: 540},
      {label: '10 mins', value: 600},
    ];
  }
}

export interface AnalysisFilters {
  machine: string;
  product: string | Array<string>;
  duration: number;
  code: string | Array<string>;
  dateRange?: { start: string; finish: string; };
  start: string;
  finish: string;
  usr: string;
}

export interface MachineModel {
  machineName: string;
  machineAlias: string;
  configuration: string | null;
}

export interface MachineGroupModel {
  name: string;
}

export interface GameTypeModel {
  code:     string;
  fullName: string;
  scoreBy:  string;
}

export interface TrainingSessionModel {
  id:                number;
  trainingSessionId: string;
  sessionCode:       string;
  machineName:       string;
  sessionTime:       string;
  data:              string;
  sessionName:       string;
  sessionDuration:   number;
  sessionMisses:     string;
  sessionPlayer:     string;
  scoreBy:           string;
  hits:              number;
  sessionScore:      string;
  color?:            string;
  graphIndex?:       number;
  nodeIndex?:        number;
  passPercentage?:   string;
}


export interface  SingleTrainingSessionGameDataModel {
  bestTime: number;
  countdown: number;
  hitAverage: number;
  hitTimeAverage: number;
  hitTimeAverageFormatted: string;
  hits: number;
  missAverage: number;
  misses: number;
  runningTotalTime: number;
  runningTotalTimeFormatted: string;
  segments: number;
  totalShots: number;
  worstTime: number;
  worstTimeFormatted: string;
}
export class SingleTrainingSessionModel {
  id!:                number;
  hits!:              number;
  machineAlias!:      string;
  machineName!:       string;
  product!:           string;
  productType!:       string;
  scoreBy!:           string;
  sessionCode!:       string;
  sessionData?:       {
    healthData?: SessionHealthCollection;
  };
  sessionDuration!:   number;
  sessionMisses!:     string;
  sessionName!:       string;
  sessionPlayer!:     string;
  sessionScore!:      string;
  sessionTime!:       string;
  trainingSessionId!: string;
  gameData!: SingleTrainingSessionGameDataModel;
  sessionDurationFormatted!: string;

  static fromApiData(apiData: SingleTrainingSessionAPIModel): SingleTrainingSessionModel {
    const model: SingleTrainingSessionModel = APIHelper.APItoJSON(apiData);
    model.gameData = APIHelper.APItoJSON(apiData.game_data);

    if (apiData.session_data && !(typeof apiData.session_data === 'string')) {
      model.sessionData = APIHelper.APItoJSON(apiData.session_data);
    }

    if (model.sessionDuration) {
      model.sessionDuration = model.sessionDuration / 10;
    }
    model.sessionDurationFormatted = model.sessionDuration ? moment.utc(+model.sessionDuration * 1000).format('mm:ss') : '0';
    model.gameData.hitTimeAverageFormatted = model.gameData.hitTimeAverage ? moment.utc(+model.gameData.hitTimeAverage).format('HH:mm:ss') : '0';
    model.gameData.worstTimeFormatted = model.gameData.worstTime ? moment.utc(+model.gameData.worstTime).format('HH:mm:ss') : '0';

    model.gameData.runningTotalTimeFormatted = moment.utc(+model.gameData.runningTotalTime).format('HH:mm:ss');

    if (model.scoreBy === 'min time' && model.sessionScore) {
      const s = moment.duration(+model.sessionScore).asSeconds();
      model.sessionScore = `${s && s < 0.1 ? 0.1 : s}`;
    }

    return model;
  }
}

export interface SessionHealthDataEntry {
  timeStamp: string;
  value:     number | string;
}
export interface SessionHealthData {
  type: string;
  unit: string;
  data: Array<SessionHealthDataEntry>;
}

export interface SessionHealthCollectionSingleItem {
  value: number;
  unit: string;
}
export interface SessionHealthCollection {
  steps:     SessionHealthCollectionSingleItem;
  heartRate: SessionHealthData;
  distance:  SessionHealthCollectionSingleItem;
  calories:  SessionHealthCollectionSingleItem;
}

export interface FetchGameTypeListRequest {
  userId: number;
  username: string;
}
export interface FetchGameTypeListResponse {
  gameTypeList:  Array<GameTypeModel> | null;
  playedGameTypeList: Array<GameTypeModel> | null;
  error:         AlertModel | null;
  userId: number;
}

export interface FetchPlayedGameListResponse {
  playedGameTypeList: Array<GameTypeModel> | null;
  error:              AlertModel | null;
}

export interface FetchMachineListRequest {
  userId: number;
  username: string;
}
export interface FetchMachineListResponse {
  machineList:  Array<MachineModel> | null;
  error:        AlertModel | null;
  userId:       number;
}

export interface FetchMachineGroupListResponse {
  machineGroupList:  Array<MachineGroupModel> | null;
  playedMachineGroupList: Array<MachineGroupModel> | null;
  error:        AlertModel | null;
}

export interface FetchPlayedMachineGroupListResponse {
  playedMachineGroupList: Array<MachineGroupModel> | null;
  error:                  AlertModel | null;
}

export interface FetchSessionListRequest extends FilterModel {
  userId: number;
  esaUsername: string;
  esaId: string;
  modalUpdate?: boolean;
}

export interface FetchSessionListResponse extends FilterModel {
  sessionList: Array<TrainingSessionModel> | null;
  userId: number;
  error:       AlertModel | null;
  sessionStats: UserStats | null;
}

export interface FetchCoachedUserListRequest {
  userId: number;
}
export interface FetchCoachedUserListResponse {
  coachedUserList: Array<CoachedUserModel> | null;
  error:       AlertModel | null;
}


export interface FetchSessionRequest {
  sessionId: number;
  userId: number;
  usr: number | null;
}

export interface FetchSessionResponse {
  session: SingleTrainingSessionModel | null;
  error: AlertModel | null;
}
