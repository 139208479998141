import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import {
  map,
  tap,
  switchMap,
  filter,
  withLatestFrom
} from 'rxjs/operators';

import { StoreState } from '../../state-management/store';
import { LoginService } from '../../services/login.service';

import * as ActionTypes from '../actions/login.actions';

import {
  ActivateAccountResponse,
  FetchNotificationStatsResponse,
  FetchUserIndexAttachmentResponse,
  LinkEsaAccountResponse,
  LoginResponse,
  RequestResetPasswordResponse,
  ResetPasswordResponse,
  StoreUserIndexResponse,
  TokenCheckResponse,
} from '../../models/login.model';

import { FetchUserStatsResponse, PatchUserProfileResponse, UserProfile } from '../../models/profile.model';

// Actions to fire upon login
import { FetchCoachedUserListRequestAction, FetchGameTypeListRequestAction, FetchMachineGroupListRequestAction } from '../actions/session.actions';
import { FetchCommunityListRequestAction, RequestFetchFriendListAction, RequestFetchInvitationListAction } from '../actions/friend.actions';
import { interval, Subscription } from 'rxjs';
import { FetchCommunityLeaderboardListRequestAction } from '../actions/leaderboard.actions';
import { FetchUserIndexRequestAction } from '../actions/login.actions';

@Injectable()
export class LoginEffects {
  private notificationSubscription!: Subscription;

  constructor(
    private actions$: Actions,
    private loginService: LoginService,
    private router: Router,
    private store: Store<StoreState>
  ) {}

  handleLogin(user: UserProfile | null, linkedRedirect: boolean = false): void {
    const url = window.location.href;
    
    if (url.includes('login') || linkedRedirect) {
      this.router.navigate([user?.userData?.profileConfirmed && user?.esaUsername ? '/player/profile' : '/player/edit']);
    }

    this.fetchInitialData(user);
  }

  fetchInitialData(user: UserProfile | null): void {
    if (user && user.esaId) {
      // Will trigger further requests in game type response
      this.store.dispatch(FetchGameTypeListRequestAction({userId: user.id, username: user.esaId}));
      this.store.dispatch(FetchMachineGroupListRequestAction({userId: user.id, username: user.esaId}));
      this.store.dispatch(FetchCommunityListRequestAction({userId: user.id}));
      this.store.dispatch(FetchCommunityLeaderboardListRequestAction());
      this.store.dispatch(FetchUserIndexRequestAction({userId: user.id}));

      if (user.esaData?.userTypeID === '3') {
        this.store.dispatch(FetchCoachedUserListRequestAction({userId: user.id}));
      }
    }
    // Setup stat poll
    const notificationPollInterval = interval(30000);
    this.notificationSubscription = notificationPollInterval.subscribe(
      () => this.store.dispatch(ActionTypes.FetchNotificationStatsRequestAction())
    );
  }

  checkToken$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CheckLoginTokenRequestAction),
    switchMap(req => this.loginService.checkToken(req).pipe(
      map((res: TokenCheckResponse) => ActionTypes.CheckLoginTokenResponseAction(res)),
    ))
  ));

  checkTokenResponse$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CheckLoginTokenResponseAction),
    filter((res: TokenCheckResponse) => res.user !== null),
    tap((req: TokenCheckResponse) => {
      if (req.accountLink) {
        this.notificationSubscription.unsubscribe();
      }
      this.handleLogin(req.user ? req.user : null, req.accountLink);
    })
  ), { dispatch: false });

  /**
   * For a LoginRequestAction, call LoginService::login() and dispatch
   * a new LoginResponseAction with the response. Also store the
   * user's token if the response was valid.
   */
  loginRequest$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.LoginRequestAction),
    switchMap(req => this.loginService.login(req).pipe(
      map((res: LoginResponse) => ActionTypes.LoginResponseAction(res))
    ))
  ));

  loginResponse$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.LoginResponseAction),
    filter((res: LoginResponse) => res.token !== null),
    tap((req: LoginResponse) => this.handleLogin(req.user ? req.user : null))
  ), { dispatch: false });

  activateAccount$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ActivateAccountRequestAction),
    switchMap(req => this.loginService.activateAccount(req).pipe(
      map((res: ActivateAccountResponse) => ActionTypes.ActivateAccountResponseAction(res))
    ))
  ));

  /**
   * For a LogoutAction, redirect the client to "/". Do not dispatch
   * any further actions.
   */
  logout$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.LogoutAction),
    map((): void => {
      if (this.notificationSubscription) {
        this.notificationSubscription.unsubscribe();
      }
      this.loginService.logout();
      this.router.navigate(['/']);
    })
  ), { dispatch: false });

  requestResetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.RequestResetPasswordRequestAction),
    switchMap(req => this.loginService.requestResetPassword(req).pipe(
      map((res: RequestResetPasswordResponse) => ActionTypes.RequestResetPasswordResponseAction(res)),
    ))
  ));

  resetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ResetPasswordRequestAction),
    switchMap(req => this.loginService.resetPassword(req).pipe(
      map((res: ResetPasswordResponse) => ActionTypes.ResetPasswordResponseAction(res)),
    ))
  ));

  updateProfile$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PatchUserProfileRequestAction),
    switchMap(req => this.loginService.patchUserProfile(req).pipe(
      map((res: PatchUserProfileResponse) => ActionTypes.PatchUserProfileResponseAction(res))
    ))
  ));
  updateProfileResponse$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PatchUserProfileResponseAction),
    filter((res: PatchUserProfileResponse) => !res.error),
    tap((req: PatchUserProfileResponse) => {
      this.store.dispatch(ActionTypes.RemovePatchedFlag());
      if (req.resetUserData) {
        this.store.dispatch(ActionTypes.ResetDataAction());
      }
    })
  ), { dispatch: false });

  fetchUserStats$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchUserStatsRequestAction),
    switchMap(req => this.loginService.fetchUserStats(req).pipe(
      map((res: FetchUserStatsResponse) => ActionTypes.FetchUserStatsResponseAction(res))
    ))
  ));

  linkEsaAccount$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.LinkEsaAccountRequestAction),
    switchMap(req => this.loginService.linkEsaAccount(req).pipe(
      map((res: LinkEsaAccountResponse) => ActionTypes.LinkEsaAccountResponseAction(res))
    ))
  ));

  linkEsaAccountResponse$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.LinkEsaAccountResponseAction),
    filter((res: LinkEsaAccountResponse) => !res.error),
    withLatestFrom(this.store),
    tap(([res, store]: [LinkEsaAccountResponse, StoreState]) => {
      this.store.dispatch(ActionTypes.CheckLoginTokenRequestAction({
        token: store.login.token as string,
        accountLink: true
      }));
    })
  ), { dispatch: false });

  fetchNotificationStats$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchNotificationStatsRequestAction),
    switchMap(() => this.loginService.fetchNotificationStats().pipe(
      map(res => ActionTypes.FetchNotificationStatsResponseAction(res)),
    ))
  ));
  fetchNotificationStatsResponse$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchNotificationStatsResponseAction),
    filter((res: FetchNotificationStatsResponse) => !res.error),
    withLatestFrom(this.store),
    tap(([res, store]: [FetchNotificationStatsResponse, StoreState]) => {
      if (store.login.previousNotificationStats) {
        if (store.login.previousNotificationStats.invitations.total !== res.notificationStats?.invitations.total) {
          this.store.dispatch(RequestFetchInvitationListAction());
        }
        if (store.login.previousNotificationStats.friends.total !== res.notificationStats?.friends.total) {
          this.store.dispatch(RequestFetchFriendListAction());
        }
      }
    })
  ), { dispatch: false });

  fetchUserIndex$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchUserIndexRequestAction),
    switchMap((action) => this.loginService.fetchUserIndex(action).pipe(
      map(res => ActionTypes.FetchUserIndexResponseAction(res)),
    ))
  ));

  storeUserIndex$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.StoreUserIndexRequestAction),
    switchMap((action) => this.loginService.storeUserIndex(action).pipe(
      map(res => ActionTypes.StoreUserIndexResponseAction(res)),
    ))
  ));
  storeUserIndexResponse$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.StoreUserIndexResponseAction),
    filter((res: StoreUserIndexResponse) => !res.error),
    tap((res: StoreUserIndexResponse) => {
      this.store.dispatch(ActionTypes.FetchUserIndexAttachmentRequestAction({userId: res.userId, userName: res.userName}));
    })
  ), { dispatch: false });

  fetchUserIndexAttachment$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchUserIndexAttachmentRequestAction),
    switchMap((action) => this.loginService.fetchUserIndexAttachment(action).pipe(
      map(res => ActionTypes.FetchUserIndexAttachmentResponseAction(res)),
    ))
  ));
  fetchUserIndexAttachmentResponse$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchUserIndexAttachmentResponseAction),
    filter((res: FetchUserIndexAttachmentResponse) => !res.error),
    tap((res: FetchUserIndexAttachmentResponse) => this.loginService.openAccountModal())
  ), { dispatch: false });

  openShareIndexModal$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.OpenShareIndexModal),
    tap((action) => this.loginService.openAccountModal())
  ), { dispatch: false });
}
