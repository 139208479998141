<ng-container *ngIf="session$ | async; let sessionState">
    <form [formGroup]="fg" novalidate [class.modal]="isModal" [class.disable-selection]="!username" *ngIf="fg">
        <app-sync-button
            class="display-flex"
            [icon]="true"
            [pending]="sessionState.pending"
            [showClear]="true"
            (handleClick)="refreshResults(sessionState.analysisFilter)"
            (handleClear)="clearFilters()"
            *ngIf="!isModal">
        </app-sync-button>
        <div class="input-field-container clear-white" [class.border-bottom]="fg.value.usr" *ngIf="sessionState.coachedUserList">
            <mat-select formControlName="usr" [panelWidth]="null" [placeholder]="(fullName ? fullName : (username ? username : 'user'))" (selectionChange)="handleUserSelection($event)">
                <mat-option [value]="null">{{fullName ? fullName : username}}</mat-option>
                <ng-container *ngIf="sessionState.coachedUserList?.length">
                    <mat-option [value]="user.userID" *ngFor="let user of sessionState.coachedUserList">{{user.userFirstname}} {{user.userLastname}}</mat-option>
                </ng-container>
            </mat-select>
        </div>
        <div class="input-field-container clear-white" [class.border-bottom]="fg.value.code && fg.value.code.length">
            <mat-select formControlName="code" [panelWidth]="null" [placeholder]="(sessionState.pendingTasks['FETCH_PLAYED_TYPE_LIST_REQUEST'] ? 'Loading...' : 'Training Mode')" (selectionChange)="determineGameType($event, sessionState.playedGameTypeList)" multiple>
                <mat-option [value]="null" *ngIf="!sessionState.pending && !sessionState.playedGameTypeList?.length" disabled>No modes available</mat-option>
                <ng-container *ngIf="sessionState.playedGameTypeList?.length">
                    <mat-option [value]="gameType.code" *ngFor="let gameType of sessionState.playedGameTypeList">{{gameType.fullName}}</mat-option>
                </ng-container>
            </mat-select>
        </div>
        <div class="input-field-container clear-white" [class.border-bottom]="fg.value.product && fg.value.product.length">
            <mat-select formControlName="product" [panelWidth]="null" [placeholder]="(sessionState.pendingTasks['FETCH_PLAYED_MACHINE_GROUP_LIST_REQUEST'] ? 'Loading...' : 'Product')" multiple>
                <mat-option [value]="null" *ngIf="!sessionState.pending && !sessionState.playedMachineGroupList?.length" disabled>No products available</mat-option>
                <ng-container *ngIf="sessionState.playedMachineGroupList?.length">
                    <mat-option [value]="machine.name" *ngFor="let machine of sessionState.playedMachineGroupList">{{machine.name}}</mat-option>
                </ng-container>
            </mat-select>
        </div>
        <div class="input-field-container clear-white" [class.border-bottom]="fg.value.duration">
            <mat-select formControlName="duration" [panelWidth]="null" placeholder="Duration">
                <mat-option [value]="null">All</mat-option>
                <mat-option [value]="duration.value" *ngFor="let duration of durationArray">{{duration.label}}</mat-option>
            </mat-select>
        </div>
        <div class="input-field-container clear-white white-theme date display-flex" [class.border-bottom]="fg.value.dateRange.start || fg.value.dateRange.finish">
            <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker" [max]="maxDate">
                <input matStartDate formControlName="start" placeholder="Start" (click)="picker.open()">
                <input matEndDate formControlName="finish" placeholder="End" (click)="picker.open()">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </div>
    </form>
</ng-container>