import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  TokenRequiredAuthGuardService,
  HidePublicAuthGuardService,
} from './services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  {
    path: 'login',
    canActivate: [HidePublicAuthGuardService],
    loadChildren: () => import('./views/pages/login-page/login-page.module').then((m) => m.LoginPageModule)
  },

  {
    path: 'player',
    canActivate: [TokenRequiredAuthGuardService],
    loadChildren: () => import('./views/pages/player-page/player-page.module').then((m) => m.PlayerPageModule),
  },

  {
    path: 'analysis',
    canActivate: [TokenRequiredAuthGuardService],
    loadChildren: () => import('./views/pages/analysis-page/analysis-page.module').then((m) => m.AnalysisPageModule),
  },

  {
    path: 'leaderboard',
    canActivate: [TokenRequiredAuthGuardService],
    loadChildren: () => import('./views/pages/leaderboard-page/leaderboard-page.module').then((m) => m.LeaderboardPageModule),
  },

  {
    path: 'friends',
    canActivate: [TokenRequiredAuthGuardService],
    loadChildren: () => import('./views/pages/friend-page/friend-page.module').then((m) => m.FriendPageModule),
  },

  {
    path: 'notifications',
    canActivate: [TokenRequiredAuthGuardService],
    loadChildren: () => import('./views/pages/notification-page/notification-page.module').then((m) => m.NotificationPageModule),
  },

  // Fall-through to LoginPageComponent
  {
    path: '**',
    loadChildren: () => import('./views/pages/login-page/login-page.module').then((m) => m.LoginPageModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  providers:[
    TokenRequiredAuthGuardService,
    HidePublicAuthGuardService
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
