import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ConfirmModel {
  title: string;
  description: string;
  confirmText: string;
  hideClose?: boolean;
}
@Component({
  selector: 'esa-account-email-modal',
  templateUrl: './esa-account-email-modal.component.html',
  styleUrls: ['./esa-account-email-modal.component.sass']
})
export class EsaAccountEmailModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModel,
  ) { }
}
