<mat-drawer-container>
    <mat-drawer #drawer mode="over" position="end">
        <app-sidebar-contents (toggleDrawer)="drawer.toggle()"></app-sidebar-contents>
    </mat-drawer>
    <mat-drawer-content>
        <app-page-header (toggleDrawer)="drawer.toggle()"></app-page-header>
        <router-outlet></router-outlet>
        <app-page-footer></app-page-footer>
    </mat-drawer-content>
</mat-drawer-container>

