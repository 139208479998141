import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
} from 'rxjs/operators';

import { ApiService } from './api.service';
import { ThemeSwitcherService } from './theme-switcher.service';

import { environment } from '../../environments/environment';

import {
  LoginRequest,
  LoginResponse,
  RequestResetPasswordRequest,
  RequestResetPasswordResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  TokenCheckResponse,
  ActivateAccountRequest,
  ActivateAccountResponse,
  LinkEsaAccountRequest,
  LinkEsaAccountResponse,
  TokenCheckRequest,
  FetchNotificationStatsResponse,
  FetchUserIndexRequest,
  FetchUserIndexResponse,
  StoreUserIndexRequest,
  StoreUserIndexResponse,
  FetchUserIndexAttachmentRequest,
  FetchUserIndexAttachmentResponse,
} from '../models/login.model';

import {
  EditSettingModalResponse,
  FetchUserStatsRequest,
  FetchUserStatsResponse,
  NotificationStatCollection,
  PatchUserProfileRequest,
  PatchUserProfileResponse,
  UserIndexModel,
  // UserPhoto,
  UserProfile,
  UserStats,
} from '../models/profile.model';

import { HttpErrorResponse } from '@angular/common/http';
import { APIUserModel, APIUserStatsModel, UserAttachmentAPIModel } from '../models/api-models/api-user.model';
import { AlertModel } from '../models/alert.model';
import { EditSettingModalComponent } from '../views/modals/edit-setting-modal/edit-setting-modal.component';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LinkEsaAccountRequestAction } from '../state-management/actions/login.actions';
import { StoreState } from '../state-management/store';
import { Store } from '@ngrx/store';
import { FilterModel } from '../models/filter.model';
import { APIHelper } from '../models/api-models/api-helper.model';
import { ShareIndexModalComponent } from '../views/modals/share-index-modal/share-index-modal.component';

/**
 * This service allows a user to do the following: -
 *  - Log in and out of the app
 *  - Fetch and update the user's profile
 *  - Reset the user's password
 *  - Check and store their login token
 */
@Injectable({
  providedIn: 'root',
})
export class LoginService {

  constructor(
    private apiService: ApiService,
    // private themeService: ThemeSwitcherService,
    private dialog: MatDialog,
    private store: Store<StoreState>,
  ) {
  }

  openLinkAccountModal(user: UserProfile): void {
    this.dialog.open(EditSettingModalComponent, {
      width: '420px',
      panelClass: 'branded-modal-dialog',
      data: {
        user,
        mode: 'EDIT_MODE_LINK',
        title: 'Link accounts',
        closeLabel: 'Link',
        valueValidators: [Validators.required],
        valueType: 'text',
        valueInitial: user.esaUsername,
        valuePlaceholder: 'Username',
        secondaryType: 'password',
        secondryInitial: '',
        secondaryPlaceholder: 'Password',
        secondaryValidators: [Validators.required]
      }
    });
  }
  openAccountModal(): void {
    this.dialog.open(ShareIndexModalComponent, {
      width: '420px',
      panelClass: 'branded-modal-dialog',
    });
  }

  /**
   * Request /current-user to obtain a user's profile data if their
   * token is valid.
   */
  checkToken(req: TokenCheckRequest): Observable<TokenCheckResponse> {
    return this.apiService.apiGet('/current-user').pipe(
      map(res => {
        const response = res as APIUserModel;
        const apiUser: UserProfile = UserProfile.fromApiData(response);
        const cId = this.apiService.getApiContextId();
        return {
          contextId: cId ? cId : response.api_context_id,
          error:     null,
          ready:     true,
          valid:     true,
          user:      apiUser,
          accountLink: !!req.accountLink
        };
      }),
      catchError(() => {
        window.localStorage.removeItem(environment.id + '_login_token');
        window.localStorage.removeItem(`${environment.id}_context_id`);

        return of({
          contextId: null,
          error:     AlertModel.handleApiMessage('An invalid token was submitted', 'warning'),
          ready:     true,
          user:      null,
          valid:     false,
          accountLink: false
        });
      }),
    );
  }

  /**
   * Attempt to authenticate using the /login route.
   *
   * A valid login attempt will return a new token. This token is
   * stored in local storage to prevent the user having to log in
   * again while the token is still active.
   */
  login(req: LoginRequest): Observable<LoginResponse> {
    return this.apiService.apiPost(
      `/${encodeURIComponent(req.contextId.toString())}/login`,
      {esa_username: req.esaUsername, password: req.password},
      false,
    ).pipe(
      map((res) => {
        const response = res as {token: string, user: APIUserModel};
        const valid = response.user && response.token;
        const validUserType = (
          response.user.type === 'SUP'
          || response.user.effective_context_type === 'owner'
          || response.user.effective_context_type === 'manager'
          || response.user.effective_context_type === 'user'
        );
        let errorMessage: string = '';

        const apiUser: UserProfile | null = validUserType ? UserProfile.fromApiData(response.user) : null;

        // Store the token in localStorage
        if (valid && validUserType) {
          window.localStorage.setItem(environment.id + '_login_token', response.token);
        }

        if (!valid) {
          errorMessage = 'User and/or token missing from response';
        }
        if (!validUserType) {
          errorMessage = 'You are not authorised to access this site';
        }

        /*this.themeService.setTheme([
          { themeVariable: '--primary-color',         value: '#d37f1e' },
          { themeVariable: '--contrast-color',        value: '#fff'    },
          { themeVariable: '--background-color',      value: '#fff' },
          { themeVariable: '--nav-background-color',  value: '#d37f1e' }
        ]);*/

        return {
          contextId: req.contextId ? req.contextId : (response.user.api_context_id ? response.user.api_context_id : null),
          error:     errorMessage ? AlertModel.handleApiMessage(errorMessage, 'warning') : null,
          ready:     true,
          token:     validUserType && response.token ? response.token : null,
          user:      apiUser,
        };
      }),
      catchError((err: HttpErrorResponse): Observable<LoginResponse> =>
        of({
          error: AlertModel.handleApiError(err),
          ready: true,
          token: null,
          user:  null,
          contextId: null,
        })
      ),
    );
  }

  /**
   * Activate a user account
   */
  activateAccount(req: ActivateAccountRequest): Observable<ActivateAccountResponse> {
    return this.apiService.apiPost(`/activate-user/${req.userId}`, {token: req.token, email: req.email}).pipe(
      map(() => ({
        activated: true,
        error:     null,
        message:   AlertModel.handleApiMessage('Your account has been successfully activated', 'info', '/login', 'Return to login'),
      })),
      catchError((err: HttpErrorResponse) =>
        of({
          activated: false,
          error:     AlertModel.handleApiError(err),
          message:   null,
        })
      ),
    );
  }

  /**
   * Remove the login token from local storage.
   */
  logout() {
    window.localStorage.removeItem(environment.id + '_login_token');
    window.localStorage.removeItem(`${environment.id}_context_id`);
  }

  /**
   * Initiate a password reset for a given e-mail address.
   *
   * This server will send an e-mail to the address (if registered)
   * with a link to reset their password
   */
  requestResetPassword(req: RequestResetPasswordRequest): Observable<RequestResetPasswordResponse> {
    return this.apiService.apiPost('/send-password-reset', {esa_username: req.esaUsername, type: req.resetType}).pipe(
      map(() => ({
        error:   null,
        message: AlertModel.handleApiMessage('An email has been sent to your account, please follow the instructions in this email to reset your password.', 'info'),
      })),
      catchError((err: HttpErrorResponse) =>
        of({
          error:   AlertModel.handleApiError(err),
          message: null,
        })
      ),
    );
  }

  /**
   * Respond to a password reset with a token and new password.
   */
  resetPassword(req: ResetPasswordRequest): Observable<ResetPasswordResponse> {
    return this.apiService.apiPost('/password-reset', ResetPasswordRequest.toApiData(req)).pipe(
      map(() => ({
        error: null,
        message: AlertModel.handleApiMessage('Your password has been successfully reset', 'info', '/login', 'Return to login'),
      })),
      catchError((err: HttpErrorResponse) =>
        of({
          error: AlertModel.handleApiError(err),
          message: null,
        })
      ),
    );
  }

  /**
   * Update a user's profile
   */
  patchUserProfile(req: PatchUserProfileRequest): Observable<PatchUserProfileResponse> {
    return this.apiService.apiPatch(`/user/${req.userId}`, UserProfile.toApiPatchData(req.patchData)).pipe(
      map(res => ({
        error: null,
        profile: res ? UserProfile.fromApiData(res as APIUserModel) : null,
        resetUserData: req.resetUserData
      })),
      catchError((err: HttpErrorResponse) =>
        of({
          error: AlertModel.handleApiError(err),
          profile: null,
          resetUserData: req.resetUserData
        })
      ),
    );
  }

  fetchUserStats(req: FetchUserStatsRequest): Observable<FetchUserStatsResponse> {
    return this.apiService.apiGet(`/user/${req.userId}/stats${FilterModel.constructFilterString(req.filters)}`).pipe(
      map(res => {
        const result = res as {stats: UserStats};
        return {
          error: null,
          userStats: result.stats ? UserStats.fromApiData(result.stats) as UserStats : null
        };
      }),
      catchError((err: HttpErrorResponse) =>
        of({
          error: AlertModel.handleApiError(err),
          userStats: null
        })
      ),
    );
  }

  linkEsaAccount(req: LinkEsaAccountRequest): Observable<LinkEsaAccountResponse> {
    return this.apiService.apiPost<any>('/current-user/esa-user', LinkEsaAccountRequest.toApiData(req)).pipe(
      map(() => ({
        error: null,
        message: AlertModel.handleApiMessage('Account linked successfully', 'info'),
        esaUsername: req.username
      })),
      catchError((err: HttpErrorResponse) =>
        of({
          error: AlertModel.handleApiError(err),
          message: null,
          esaUsername: null
        })
      )
    );
  }
  fetchNotificationStats(): Observable<FetchNotificationStatsResponse> {
    return this.apiService.apiGet(`/current-user/stats`).pipe(
      map((res: unknown): FetchNotificationStatsResponse => {
        const result = res as APIUserStatsModel;
        return {
          error: null,
          notificationStats: NotificationStatCollection.fromApi(result)
        };
      }),
      catchError((err: HttpErrorResponse) =>
        of({
          error: AlertModel.handleApiError(err),
          notificationStats: null
        })
      ),
    );
  }
  fetchUserIndex(req: FetchUserIndexRequest): Observable<FetchUserIndexResponse> {
    return this.apiService.apiGet(`/user/${req.userId}/esa-index`).pipe(
      map((res: unknown): FetchUserIndexResponse => {
        const result = res as {data: UserIndexModel};
        return {
          error: null,
          indexData: result?.data ? UserIndexModel.fromApiData(result.data) : null
        };
      }),
      catchError((err: HttpErrorResponse) =>
        of({
          error: AlertModel.handleApiError(err),
          indexData: null
        })
      ),
    );
  }
  storeUserIndex(req: StoreUserIndexRequest): Observable<StoreUserIndexResponse> {
    return this.apiService.apiPatch(`/user/${req.userId}`, {files: [req.file]}).pipe(
      map((res: unknown): StoreUserIndexResponse => ({
        error: null,
        userId: req.userId,
        userName: req.userName})),
      catchError((err: HttpErrorResponse) => of({
        error: AlertModel.handleApiError(err),
        userId: req.userId,
        userName: req.userName
      })
      ),
    );
  }
  fetchUserIndexAttachment(req: FetchUserIndexAttachmentRequest): Observable<FetchUserIndexAttachmentResponse> {
    return this.apiService.apiGet<any>(`/user/${req.userId}/attachment`).pipe(
      map((res: unknown): FetchUserIndexAttachmentResponse => {
        const response = res as Array<UserAttachmentAPIModel>;
        const indexPhoto = response.find(r => r.name === 'esa-index');
        return {
          error: indexPhoto ? null : AlertModel.handleApiMessage('No index photo available', 'warning'),
          attachmentUrl: indexPhoto ? encodeURIComponent(`${environment.api.baseUrl}/${environment.contextId}/static/public/${indexPhoto.file_id}/esa-index`) : null,
          pageUrl: indexPhoto ? encodeURIComponent(`${environment.api.baseUrl.replace('/v1', '')}/esa-index.php?filename=esa-index&name=${encodeURI(req.userName)}&fileref=${indexPhoto.file_id}`) : null
        };
      }),
      catchError((err: HttpErrorResponse): Observable<FetchUserIndexAttachmentResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          attachmentUrl: null,
          pageUrl: null
        });
      }),
    );
  }
}
