import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { EditSettingModalData, UserPatchData } from 'src/app/models/profile.model';

import { State as LoginState } from '../../../state-management/reducers/login.reducer';
import { StoreState } from 'src/app/state-management/store';
import { Store } from '@ngrx/store';
import { LinkEsaAccountRequestAction, PatchUserProfileRequestAction } from 'src/app/state-management/actions/login.actions';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AlertModel } from 'src/app/models/alert.model';

@Component({
  selector: 'app-edit-setting-modal',
  templateUrl: './edit-setting-modal.component.html',
  styleUrls: ['./edit-setting-modal.component.sass']
})
export class EditSettingModalComponent implements OnDestroy {
  public fg!: UntypedFormGroup;
  public login$: Observable<LoginState>;

  private destroy  = new Subject();
  private destroy$ = this.destroy.asObservable();

  private updateSubmitted = false;
  public updateSuccess: AlertModel | null = null;

  // public linkSuccess: AlertModel;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: EditSettingModalData,
    private dialogRef: MatDialogRef<EditSettingModalComponent>,
    private store: Store<StoreState>
  ) {
    this.login$ = this.store.select('login');

    if (this.dialogData && this.dialogData.user) {
      this.fg = this.fb.group({
        value: [dialogData.valueInitial ? dialogData.valueInitial : '', dialogData.valueValidators ? dialogData.valueValidators : []],
        secondaryValue: [dialogData.secondryInitial ? dialogData.secondryInitial : '', dialogData.secondaryValidators ? dialogData.secondaryValidators : []],
      },
      {
        validators: dialogData.formValidators ? dialogData.formValidators : null
      }
      );
    }
    else {
      this.dialogRef.close();
    }

    this.login$.pipe(
      distinctUntilChanged((x: LoginState, y: LoginState) => x.user === y.user),
      takeUntil(this.destroy$)
    ).subscribe(state => {
      if (state.user && this.updateSubmitted && !state.pending && !state.error && state.message && !this.updateSuccess) {
        this.updateSuccess = {...state.message};
      }
    });
  }
  onSubmitForm(): void {
    if (this.fg.valid && this.dialogData.user) {
      this.updateSubmitted = true;

      if (this.dialogData.mode === 'EDIT_MODE_LINK') {
        this.store.dispatch(LinkEsaAccountRequestAction({
          email: this.dialogData.user.email,
          password: this.fg.get('secondaryValue')?.value,
          username: this.fg.get('value')?.value
        }));
      }
      else if (this.dialogData.mode === 'EDIT_MODE_PATCH' && !!this.dialogData.patchKey) {
        const patchData: UserPatchData = {};
        patchData[this.dialogData.patchKey] = this.fg.get('value')?.value;

        this.store.dispatch(PatchUserProfileRequestAction({
          userId: this.dialogData.user.id,
          patchData
        }));
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}
