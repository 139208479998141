import { APIContext } from './api-models/api-context.model';
// import { Attachment }     from './attachment.model';
// import { ContextService } from './context-service.model';

import { IAPIModel, IAPIModelFactory } from './generics.model';

export class ContextSubscriptionType {
  constructor(public code: string, public description: string) {}
}

export class Context implements IAPIModel<Context> {
  AllowedRegistrationRoles!:     Array<string>;
  activationLink!:               string;
  allowRegistration!:            boolean;
  allowedRegistrationTypes!:     Array<string>;
  defaultUserRoles!:             string;
  defaultUserType!:              string;
  id!:                           number;
  invitiationRegistrationLink!:  string;
  locale!:                       string;
  name!:                         string;
  passwordResetUri!:             string;

  static getAvailableLocales(): Array<string> {
    return ['en'];
  }

  static getAvailableSubscriptionTypes(): Array<ContextSubscriptionType> {
    return [
      new ContextSubscriptionType('USR', 'Subscriptions required per User (based on User types needing Subscription)'),
      new ContextSubscriptionType('OWN', 'One Subscription required by owner only'),
    ];
  }

  static getAvailableUserRoles(): Array<string> {
    return [];
  }

  static getAvailableUserTypes(): Array<string> {
    return ['owner', 'manager', 'user'];
  }

  fromAPI(data: APIContext): Context {
    this.AllowedRegistrationRoles     = data.allowed_registration_roles;
    this.activationLink               = data.activation_link;
    this.allowRegistration            = data.allow_registration;
    this.allowedRegistrationTypes     = data.allowed_registration_types;
    this.defaultUserRoles             = data.default_user_roles;
    this.defaultUserType              = data.default_user_type;
    this.id                           = data.id;
    this.invitiationRegistrationLink  = data.invitation_registration_link;
    this.locale                       = data.locale;
    this.name                         = data.name;
    this.passwordResetUri             = data.password_reset_uri;

    return this;
  }

  toAPI(): any {
    const res: any = {
      activation_link:                 this.activationLink,
      allow_registration:              this.allowRegistration,
      allowed_registration_roles:      this.AllowedRegistrationRoles,
      allowed_registration_types:      this.allowedRegistrationTypes,
      default_user_roles:              this.defaultUserRoles,
      default_user_type:               this.defaultUserType,
      invitation_registration_link:    this.invitiationRegistrationLink,
      locale:                          this.locale,
      name:                            this.name,
      password_reset_uri:              this.passwordResetUri
    };

    return res;
  }
}

export class ContextFactory implements IAPIModelFactory<Context> {
  create(): Context {
    return new Context();
  }
}
