import { Component, OnInit, Input } from '@angular/core';
import { AlertModel } from 'src/app/models/alert.model';

import { faExclamationTriangle, faInfoCircle, faCheckCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-card',
  templateUrl: './alert-card.component.html',
  styleUrls: ['./alert-card.component.sass']
})
export class AlertCardComponent implements OnInit {
  @Input() alert: AlertModel | null = null;
  @Input() centered = false;

  public icon: IconDefinition | null = null;

  constructor(
    private router: Router
  ) {

  }

  ngOnInit(): void {
    switch(this.alert?.type) {
      case 'error':
        this.icon = faExclamationTriangle;
      break;
      case 'warning':
        this.icon = faInfoCircle;
      break;
      case 'info':
        this.icon = faCheckCircle;
      break;
    }
  }

  navigateByUrl(url: string | null | undefined): void {
    if (url) {
      this.router.navigate([url]);
    }
  }
}
