import * as actions     from '../actions/login.actions';
import { ClearAllErrorsAndMessages } from '../actions/page.actions';

import { NotificationStatCollection, UserIndexModel, UserProfile, UserStats } from '../../models/profile.model';
import { AlertModel } from 'src/app/models/alert.model';
import { Action, createReducer, on } from '@ngrx/store';
import { APIHelper } from 'src/app/models/api-models/api-helper.model';

export interface State {
  error:           AlertModel | null;
  message:         AlertModel | null;
  pending:         boolean | null;
  pendingTasks:    {[id: string]: boolean};
  token:           string | null;
  user:            UserProfile | null;
  userStats:       UserStats | null;
  userStatsFilter: {[id: string]: string} | null;
  profilePatched:  boolean;
  previousNotificationStats: NotificationStatCollection | null;
  userIndexData:  UserIndexModel | null;
  indexAttachmentUrl: string | null;
  indexPageUrl: string | null;
}

const defaultState: State = {
  error:           null,
  message:         null,
  pending:         false,
  pendingTasks:    {},
  token:           null,
  user:            null,
  userStats:       null,
  userStatsFilter: null,
  profilePatched:  false,
  previousNotificationStats: null,
  userIndexData: null,
  indexAttachmentUrl: null,
  indexPageUrl: null
};

const loginReducer = createReducer(
  defaultState,
  on(actions.InitLoginState, state => ({
      ...state,
      ...defaultState
  })),
  on(actions.CheckLoginTokenRequestAction, (state, action) => ({
    ...state,
    pending: true,
    token: action.token,
    pendingTasks: {...state.pendingTasks, [actions.CheckLoginTokenRequestAction.type]: true },
  })),
  on(actions.CheckLoginTokenResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.CheckLoginTokenRequestAction.type, state.pendingTasks),
    user:         action.valid ? action.user : null,
    token:        action.valid ? state.token : null,
  })),
  on(actions.LoginRequestAction, state => ({
    ...state,
    pending:      true,
    pendingTasks: {...state.pendingTasks, [actions.LoginRequestAction.type]: true },
    error:        null,
    user:         null,
    message:      null,
    token:        null,
  })),
  on(actions.LoginResponseAction, (state, action) => ({
    ...state,
    pending:  false,
    pendingTasks: APIHelper.removePendingTask(actions.LoginRequestAction.type, state.pendingTasks),
    error:    action.error,
    user:     action.user,
    token:    action.token,
  })),
  on(actions.LogoutAction, (state, action) => ({
    ...state,
    ...defaultState
  })),
  on(actions.ActivateAccountRequestAction, state => ({
    ...state,
    pending:      true,
    pendingTasks: {...state.pendingTasks, [actions.ActivateAccountRequestAction.type]: true },
    error:        null,
    message:      AlertModel.handleApiMessage('Activating account, please wait...', 'info')
  })),
  on(actions.ActivateAccountResponseAction, (state, action) => ({
    ...state,
    pending:        false,
    pendingTasks:   APIHelper.removePendingTask(actions.ActivateAccountRequestAction.type, state.pendingTasks),
    error:          action.error,
    message:        action.message,
  })),
  on(actions.RequestResetPasswordRequestAction, state => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.RequestResetPasswordRequestAction.type]: true },
    error:   null,
    message: AlertModel.handleApiMessage('If the specified email address is registered, you will now receive a link to reset your password.', 'info')
  })),
  on(actions.RequestResetPasswordResponseAction, (state, action) => ({
    ...state,
    pending: false,
    pendingTasks:   APIHelper.removePendingTask(actions.RequestResetPasswordRequestAction.type, state.pendingTasks),
    error:   action.error,
    message: action.message
  })),
  on(actions.ResetPasswordRequestAction, state => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.ResetPasswordRequestAction.type]: true },
    error: null,
    message: AlertModel.handleApiMessage('Please wait while we reset your password', 'info')
  })),
  on(actions.ResetPasswordResponseAction, (state, action) => ({
    ...state,
    pending:  false,
    pendingTasks:   APIHelper.removePendingTask(actions.ResetPasswordRequestAction.type, state.pendingTasks),
    error:    action.error,
    message:  action.message
  })),
  on(actions.PatchUserProfileRequestAction, state => ({
    ...state,
    pending:      true,
    pendingTasks: {...state.pendingTasks, [actions.PatchUserProfileRequestAction.type]: true },
    message:      AlertModel.handleApiMessage('Updating profile, please wait...', 'info'),
  })),
  on(actions.PatchUserProfileResponseAction, (state, action) => ({
    ...state,
    pending:        false,
    pendingTasks:   APIHelper.removePendingTask(actions.PatchUserProfileRequestAction.type, state.pendingTasks),
    error:          action.error ? action.error : state.error,
    message:        action.error ? null : AlertModel.handleApiMessage('Profile updated successfully.', 'info'),
    user:           action.error ? state.user : action.profile,
    profilePatched: !action.error
  })),
  on(actions.RemovePatchedFlag, state => ({
    ...state,
    profilePatched: false
  })),
  on(actions.FetchUserStatsRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchUserStatsRequestAction.type]: true },
    userStatsFilter: action.filters ? action.filters : state.userStatsFilter
  })),
  on(actions.FetchUserStatsResponseAction, (state, action) => ({
    ...state,
    pending: false,
    pendingTasks:   APIHelper.removePendingTask(actions.FetchUserStatsRequestAction.type, state.pendingTasks),
    error:   action.error ? action.error : state.error,
    userStats: action.error ? state.userStats : action.userStats
  })),
  on(actions.LinkEsaAccountRequestAction, state => ({
    ...state,
    pending: true,
    message: AlertModel.handleApiMessage('Linking user account, please wait...', 'info'),
    pendingTasks: {...state.pendingTasks, [actions.LinkEsaAccountRequestAction.type]: true },
  })),
  on(actions.LinkEsaAccountResponseAction, (state, action) => ({
    ...state,
    pending: false,
    pendingTasks:   APIHelper.removePendingTask(actions.LinkEsaAccountRequestAction.type, state.pendingTasks),
    error:   action.error ? action.error : state.error,
    message: action.message,
    user: action.error || !state.user ? state.user : {
      ...state.user,
      esaUsername: action.esaUsername ? action.esaUsername : state.user?.esaUsername
    }
  })),
  on(actions.FetchNotificationStatsRequestAction, (state, action) => ({
    ...state,
    previousNotificationStats: state.user?.stats ? state.user?.stats : null
  })),
  on(actions.FetchNotificationStatsResponseAction, (state, action) => ({
    ...state,
    error:   action.error ? action.error : state.error,
    user: action.error || !state.user ? state.user : {
      ...state.user,
      stats: action.notificationStats
    }
  })),
  on(actions.FetchUserIndexRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchUserIndexRequestAction.type]: true },
  })),
  on(actions.FetchUserIndexResponseAction, (state, action) => ({
    ...state,
    pending: false,
    pendingTasks:   APIHelper.removePendingTask(actions.FetchUserIndexRequestAction.type, state.pendingTasks),
    error:   action.error  ? action.error : state.error,
    userIndexData: action.indexData
  })),
  on(actions.StoreUserIndexRequestAction, (state) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.StoreUserIndexRequestAction.type]: true },
  })),
  on(actions.StoreUserIndexResponseAction, (state, action) => ({
    ...state,
    pending: false,
    pendingTasks:   APIHelper.removePendingTask(actions.StoreUserIndexRequestAction.type, state.pendingTasks),
    error:   action.error  ? action.error : state.error,
  })),
  on(actions.FetchUserIndexAttachmentRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchUserIndexAttachmentRequestAction.type]: true },
  })),
  on(actions.FetchUserIndexAttachmentResponseAction, (state, action) => {
    console.log('action', action);
    return {
    ...state,
    pending: false,
    pendingTasks:   APIHelper.removePendingTask(actions.FetchUserIndexAttachmentRequestAction.type, state.pendingTasks),
    error:   action.error  ? action.error : state.error,
    indexAttachmentUrl: action.attachmentUrl,
    indexPageUrl: action.pageUrl
  };}),
  on(actions.ResetDataAction, (state, action) => ({
    ...state,
    userStats: null,
    userStatsFilter: null
  })),
  on(ClearAllErrorsAndMessages, state => ({
    ...state,
    message: null,
    error: null
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return loginReducer(state, action);
}