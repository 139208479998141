import { Action, createReducer, on } from '@ngrx/store';
import { AlertModel } from 'src/app/models/alert.model';
import { APIHelper } from 'src/app/models/api-models/api-helper.model';
import * as actions     from '../actions/leaderboard.actions';
import * as friendActions     from '../actions/friend.actions';
import { LogoutAction, ResetDataAction } from '../actions/login.actions';
import { LeaderboardModel } from 'src/app/models/leaderboard.model';
import { ClearAllErrorsAndMessages } from '../actions/page.actions';
import { FriendCommunity } from 'src/app/models/friend.model';

export interface State {
  leaderboardData: Array<LeaderboardModel> | null;
  countryCode: string | null;
  ownRank: LeaderboardModel | null;
  leaderboardFilter: {[id: string]: string} | null;
  leaderboardPreviousFilter: {[id: string]: string} | null;
  initialFilterSet: boolean;
  pending:      boolean | null;
  pendingTasks: {[id: string]: boolean};
  error: AlertModel | null;
  message: AlertModel | null;
  triggerFilterUpdate: boolean;
  communityLeaderboards: Array<FriendCommunity> | null;
  singleCommunityLeaderboard: FriendCommunity | null;
}

const defaultState: State = {
  leaderboardData: null,
  countryCode: null,
  ownRank: null,
  leaderboardFilter: null,
  leaderboardPreviousFilter: null,
  initialFilterSet: false,
  pending:      false,
  pendingTasks: {},
  error: null,
  message: null,
  triggerFilterUpdate: false,
  communityLeaderboards: null,
  singleCommunityLeaderboard: null
};

const leaderboardReducer = createReducer(
  defaultState,
  on(actions.FetchLeaderboardDataRequestAction, (state, action) => ({
    ...state,
    pending: true,
    leaderboardFilter: action.filters ? action.filters : state.leaderboardFilter,
    pendingTasks: {...state.pendingTasks, [actions.FetchLeaderboardDataRequestAction.type]: true },
    triggerFilterUpdate: false
  })),
  on(actions.FetchLeaderboardDataResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.FetchLeaderboardDataRequestAction.type, state.pendingTasks),
    error:        action.error,
    leaderboardPreviousFilter: state.leaderboardFilter,
    leaderboardData: action.error ? state.leaderboardData : action.leaderboardData,
    initialFilterSet: true,
    countryCode: action.error ? state.countryCode : action.countryCode,
    ownRank: action.error ? state.ownRank : action.ownRank
  })),
  on(actions.SetLeaderboardFiltersAction, (state, action) => ({
    ...state,
    leaderboardFilter: action.filters ? action.filters : state.leaderboardFilter,
    triggerFilterUpdate: action.modalUpdate ? action.modalUpdate : false
  })),
  on(actions.PostCommunityLeaderboardRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.PostCommunityLeaderboardRequestAction.type]: true },
    message: null,
  })),
  on(actions.PostCommunityLeaderboardResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.PostCommunityLeaderboardRequestAction.type, state.pendingTasks),
    error:        action.error,
    message:      action.error ? state.message : AlertModel.handleApiMessage('Leaderboard created successfully', 'info'),
    communityLeaderboards: action.error || !action.community ? state.communityLeaderboards : state.communityLeaderboards?.length ?
      state.communityLeaderboards.concat(action.community) : [action.community]
  })),
  on(actions.FetchCommunityLeaderboardListRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchCommunityLeaderboardListRequestAction.type]: true },
  })),
  on(actions.FetchCommunityLeaderboardListResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.FetchCommunityLeaderboardListRequestAction.type, state.pendingTasks),
    error:        action.error,
    communityLeaderboards: action.error ? state.communityLeaderboards : action.communityArray
  })),
  on(actions.FetchCommunityLeaderboardRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchCommunityLeaderboardRequestAction.type]: true },
  })),
  on(actions.FetchCommunityLeaderboardResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.FetchCommunityLeaderboardRequestAction.type, state.pendingTasks),
    error:        action.error,
    singleCommunityLeaderboard: action.error ? state.singleCommunityLeaderboard : action.community
  })),
  on(actions.PatchCommunityLeaderboardRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.PatchCommunityLeaderboardRequestAction.type]: true },
    message: null,
  })),
  on(actions.PatchCommunityLeaderboardResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.PatchCommunityLeaderboardRequestAction.type, state.pendingTasks),
    error:        action.error,
    message:      action.error ? state.message : AlertModel.handleApiMessage('Leaderboard created successfully', 'info'),
    communityLeaderboards: action.error || !action.community || !state.communityLeaderboards ? state.communityLeaderboards :
      state.communityLeaderboards.map(l => l.id === action.communityId && action.community ? {
        ...l,
        name: action.community.name
      } : l),
    singleCommunityLeaderboard: action.error || !action.community || action.communityId !== state.singleCommunityLeaderboard?.id ?
      state.singleCommunityLeaderboard : {
        ...state.singleCommunityLeaderboard,
        name: action.community.name
      }
  })),
  on(friendActions.RemoveUserFromCommunityRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [friendActions.RemoveUserFromCommunityRequestAction.type + '_' + action.userId]: true },
  })),
  on(friendActions.RemoveUserFromCommunityResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(friendActions.RemoveUserFromCommunityRequestAction.type + '_' + action.userId, state.pendingTasks),
    error:        action.error,
    singleCommunityLeaderboard: action.error ? state.singleCommunityLeaderboard : action.userId !== state.singleCommunityLeaderboard?.ownerUserId && state.singleCommunityLeaderboard ? {
      ...state.singleCommunityLeaderboard,
      users: state.singleCommunityLeaderboard.users?.filter(u => u.id !== action.userId)
    }: null,
    communityLeaderboards: action.error || !state.communityLeaderboards || action.sessionUserId !== action.userId ? state.communityLeaderboards : state.communityLeaderboards?.filter(l => l.id !== action.communityId)
  })),
  on(actions.ClearSelectedCommunityAction, (state, action) => ({
    ...state,
    singleCommunityLeaderboard: null,
  })),
  on(ResetDataAction, (state, action) => ({
    ...state,
    ...defaultState
  })),
  on(LogoutAction, (state, action) => ({
    ...state,
    ...defaultState
  })),
  on(ClearAllErrorsAndMessages, state => ({
    ...state,
    message: null,
    error: null
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return leaderboardReducer(state, action);
}