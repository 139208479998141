import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ApiService } from './api.service';

import { AlertModel } from '../models/alert.model';
import { FilterModel } from '../models/filter.model';
import { FetchCommunityLeaderboardListResponse, FetchCommunityLeaderboardRequest, FetchCommunityLeaderboardResponse, FetchLeaderboardDataRequest, FetchLeaderboardDataResponse, LeaderboardFilters, LeaderboardModel } from '../models/leaderboard.model';
import { CountryCodes } from '../models/country-code.model';
import { APIHelper } from '../models/api-models/api-helper.model';
import { APIFriendCommunityModel } from '../models/api-models/api-friend.model';
import { APIUserModel } from '../models/api-models/api-user.model';
import { FriendCommunity } from '../models/friend.model';
import { APILeaderboardModel } from '../models/api-models/api-leaderboard.model';
import { GameTypeModel } from '../models/session.model';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class LeaderboardService {
  constructor(
    private apiService: ApiService,
  ) {
  }

  removeFilterFields(filters: LeaderboardFilters): {[id: string]: any} {
    const modifiedFilter = {...filters};
    if (filters && filters.global && filters.nation) {
      delete modifiedFilter['nation'];
    }
    if (filters && filters.leaderboardId) {
      delete modifiedFilter['leaderboardId'];
    }

    return modifiedFilter;
  }

  fetchLeaderboardData(req: FetchLeaderboardDataRequest, gameTypes: Array<GameTypeModel> | null, filters:{[id: string]: string} | null): Observable<FetchLeaderboardDataResponse> {
    return this.apiService.apiGet<any>(`${!req?.filters?.leaderboardId ? '/leaderboard' : '/current-user/leaderboard/' + req.filters.leaderboardId + '/data'}${FilterModel.constructFilterString({
      ...this.removeFilterFields(req.filters as LeaderboardFilters),
      count: '10'
    })}`).pipe(
      map((res): FetchLeaderboardDataResponse => {
        const response: { filters: any, table: Array<APILeaderboardModel>, country: string, country_code: string, rank: string, score: string } = res;
        const rCC = response.country_code ? CountryCodes.getCountryISO2(response.country_code) : response.country_code;
        const filterGameType = filters && filters['code'] && gameTypes && gameTypes.length ? gameTypes.find(t => t.code === filters['code']) : null;
        const ownScore = response.score && filterGameType && filterGameType.scoreBy === 'min time' ? `${moment.duration(+response.score).asSeconds()}s` : response.score;

        return {
          error: null,
          leaderboardData: response.table.map((r: APILeaderboardModel): LeaderboardModel => {
            let sessionScore = r.score; // r.game === 'SPR1' && r.score !== null ? (+r.score / 1000).toFixed(2) : r.score;
            const data = APIHelper.APItoJSON(r) as LeaderboardModel;
            const dataCC = data.countryCode ? CountryCodes.getCountryISO2(data.countryCode) : data.countryCode;
            const gameType = filterGameType ? filterGameType : (gameTypes && gameTypes.length ? gameTypes.find(t => t.code === r.game) : null);

            if (gameType && gameType.scoreBy === 'min time' && r.score) {
              const s = moment.duration(+r.score).asSeconds();
              sessionScore = `${s && s < 0.1 ? 0.1 : s}s`;
            }

            return {
              ...data,
              score: sessionScore ? sessionScore : 'N/A',
              countryCode: dataCC ? dataCC.toLowerCase() : dataCC
            };
          }),
          countryCode: rCC ? rCC.toLowerCase(): rCC,
          ownRank: { username: req.username, fullname: req.fullName, machineAlias: '', misses: '', score: ownScore, position: +response.rank, game: '', countryCode: '' }
        };
      }),
      catchError((err: HttpErrorResponse): Observable<FetchLeaderboardDataResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          leaderboardData: null,
          countryCode: null,
          ownRank: null
        });
      }),
    );
  }

  fetchCommunityLeaderboardList(): Observable<FetchCommunityLeaderboardListResponse> {
    return this.apiService.apiGet<any>('/current-user/leaderboard').pipe(
      map((res: Array<unknown>): FetchCommunityLeaderboardListResponse => ({
        error: null,
        communityArray: res.map(r => APIHelper.APItoJSON(r as APIFriendCommunityModel))
      })),
      catchError((err: HttpErrorResponse): Observable<FetchCommunityLeaderboardListResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          communityArray: null,
        });
      }),
    );
  }

  fetchCommunityLeaderboard(req: FetchCommunityLeaderboardRequest): Observable<FetchCommunityLeaderboardResponse> {
    return this.apiService.apiGet<any>(`/current-user/leaderboard/${req.communityId}`).pipe(
      map((res: APIFriendCommunityModel): FetchCommunityLeaderboardResponse => {
        const community: FriendCommunity = APIHelper.APItoJSON(res);
        community.users = community.users?.map(u => APIHelper.APItoJSON(u));
        return {
          error: null,
          community
        };
      }),
      catchError((err: HttpErrorResponse): Observable<FetchCommunityLeaderboardResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          community: null,
        });
      }),
    );
  }
}
