import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { AlertModel } from 'src/app/models/alert.model';

import { State as LoginState } from '../../../state-management/reducers/login.reducer';
import { StoreState } from 'src/app/state-management/store';
import { Store } from '@ngrx/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountryCodes, CountryItem } from 'src/app/models/country-code.model';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PatchUserProfileRequestAction } from 'src/app/state-management/actions/login.actions';
import { UserPatchData, UserProfile } from 'src/app/models/profile.model';

@Component({
  selector: 'app-edit-country-modal',
  templateUrl: './edit-country-modal.component.html',
  styleUrls: ['./edit-country-modal.component.sass']
})
export class EditCountryModalComponent implements OnDestroy {
  public fg!: UntypedFormGroup;
  public login$: Observable<LoginState>;

  private destroy  = new Subject();
  private destroy$ = this.destroy.asObservable();

  private updateSubmitted = false;
  public updateSuccess: AlertModel | null = null;

  private readonly countryList: Array<CountryItem> = CountryCodes.getCountryNameAndISO3Array();
  // Autocomplete lists for country
  public filteredCountries: Array<CountryItem> = this.countryList;

  public countrySwitchAlert = AlertModel.handleApiMessage(
    'Switching to this country will unlink your existing ESA account',
    'warning'
  );

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: UserProfile,
    private dialogRef: MatDialogRef<EditCountryModalComponent>,
    private store: Store<StoreState>
  ) {
    this.login$ = this.store.select('login');
    const countryName = this.dialogData.countryCode ? CountryCodes.getNameFromISO2Code(this.dialogData.countryCode): '';
    const countryCode = this.dialogData.countryCode ? CountryCodes.getCountryISO3(this.dialogData.countryCode) : '';

    this.fg = this.fb.group({
      country: [countryName, Validators.compose([Validators.required])],
      countryCode: [countryCode, Validators.compose([Validators.required])]
    });

    this.fg.get('country')?.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(v => {
      this.filteredCountries = this.filterCountries(v);

      if (this.fg.get('countryCode')?.value) {
        this.fg.get('countryCode')?.setValue('');
      }
    });

    this.login$.pipe(
      distinctUntilChanged((x: LoginState, y: LoginState) => x.user === y.user),
      takeUntil(this.destroy$)
    ).subscribe(state => {
      if (state.user && this.updateSubmitted && !state.pending && !state.error && state.message && !this.updateSuccess) {
        this.updateSuccess = {...state.message};
      }
    });
  }

  filterCountries(valueString: string): Array<CountryItem> {
    return this.countryList.filter(c => c.name.toLowerCase().includes(valueString.toLowerCase()));
  }

  setCountryCode(e: MatAutocompleteSelectedEvent): void {
    const selectedCountry = e.option ? this.countryList.find(c => c.name === e.option.value) : null;

    if (selectedCountry) {
      this.fg.get('country')?.setErrors(null);
      this.fg.get('countryCode')?.setValue(selectedCountry.code);
      this.fg.get('countryCode')?.updateValueAndValidity();
    }
  }

  determineFieldValid(field: string): boolean {
    const valid = this.fg.get(field)?.valid ? this.fg.get(field)?.valid : null;
    return valid ? true : false;
  }

  checkForAccountChange(countryCode: string): boolean {
    return (this.dialogData.countryCode === 'EG' && countryCode !== 'EGY') || (this.dialogData.countryCode !== 'EG' && countryCode === 'EGY');
  }

  blurInput(): void {
    setTimeout(() => {
      if (!this.fg.get('countryCode')?.value) {
        this.fg.get('country')?.setValue('', { emitEvent: false });
        this.filteredCountries = this.filterCountries('');
      }
    }, 100);
  }

  onSubmitForm(): void {
    if (this.fg.valid && this.dialogData) {
      this.updateSubmitted = true;
      const code = this.fg.get('countryCode')?.value ? this.fg.get('countryCode')?.value : null;
      const ISO2 = code ? CountryCodes.getCountryISO2(code) : null;
      const patchData: UserPatchData = {
        metadata: [{
          key: 'country_code',
          value: ISO2 ? ISO2.toUpperCase() : null,
          public: true
        }]
      };

      this.store.dispatch(PatchUserProfileRequestAction({
        userId: this.dialogData.id,
        patchData,
        resetUserData: this.checkForAccountChange(code)
      }));
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}
