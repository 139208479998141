import { Action, createReducer, on } from '@ngrx/store';
import { AlertModel } from 'src/app/models/alert.model';
import { APIHelper } from 'src/app/models/api-models/api-helper.model';
import * as actions     from '../actions/friend.actions';
import { LogoutAction, ResetDataAction } from '../actions/login.actions';
import { ClearAllErrorsAndMessages } from '../actions/page.actions';
import { FriendCommunity, FriendInvitation, FriendModel } from 'src/app/models/friend.model';

export interface State {
  ownedCommunity:           FriendCommunity | null;
  friends:                  Array<FriendModel> | null;
  sentInvitations:          Array<FriendInvitation> | null;
  receivedInvitations:      Array<FriendInvitation> | null;
  pending:                  boolean | null;
  pendingTasks:             {[id: string]: boolean};
  error:                    AlertModel | null;
  message:                  AlertModel | null;
  triggerFriendQuery:       boolean,
  triggerInvitationQuery:   boolean,
}

const defaultState: State = {
  ownedCommunity:           null,
  friends:                  null,
  sentInvitations:          null,
  receivedInvitations:      null,
  pending:                  false,
  pendingTasks:             {},
  error:                    null,
  message:                  null,
  triggerFriendQuery:       true,
  triggerInvitationQuery:   true,
};

const friendReducer = createReducer(
  defaultState,
  on(actions.FetchCommunityListRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchCommunityListRequestAction.type]: true },
  })),
  on(actions.FetchCommunityListResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.FetchCommunityListRequestAction.type, state.pendingTasks),
    error:        action.error,
    ownedCommunity:  state.error ? state.ownedCommunity : action.ownedCommunity
  })),
  on(actions.PostCommunityRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.PostCommunityRequestAction.type]: true },
  })),
  on(actions.PostCommunityResponseAction, (state, action) => ({
    ...state,
    pending:        false,
    pendingTasks:   APIHelper.removePendingTask(actions.PostCommunityRequestAction.type, state.pendingTasks),
    error:          action.error,
    ownedCommunity: state.error || !action.community ? state.ownedCommunity : action.community
  })),
  on(actions.FetchFriendListRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchFriendListRequestAction.type]: true },
    triggerFriendQuery: false
  })),
  on(actions.FetchFriendListResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.FetchFriendListRequestAction.type, state.pendingTasks),
    error:        action.error,
    friends:  state.error || !action.friends ? state.friends : action.friends
  })),
  on(actions.InviteUserToCommunityRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.InviteUserToCommunityRequestAction.type]: true },
  })),
  on(actions.InviteUserToCommunityResponseAction, (state, action) => ({
    ...state,
    pending:                false,
    pendingTasks:           APIHelper.removePendingTask(actions.InviteUserToCommunityRequestAction.type, state.pendingTasks),
    error:                  action.error,
    message:                action.message,
    triggerInvitationQuery: action.error ? state.triggerInvitationQuery : true
  })),
  on(actions.InviteMultipleUsersToCommunityRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.InviteMultipleUsersToCommunityRequestAction.type]: true },
  })),
  on(actions.InviteMultipleUsersToCommunityResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.InviteMultipleUsersToCommunityRequestAction.type, state.pendingTasks),
    error:        action.error,
    message:      action.message,
    triggerInvitationQuery: action.error ? state.triggerInvitationQuery : true
  })),
  on(actions.FetchUserInvitationListRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchUserInvitationListRequestAction.type]: true },
    triggerInvitationQuery: false
  })),
  on(actions.FetchUserInvitationListResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.FetchUserInvitationListRequestAction.type, state.pendingTasks),
    error:        action.error,
    sentInvitations: action.error ? state.sentInvitations : action.sentInvitations,
    receivedInvitations: action.error ? state.receivedInvitations : action.receivedInvitations,
  })),
  on(actions.RespondUserInvitationRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.RespondUserInvitationRequestAction.type + '_' + action.invitationId]: true },
  })),
  on(actions.RespondUserInvitationResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.RespondUserInvitationRequestAction.type + '_' + action.invitationId, state.pendingTasks),
    error:        action.error,
    receivedInvitations: action.error || !state.receivedInvitations ? state.receivedInvitations :
      state.receivedInvitations?.filter(i => i.id !== action.invitationId),
  })),
  on(actions.DeleteInvitationRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.DeleteInvitationRequestAction.type + '_' + action.invitationId]: true },
  })),
  on(actions.DeleteInvitationResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.DeleteInvitationRequestAction.type + '_' + action.invitationId, state.pendingTasks),
    error:        action.error,
    sentInvitations: action.error || !state.sentInvitations ? state.sentInvitations :
      state.sentInvitations?.filter(i => i.id !== action.invitationId),
  })),
  on(actions.RemoveFriendRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.RemoveFriendRequestAction.type + '_' + action.userId]: true },
  })),
  on(actions.RemoveFriendResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.RemoveFriendRequestAction.type + '_' + action.userId, state.pendingTasks),
    error:        action.error,
    friends: action.error || !state.friends ? state.friends :
      state.friends?.filter(i => i.id !== action.userId),
  })),
  /* on(actions.RemoveUserFromCommunityRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.RemoveUserFromCommunityRequestAction.type + '_' + action.userId]: true },
  })),
  on(actions.RemoveUserFromCommunityResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.RemoveUserFromCommunityRequestAction.type + '_' + action.userId, state.pendingTasks),
    error:        action.error,
    friends: action.error || !state.friends ? state.friends :
      state.friends?.filter(i => i.id !== action.userId),
  })),*/
  on(actions.RequestFetchFriendListAction, state => ({
    ...state,
    triggerFriendQuery: true
  })),
  on(actions.RequestFetchInvitationListAction, state => ({
    ...state,
    triggerInvitationQuery: true
  })),
  on(ResetDataAction, (state, action) => ({
    ...state,
    ...defaultState
  })),
  on(LogoutAction, (state, action) => ({
    ...state,
    ...defaultState
  })),
  on(ClearAllErrorsAndMessages, state => ({
    ...state,
    message: null,
    error: null
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return friendReducer(state, action);
}