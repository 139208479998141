<mat-card [class]="alert.type" [class.centered]="centered" *ngIf="alert">
    <mat-card-header [class.text-align-left]="!centered">
        <fa-icon mat-card-avatar [icon]="icon" class="icon" *ngIf="icon"></fa-icon>
        <mat-card-title>{{alert.message}}</mat-card-title>
        <mat-card-subtitle *ngIf="alert?.listItems?.length">
            <ul class="color-white">
                <li *ngFor="let item of alert.listItems">{{item}}</li>
            </ul>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="alert?.link">
            <button class="button primary small hover-white" (click)="navigateByUrl(alert.link)">{{alert.linkText}}</button>
        </mat-card-subtitle>
    </mat-card-header>
</mat-card>
