import { createAction, props } from '@ngrx/store';
import {
    DeleteNotificationRequest,
    DeleteNotificationResponse,
    FetchNotificationListRequest,
    FetchNotificationListResponse,
    MarkNotificationReadRequest,
    MarkNotificationReadResponse,
} from 'src/app/models/notification.model';

export const FetchNotificationListRequestAction = createAction('FETCH_NOTIFICATION_LIST_REQUEST', props<FetchNotificationListRequest>());
export const FetchNotificationListResponseAction = createAction('FETCH_NOTIFICATION_LIST_RESPONSE', props<FetchNotificationListResponse>());

export const MarkNotificationReadRequestAction = createAction('MARK_NOTIFICATION_READ_REQUEST', props<MarkNotificationReadRequest>());
export const MarkNotificationReadResponseAction = createAction('MARK_NOTIFICATION_READ_RESPONSE', props<MarkNotificationReadResponse>());

export const DeleteNotificationRequestAction = createAction('DELETE_NOTIFICATION_REQUEST', props<DeleteNotificationRequest>());
export const DeleteNotificationResponseAction = createAction('DELETE_NOTIFICATION_RESPONSE', props<DeleteNotificationResponse>());
