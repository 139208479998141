import { Action, createReducer, on } from '@ngrx/store';
import { AlertModel } from 'src/app/models/alert.model';
import { APIHelper } from 'src/app/models/api-models/api-helper.model';
import { CoachedUserModel, UserStats } from 'src/app/models/profile.model';
import { GameTypeModel, MachineGroupModel, MachineModel, SingleTrainingSessionModel, TrainingSessionModel } from 'src/app/models/session.model';
import * as actions     from '../actions/session.actions';
import { LogoutAction, ResetDataAction } from '../actions/login.actions';
import { ClearAllErrorsAndMessages } from '../actions/page.actions';

export interface State {
  currentUsername:        string | null;
  gameTypeList:           Array<GameTypeModel> | null;
  playedGameTypeList:     Array<GameTypeModel> | null;
  machineList:            Array<MachineModel> | null;
  machineGroupList:       Array<MachineGroupModel> | null;
  playedMachineGroupList: Array<MachineGroupModel> | null;
  sessionList:            Array<TrainingSessionModel> | null;
  sessionStats:           UserStats | null;
  singleSession:          SingleTrainingSessionModel | null;
  coachedUserList:        Array<CoachedUserModel> | null;
  analysisFilter:         {[id: string]: string} | null;
  analysisPreviousFilter: {[id: string]: string} | null;
  initialFilterSet:       boolean;
  pending:                boolean | null;
  pendingTasks:           {[id: string]: boolean};
  triggerFilterUpdate:    boolean;
  error:                  AlertModel | null;
}

const defaultState: State = {
  currentUsername: null,
  gameTypeList: null,
  playedGameTypeList: null,
  machineList: null,
  machineGroupList: null,
  playedMachineGroupList: null,
  sessionList:  null,
  sessionStats: null,
  singleSession: null,
  coachedUserList: null,
  analysisFilter: null,
  analysisPreviousFilter: null,
  initialFilterSet: false,
  pending:      false,
  pendingTasks: {},
  triggerFilterUpdate: false,
  error: null
};

const sessionReducer = createReducer(
  defaultState,
  on(actions.FetchGameTypeListRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchGameTypeListRequestAction.type]: true },
  })),
  on(actions.FetchGameTypeListResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.FetchGameTypeListRequestAction.type, state.pendingTasks),
    error:        action.error,
    gameTypeList: action.error ? state.gameTypeList : action.gameTypeList,
    playedGameTypeList: action.error ? state.playedGameTypeList : action.playedGameTypeList
  })),
  on(actions.FetchPlayedGameTypeListRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchPlayedGameTypeListRequestAction.type]: true },
    currentUsername: action.username
  })),
  on(actions.FetchPlayedGameTypeListResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.FetchPlayedGameTypeListRequestAction.type, state.pendingTasks),
    error:        action.error,
    playedGameTypeList: action.error ? state.playedGameTypeList : action.playedGameTypeList,
  })),
  on(actions.FetchMachineListRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchMachineListRequestAction.type]: true },
  })),
  on(actions.FetchMachineListResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.FetchMachineListRequestAction.type, state.pendingTasks),
    error:        action.error,
    machineList: action.error ? state.machineList : action.machineList
  })),
  on(actions.FetchMachineGroupListRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchMachineGroupListRequestAction.type]: true },
  })),
  on(actions.FetchMachineGroupListResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.FetchMachineGroupListRequestAction.type, state.pendingTasks),
    error:        action.error,
    machineGroupList: action.error ? state.machineGroupList : action.machineGroupList,
    playedMachineGroupList: action.error ? state.playedMachineGroupList : action.playedMachineGroupList
  })),
  on(actions.FetchPlayedMachineGroupListRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchPlayedMachineGroupListRequestAction.type]: true },
  })),
  on(actions.FetchPlayedMachineGroupListResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.FetchPlayedMachineGroupListRequestAction.type, state.pendingTasks),
    error:        action.error,
    playedMachineGroupList: action.error ? state.playedMachineGroupList : action.playedMachineGroupList
  })),
  on(actions.FetchSessionListRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchSessionListRequestAction.type]: true },
    analysisFilter: action.filters ? action.filters : state.analysisFilter,
    triggerFilterUpdate: false
  })),
  on(actions.FetchSessionListResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.FetchSessionListRequestAction.type, state.pendingTasks),
    error:        action.error,
    sessionList: action.error ? state.sessionList : action.sessionList,
    analysisPreviousFilter: state.analysisFilter,
    sessionStats: action.error ? state.sessionStats : action.sessionStats,
    initialFilterSet: true
  })),
  on(actions.FetchCoachedUserListRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchCoachedUserListRequestAction.type]: true },
  })),
  on(actions.FetchCoachedUserListResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.FetchCoachedUserListRequestAction.type, state.pendingTasks),
    error:        action.error,
    coachedUserList: action.error ? state.coachedUserList : action.coachedUserList
  })),
  on(actions.FetchSessionRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchSessionRequestAction.type]: true },
  })),
  on(actions.FetchSessionResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.FetchSessionRequestAction.type, state.pendingTasks),
    error:        action.error,
    singleSession: action.error ? state.singleSession : action.session
  })),
  on(actions.SetSessionFiltersAction, (state, action) => ({
    ...state,
    analysisFilter: action.filters ? action.filters : state.analysisFilter,
    triggerFilterUpdate: action.modalUpdate ? action.modalUpdate : false
  })),
  on(ResetDataAction, (state, action) => ({
    ...state,
    ...defaultState
  })),
  on(LogoutAction, (state, action) => ({
    ...state,
    ...defaultState
  })),
  on(ClearAllErrorsAndMessages, state => ({
    ...state,
    message: null,
    error: null
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return sessionReducer(state, action);
}