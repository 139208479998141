import { createAction, props } from '@ngrx/store';
import {
    DeleteInvitationRequest,
    DeleteInvitationResponse,
    FetchCommunityListRequest,
    FetchCommunityListResponse,
    FetchFriendListRequest,
    FetchFriendListResponse,
    FetchUserInvitationListResponse,
    InviteUserToCommunityRequest,
    InviteUserToCommunityResponse,
    PostCommunityRequest,
    PostCommunityResponse,
    RemoveFriendRequest,
    RemoveFriendResponse,
    RemoveUserFromCommunityRequest,
    RemoveUserFromCommunityResponse,
    RespondUserInvitationRequest,
    RespondUserInvitationResponse
} from 'src/app/models/friend.model';

export const FetchCommunityListRequestAction = createAction('FETCH_COMMUNITY_LIST_REQUEST', props<FetchCommunityListRequest>());
export const FetchCommunityListResponseAction = createAction('FETCH_COMMUNITY_LIST_RESPONSE', props<FetchCommunityListResponse>());

export const PostCommunityRequestAction = createAction('POST_COMMUNITY_REQUEST', props<PostCommunityRequest>());
export const PostCommunityResponseAction = createAction('POST_COMMUNITY_RESPONSE', props<PostCommunityResponse>());

export const FetchFriendListRequestAction = createAction('FETCH_FRIEND_LIST_REQUEST', props<FetchFriendListRequest>());
export const FetchFriendListResponseAction = createAction('FETCH_FRIEND_LIST_RESPONSE', props<FetchFriendListResponse>());

export const InviteUserToCommunityRequestAction = createAction('INVITE_USER_TO_COMMUNITY_REQUEST', props<InviteUserToCommunityRequest>());
export const InviteUserToCommunityResponseAction = createAction('INVITE_USER_TO_COMMUNITY_RESPONSE', props<InviteUserToCommunityResponse>());

export const InviteMultipleUsersToCommunityRequestAction = createAction('INVITE_MULTIPLE_USERS_TO_COMMUNITY_REQUEST', props<InviteUserToCommunityRequest>());
export const InviteMultipleUsersToCommunityResponseAction = createAction('INVITE_MULTIPLE_USERS_TO_COMMUNITY_RESPONSE', props<InviteUserToCommunityResponse>());

export const FetchUserInvitationListRequestAction = createAction('FETCH_USER_INVITATION_LIST_REQUEST');
export const FetchUserInvitationListResponseAction = createAction('FETCH_USER_INVITATION_LIST_RESPONSE', props<FetchUserInvitationListResponse>());

export const RespondUserInvitationRequestAction = createAction('RESPOND_USER_INVITATION_REQUEST', props<RespondUserInvitationRequest>());
export const RespondUserInvitationResponseAction = createAction('RESPOND_USER_INVITATION_RESPONSE', props<RespondUserInvitationResponse>());

export const DeleteInvitationRequestAction = createAction('DELETE_INVITATION_REQUEST', props<DeleteInvitationRequest>());
export const DeleteInvitationResponseAction = createAction('DELETE_INVITATION_RESPONSE', props<DeleteInvitationResponse>());

export const RemoveFriendRequestAction = createAction('REMOVE_FRIEND_REQUEST', props<RemoveFriendRequest>());
export const RemoveFriendResponseAction = createAction('REMOVE_FRIEND_RESPONSE', props<RemoveFriendResponse>());

export const RemoveUserFromCommunityRequestAction = createAction('REMOVE_USER_FROM_COMMUNITY_REQUEST', props<RemoveUserFromCommunityRequest>());
export const RemoveUserFromCommunityResponseAction = createAction('REMOVE_USER_FROM_COMMUNITY_RESPONSE', props<RemoveUserFromCommunityResponse>());

export const RequestFetchFriendListAction = createAction('REQUEST_FETCH_FRIEND_LIST');
export const RequestFetchInvitationListAction = createAction('REQUEST_FETCH_INVITATION_LIST');