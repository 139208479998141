import * as countryList from 'country-list';

export interface CountryItem {
  name: string;
  code: string;
}

export class CountryCodes {
  static getISOMappings(): {[id: string]: string} {
    return {
      AFG: 'AF',
      ALA: 'AX',
      ALB: 'AL',
      DZA: 'DZ',
      ASM: 'AS',
      AND: 'AD',
      AGO: 'AO',
      AIA: 'AI',
      ATA: 'AQ',
      ATG: 'AG',
      ARG: 'AR',
      ARM: 'AM',
      ABW: 'AW',
      AUS: 'AU',
      AUT: 'AT',
      AZE: 'AZ',
      BES: 'BQ',
      BHS: 'BS',
      BHR: 'BH',
      BGD: 'BD',
      BRB: 'BB',
      BLR: 'BY',
      BEL: 'BE',
      BLZ: 'BZ',
      BEN: 'BJ',
      BMU: 'BM',
      BTN: 'BT',
      BOL: 'BO',
      BIH: 'BA',
      BWA: 'BW',
      BVT: 'BV',
      BRA: 'BR',
      VGB: 'VG',
      IOT: 'IO',
      BRN: 'BN',
      BGR: 'BG',
      BFA: 'BF',
      BDI: 'BI',
      KHM: 'KH',
      CMR: 'CM',
      CAN: 'CA',
      CPV: 'CV',
      CYM: 'KY',
      CAF: 'CF',
      TCD: 'TD',
      CHL: 'CL',
      CHN: 'CN',
      HKG: 'HK',
      MAC: 'MO',
      CXR: 'CX',
      CCK: 'CC',
      COL: 'CO',
      COM: 'KM',
      COG: 'CG',
      COD: 'CD',
      COK: 'CK',
      CRI: 'CR',
      CIV: 'CI',
      HRV: 'HR',
      CUB: 'CU',
      CUW: 'CW',
      CYP: 'CY',
      CZE: 'CZ',
      DNK: 'DK',
      DJI: 'DJ',
      DMA: 'DM',
      DOM: 'DO',
      ECU: 'EC',
      EGY: 'EG',
      SLV: 'SV',
      GNQ: 'GQ',
      ERI: 'ER',
      EST: 'EE',
      ETH: 'ET',
      FLK: 'FK',
      FRO: 'FO',
      FJI: 'FJ',
      FIN: 'FI',
      FRA: 'FR',
      GUF: 'GF',
      PYF: 'PF',
      ATF: 'TF',
      GAB: 'GA',
      GMB: 'GM',
      GEO: 'GE',
      DEU: 'DE',
      GHA: 'GH',
      GIB: 'GI',
      GRC: 'GR',
      GRL: 'GL',
      GRD: 'GD',
      GLP: 'GP',
      GUM: 'GU',
      GTM: 'GT',
      GGY: 'GG',
      GIN: 'GN',
      GNB: 'GW',
      GUY: 'GY',
      HTI: 'HT',
      HMD: 'HM',
      VAT: 'VA',
      HND: 'HN',
      HUN: 'HU',
      ISL: 'IS',
      IND: 'IN',
      IDN: 'ID',
      IRN: 'IR',
      IRQ: 'IQ',
      IRL: 'IE',
      IMN: 'IM',
      ISR: 'IL',
      ITA: 'IT',
      JAM: 'JM',
      JPN: 'JP',
      JEY: 'JE',
      JOR: 'JO',
      KAZ: 'KZ',
      KEN: 'KE',
      KIR: 'KI',
      PRK: 'KP',
      KOR: 'KR',
      KWT: 'KW',
      KGZ: 'KG',
      LAO: 'LA',
      LVA: 'LV',
      LBN: 'LB',
      LSO: 'LS',
      LBR: 'LR',
      LBY: 'LY',
      LIE: 'LI',
      LTU: 'LT',
      LUX: 'LU',
      MKD: 'MK',
      MDG: 'MG',
      MWI: 'MW',
      MYS: 'MY',
      MDV: 'MV',
      MLI: 'ML',
      MLT: 'MT',
      MHL: 'MH',
      MTQ: 'MQ',
      MRT: 'MR',
      MUS: 'MU',
      MYT: 'YT',
      MEX: 'MX',
      FSM: 'FM',
      MDA: 'MD',
      MCO: 'MC',
      MNG: 'MN',
      MNE: 'ME',
      MSR: 'MS',
      MAR: 'MA',
      MOZ: 'MZ',
      MMR: 'MM',
      NAM: 'NA',
      NRU: 'NR',
      NPL: 'NP',
      NLD: 'NL',
      ANT: 'AN',
      NCL: 'NC',
      NZL: 'NZ',
      NIC: 'NI',
      NER: 'NE',
      NGA: 'NG',
      NIU: 'NU',
      NFK: 'NF',
      MNP: 'MP',
      NOR: 'NO',
      OMN: 'OM',
      PAK: 'PK',
      PLW: 'PW',
      PSE: 'PS',
      PAN: 'PA',
      PNG: 'PG',
      PRY: 'PY',
      PER: 'PE',
      PHL: 'PH',
      PCN: 'PN',
      POL: 'PL',
      PRT: 'PT',
      PRI: 'PR',
      QAT: 'QA',
      REU: 'RE',
      ROU: 'RO',
      RUS: 'RU',
      RWA: 'RW',
      BLM: 'BL',
      SHN: 'SH',
      KNA: 'KN',
      LCA: 'LC',
      MAF: 'MF',
      SPM: 'PM',
      VCT: 'VC',
      WSM: 'WS',
      SMR: 'SM',
      STP: 'ST',
      SAU: 'SA',
      SEN: 'SN',
      SRB: 'RS',
      SYC: 'SC',
      SLE: 'SL',
      SGP: 'SG',
      SVK: 'SK',
      SVN: 'SI',
      SLB: 'SB',
      SOM: 'SO',
      SXM: 'SX',
      ZAF: 'ZA',
      SGS: 'GS',
      SSD: 'SS',
      ESP: 'ES',
      LKA: 'LK',
      SDN: 'SD',
      SUR: 'SR',
      SJM: 'SJ',
      SWZ: 'SZ',
      SWE: 'SE',
      CHE: 'CH',
      SYR: 'SY',
      TWN: 'TW',
      TJK: 'TJ',
      TZA: 'TZ',
      THA: 'TH',
      TLS: 'TL',
      TGO: 'TG',
      TKL: 'TK',
      TON: 'TO',
      TTO: 'TT',
      TUN: 'TN',
      TUR: 'TR',
      TKM: 'TM',
      TCA: 'TC',
      TUV: 'TV',
      UGA: 'UG',
      UKR: 'UA',
      ARE: 'AE',
      GBR: 'GB',
      USA: 'US',
      UMI: 'UM',
      URY: 'UY',
      UZB: 'UZ',
      VUT: 'VU',
      VEN: 'VE',
      VNM: 'VN',
      VIR: 'VI',
      WLF: 'WF',
      ESH: 'EH',
      YEM: 'YE',
      ZMB: 'ZM',
      ZWE: 'ZW',
      XKX: 'XK'
    };
  }

  static getCountryISO2(countryCode: string): string {
    const mappings = CountryCodes.getISOMappings();
    return mappings[countryCode] ? mappings[countryCode].toLowerCase() : countryCode.toLowerCase();
  }
  static getCountryISO3(countryCode: string): string {
    const mappings = CountryCodes.getISOMappings();
    const result = Object.keys(mappings).find(key => mappings[key] === countryCode);
    return result ? result : '';
  }

  static getCountryNameAndISO3Array(): Array<CountryItem> {
    return countryList.getData().map((c: {name: string, code: string}) => { //eslint-disable-line
      return {
        name: CountryCodes.getCodeName(c.code, c.name),
        code: this.getCountryISO3(c.code)
      };
    }).sort((a, b) => {
      if (a.name > b.name) { return 1; }
      if (a.name < b.name) { return -1; }
      return 0;
    });
  }
  static getNameFromISO3Code(code: string): string {
    const ISO2Code = this.getCountryISO2(code);
    const name = ISO2Code ? countryList.getName(ISO2Code) : null; //eslint-disable-line

    return name ? CountryCodes.getCodeName(code, name) : '';
  }
  static getNameFromISO2Code(code: string): string {
    const name = code ? countryList.getName(code) : null; //eslint-disable-line

    return name ? CountryCodes.getCodeName(code, name) : '';
  }

  static getCodeName(code: string, name: string) {
    return code === 'GB' ? 'United Kingdom' : name;
  }
}

export class Nations {
  static getNationArray(): Array<string> {
    return [
      'Afghan',
      'Albanian',
      'Algerian',
      'American',
      'Andorran',
      'Angolan',
      'Anguillan',
      'Argentine',
      'Armenian',
      'Australian',
      'Austrian',
      'Azerbaijani',
      'Bahamian',
      'Bahraini',
      'Bangladeshi',
      'Barbadian',
      'Belarusian',
      'Belgian',
      'Belizean',
      'Beninese',
      'Bermudian',
      'Bhutanese',
      'Bolivian',
      'Botswanan',
      'Brazilian',
      'British',
      'British Virgin Islander',
      'Bruneian',
      'Bulgarian',
      'Burkinan',
      'Burmese',
      'Burundian',
      'Cambodian',
      'Cameroonian',
      'Canadian',
      'Cape Verdean',
      'Cayman Islander',
      'Central African',
      'Chadian',
      'Chilean',
      'Chinese',
      'Citizen of Antigua and Barbuda',
      'Citizen of Bosnia and Herzegovina',
      'Citizen of Guinea-Bissau',
      'Citizen of Kiribati',
      'Citizen of Seychelles',
      'Citizen of the Dominican Republic',
      'Citizen of Vanuatu',
      'Colombian',
      'Comoran',
      'Congolese (Congo)',
      'Congolese (DRC)',
      'Cook Islander',
      'Costa Rican',
      'Croatian',
      'Cuban',
      'Cymraes',
      'Cymro',
      'Cypriot',
      'Czech',
      'Danish',
      'Djiboutian',
      'Dominican',
      'Dutch',
      'East Timorese',
      'Ecuadorean',
      'Egyptian',
      'Emirati',
      'English',
      'Equatorial Guinean',
      'Eritrean',
      'Estonian',
      'Ethiopian',
      'Faroese',
      'Fijian',
      'Filipino',
      'Finnish',
      'French',
      'Gabonese',
      'Gambian',
      'Georgian',
      'German',
      'Ghanaian',
      'Gibraltarian',
      'Greek',
      'Greenlandic',
      'Grenadian',
      'Guamanian',
      'Guatemalan',
      'Guinean',
      'Guyanese',
      'Haitian',
      'Honduran',
      'Hong Konger',
      'Hungarian',
      'Icelandic',
      'Indian',
      'Indonesian',
      'Iranian',
      'Iraqi',
      'Irish',
      'Israeli',
      'Italian',
      'Ivorian',
      'Jamaican',
      'Japanese',
      'Jordanian',
      'Kazakh',
      'Kenyan',
      'Kittitian',
      'Kosovan',
      'Kuwaiti',
      'Kyrgyz',
      'Lao',
      'Latvian',
      'Lebanese',
      'Liberian',
      'Libyan',
      'Liechtenstein citizen',
      'Lithuanian',
      'Luxembourger',
      'Macanese',
      'Macedonian',
      'Malagasy',
      'Malawian',
      'Malaysian',
      'Maldivian',
      'Malian',
      'Maltese',
      'Marshallese',
      'Martiniquais',
      'Mauritanian',
      'Mauritian',
      'Mexican',
      'Micronesian',
      'Moldovan',
      'Monegasque',
      'Mongolian',
      'Montenegrin',
      'Montserratian',
      'Moroccan',
      'Mosotho',
      'Mozambican',
      'Namibian',
      'Nauruan',
      'Nepalese',
      'New Zealander',
      'Nicaraguan',
      'Nigerian',
      'Nigerien',
      'Niuean',
      'North Korean',
      'Northern Irish',
      'Norwegian',
      'Omani',
      'Pakistani',
      'Palauan',
      'Palestinian',
      'Panamanian',
      'Papua New Guinean',
      'Paraguayan',
      'Peruvian',
      'Pitcairn Islander',
      'Polish',
      'Portuguese',
      'Prydeinig',
      'Puerto Rican',
      'Qatari',
      'Romanian',
      'Russian',
      'Rwandan',
      'Salvadorean',
      'Sammarinese',
      'Samoan',
      'Sao Tomean',
      'Saudi Arabian',
      'Scottish',
      'Senegalese',
      'Serbian',
      'Sierra Leonean',
      'Singaporean',
      'Slovak',
      'Slovenian',
      'Solomon Islander',
      'Somali',
      'South African',
      'South Korean',
      'South Sudanese',
      'Spanish',
      'Sri Lankan',
      'St Helenian',
      'St Lucian',
      'Stateless',
      'Sudanese',
      'Surinamese',
      'Swazi',
      'Swedish',
      'Swiss',
      'Syrian',
      'Taiwanese',
      'Tajik',
      'Tanzanian',
      'Thai',
      'Togolese',
      'Tongan',
      'Trinidadian',
      'Tristanian',
      'Tunisian',
      'Turkish',
      'Turkmen',
      'Turks and Caicos Islander',
      'Tuvaluan',
      'Ugandan',
      'Ukrainian',
      'Uruguayan',
      'Uzbek',
      'Vatican citizen',
      'Venezuelan',
      'Vietnamese',
      'Vincentian',
      'Wallisian',
      'Welsh',
      'Yemeni',
      'Zambian',
      'Zimbabwean'
    ];
  }
}
