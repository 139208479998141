import { createAction, props } from '@ngrx/store';
import { PatchCommunityRequest, PatchCommunityResponse, PostCommunityRequest, PostCommunityResponse } from 'src/app/models/friend.model';
import { FetchCommunityLeaderboardListResponse, FetchCommunityLeaderboardRequest, FetchCommunityLeaderboardResponse, FetchLeaderboardDataRequest, FetchLeaderboardDataResponse } from 'src/app/models/leaderboard.model';

export const FetchLeaderboardDataRequestAction  = createAction('FETCH_LEADERBOARD_DATA_REQUEST', props<FetchLeaderboardDataRequest>());
export const FetchLeaderboardDataResponseAction  = createAction('FETCH_LEADERBOARD_DATA_RESPONSE', props<FetchLeaderboardDataResponse>());

export const SetLeaderboardFiltersAction  = createAction('SET_LEADERBOARD_FILTERS', props<FetchLeaderboardDataRequest>());

export const PostCommunityLeaderboardRequestAction  = createAction('POST_COMMUNITY_LEADERBOARD_REQUEST', props<PostCommunityRequest>());
export const PostCommunityLeaderboardResponseAction  = createAction('POST_COMMUNITY_LEADERBOARD_RESPONSE', props<PostCommunityResponse>());

export const FetchCommunityLeaderboardListRequestAction  = createAction('FETCH_COMMUNITY_LEADERBOARD_LIST_REQUEST');
export const FetchCommunityLeaderboardListResponseAction  = createAction('FETCH_COMMUNITY_LEADERBOARD_LIST_RESPONSE', props<FetchCommunityLeaderboardListResponse>());

export const FetchCommunityLeaderboardRequestAction  = createAction('FETCH_COMMUNITY_LEADERBOARD_REQUEST', props<FetchCommunityLeaderboardRequest>());
export const FetchCommunityLeaderboardResponseAction  = createAction('FETCH_COMMUNITY_LEADERBOARD_RESPONSE', props<FetchCommunityLeaderboardResponse>());

export const PatchCommunityLeaderboardRequestAction  = createAction('PATCH_COMMUNITY_LEADERBOARD_REQUEST', props<PatchCommunityRequest>());
export const PatchCommunityLeaderboardResponseAction  = createAction('PATCH_COMMUNITY_LEADERBOARD_RESPONSE', props<PatchCommunityResponse>());

export const ClearSelectedCommunityAction  = createAction('CLEAR_SELECTED_COMMUNITY');