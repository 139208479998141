<ng-container *ngIf="combinedReducers$ | async; let combinedState">
    <form [formGroup]="fg" novalidate [class.modal]="isModal" [class.disable-selection]="!username">
        <app-sync-button
            class="display-flex"
            [icon]="true"
            [pending]="combinedState.session.pending || combinedState.leaderboard.pending"
            [showClear]="true"
            (handleClick)="refreshResults(combinedState.leaderboard.leaderboardFilter)"
            (handleClear)="clearFilters()"
            [noBorder]="true"
            *ngIf="!isModal">
        </app-sync-button>
        <div class="input-field-container clear-white border-right" [class.border-bottom]="fg.value.global">
            <mat-select formControlName="leaderboard" [panelWidth]="null" placeholder="Local">
                <mat-option value="local">Local</mat-option>
                <mat-option value="global">Global</mat-option>
                <ng-container *ngIf="combinedState?.leaderboard?.communityLeaderboards?.length">
                    <mat-option [value]="leaderboard.id" *ngFor="let leaderboard of combinedState.leaderboard.communityLeaderboards">{{leaderboard.name}}</mat-option>
                </ng-container>
            </mat-select>
        </div>
        <div class="divider"><div class="divider-inner"></div></div>
        <div class="input-field-container clear-white"  [class.border-bottom]="fg.value.usr_id_list" *ngIf="combinedState.session.coachedUserList">
            <mat-select formControlName="usr_id_list" [panelWidth]="null" [placeholder]="(fullName ? fullName : (username ? username : 'user'))">
                <mat-option [value]="null">{{fullName ? fullName : username}}</mat-option>
                <ng-container *ngIf="combinedState.session.coachedUserList?.length">
                    <mat-option [value]="user.userID" *ngFor="let user of combinedState.session.coachedUserList">{{user.userFirstname}} {{user.userLastname}}</mat-option>
                </ng-container>
            </mat-select>
        </div>
        <div class="input-field-container clear-white" [class.border-bottom]="fg.value.code && fg.value.code.length">
            <mat-select formControlName="code" [panelWidth]="null" placeholder="Training Mode" (selectionChange)="determineGameType($event, combinedState.session.gameTypeList)">
                <mat-option [value]="null" *ngIf="!combinedState.session.pending && !combinedState.session.gameTypeList?.length" disabled>No modes available</mat-option>
                <ng-container *ngIf="combinedState.session.gameTypeList?.length">
                    <mat-option [value]="gameType.code" *ngFor="let gameType of combinedState.session.gameTypeList">{{gameType.fullName}}</mat-option>
                </ng-container>
            </mat-select>
        </div>
        <div class="input-field-container clear-white" [class.border-bottom]="fg.value.product && fg.value.product.length">
            <mat-select formControlName="product" [panelWidth]="null" placeholder="Product" multiple>
                <mat-option [value]="null" *ngIf="!combinedState.session.pending && !combinedState.session.machineGroupList?.length" disabled>No products available</mat-option>
                <ng-container *ngIf="combinedState.session.machineGroupList?.length">
                    <mat-option [value]="machine.name" *ngFor="let machine of combinedState.session.machineGroupList">{{machine.name}}</mat-option>
                </ng-container>
            </mat-select>
        </div>
        <div class="input-field-container clear-white" [class.border-bottom]="fg.value.duration">
            <mat-select formControlName="duration" [panelWidth]="null" placeholder="Duration">
                <mat-option [value]="null">All</mat-option>
                <mat-option [value]="duration.value" *ngFor="let duration of durationArray">{{duration.label}}</mat-option>
            </mat-select>
        </div>
        <div class="input-field-container clear-white" [class.border-bottom]="fg.value.gender">
            <mat-select formControlName="gender" [panelWidth]="null" placeholder="Gender">
                <mat-option [value]="null">All</mat-option>
                <mat-option value="Male" >Male</mat-option>
                <mat-option value="Female" >Female</mat-option>
            </mat-select>
        </div>
        <div class="input-field-container clear-white" [class.border-bottom]="fg.value.age">
            <mat-select formControlName="age" [panelWidth]="null" placeholder="Age">
                <mat-option [value]="null">All</mat-option>
                <mat-option [value]="number" *ngFor="let number of [5,6,7,8,9,10,11,12,13,14,15,16,17]">{{number}}</mat-option>
                <mat-option [value]="18">18+</mat-option>
            </mat-select>
        </div>
        <div class="input-field-container clear-white white-theme date display-flex" [class.border-bottom]="fg.value.dateRange.start || fg.value.dateRange.finish">
            <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker" [max]="maxDate">
                <input matStartDate formControlName="start" placeholder="Start" (click)="picker.open()">
                <input matEndDate formControlName="finish" placeholder="End" (click)="picker.open()">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </div>
        <!--<div class="input-field-container clear-white" [class.border-bottom]="fg.value.gender">
            <mat-select formControlName="gender" placeholder="Gender">
                <mat-option [value]="null">All</mat-option>
                <mat-option value="male">Male</mat-option>
                <mat-option value="femal">Female</mat-option>
            </mat-select>
        </div>-->
        <div class="input-field-container clear-white"
            *ngIf="isModal && (combinedState.login.user?.esaData?.userFieldPosition || combinedState.login.user?.esaData?.userPreferredFoot)">
            <mat-select formControlName="ownFilter" placeholder="Filter by own" multiple>
                <mat-option value="nation" *ngIf="combinedState.login.user?.esaData?.userNationality && !fg.value.global">Nationality</mat-option>
                <mat-option value="position" *ngIf="combinedState.login.user?.esaData?.userFieldPosition">Position</mat-option>
                <mat-option value="foot" *ngIf="combinedState.login.user?.esaData?.userPreferredFoot">Preferred foot</mat-option>
            </mat-select>
        </div>
    </form>
</ng-container>
