import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, distinct, map, switchMap, toArray } from 'rxjs/operators';

import { ApiService } from './api.service';

import { AlertModel } from '../models/alert.model';
import {
  FriendCommunity,
  FetchCommunityListRequest,
  FetchCommunityListResponse,
} from '../models/friend.model';
// import { LoginService } from './login.service';
import { APIHelper } from '../models/api-models/api-helper.model';
import { DeleteNotificationRequest, DeleteNotificationResponse, FetchNotificationListRequest, FetchNotificationListResponse, MarkNotificationReadRequest, MarkNotificationReadResponse, NotificationModel } from '../models/notification.model';
import { APINotificationModel } from '../models/api-models/api-notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private apiService: ApiService,
    // private loginService: LoginService
  ) {
  }

  fetchNotificationList(req: FetchNotificationListRequest): Observable<FetchNotificationListResponse> {
    return this.apiService.apiGet<any>(`/current-user/notification${req.unread ? '/unread' : ''}`).pipe(
      map((res: Array<unknown>): FetchNotificationListResponse => {
        return {
          error: null,
          notificationArray: res.map((r: unknown): NotificationModel => NotificationModel.fromApiData(r as APINotificationModel)),
        };
      }),
      catchError((err: HttpErrorResponse): Observable<FetchNotificationListResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          notificationArray: null,
        });
      }),
    );
  }
  markNotificationRead(req: MarkNotificationReadRequest): Observable<MarkNotificationReadResponse> {
    return this.apiService.apiPatch<any>(`/current-user/notification${req.notificationId ? `/${req.notificationId}` : ''}`, {read: req.read}).pipe(
      map((res: unknown | Array<unknown>): MarkNotificationReadResponse => {
        let notificationResponse: NotificationModel | Array<NotificationModel>;
        if (req.notificationId) {
          notificationResponse = NotificationModel.fromApiData(res as APINotificationModel);
        }
        else {
          notificationResponse = (res as Array<unknown>).map((r: unknown): NotificationModel => NotificationModel.fromApiData(r as APINotificationModel));
        }
        return {
          error: null,
          notificationResponse,
          notificationId: req.notificationId
        };
      }),
      catchError((err: HttpErrorResponse): Observable<MarkNotificationReadResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          notificationResponse: null,
          notificationId: req.notificationId
        });
      }),
    );
  }

  deleteNotification(req: DeleteNotificationRequest): Observable<DeleteNotificationResponse> {
    return this.apiService.apiDelete<any>(`/current-user/notification${req.notificationId ? `/${req.notificationId}` : ''}`).pipe(
      map((res: unknown | Array<unknown>): DeleteNotificationResponse => {
        return {
          error: null,
          notificationId: req.notificationId
        };
      }),
      catchError((err: HttpErrorResponse): Observable<DeleteNotificationResponse> => {
        return of({
          error: AlertModel.handleApiError(err),
          notificationId: req.notificationId
        });
      }),
    );
  }
}