<ng-container *ngIf="login$ | async; let loginState">
  <div class="primary-nav-container">
    <div class="logo-container text-align-center full-width">
      <img src="assets/logos/esa-logo.svg" alt="Elite Skills Arena" class="full-width" />
    </div>
    <div class="page-navigation display-flex full-width">
      <!--<app-partner-icons class="esa-partner-icons full-width display-flex align-items-center"></app-partner-icons>-->
      <div class="primary-nav display-flex">
        <div class="nav-curve"></div>
        <div class="nav-items display-flex full-width">
          <ng-container *ngIf="loginState.user">
            <button class="menu-link color-white bold" routerLinkActive="active" routerLink="/player">Player</button>
            <button class="menu-link color-white bold" routerLinkActive="active" routerLink="/analysis">Analysis</button>
            <button class="menu-link color-white bold" routerLinkActive="active" routerLink="/leaderboard">Leaderboard</button>
            <button class="menu-link color-white bold" routerLinkActive="active" routerLink="/friends">
              <span
                [matBadge]="loginState?.user?.stats?.invitations?.unread"
                [matBadgeHidden]="!loginState?.user?.stats?.invitations?.unread"
                matBadgeOverlap="false"
                class="display-inline-block">
                Friends
              </span>
            </button>
          </ng-container>
        </div>
        <div class="account display-flex">
          <button class="menu-link color-white bold notification" routerLinkActive="active" routerLink="notifications" [queryParams]="{ type: 'unread'}" *ngIf="loginState.user">
            <span
              [matBadge]="loginState?.user?.stats?.notifications?.unread"
              [matBadgeHidden]="!loginState?.user?.stats?.notifications?.unread"
              matBadgeOverlap="false"
              class="display-inline-block">
              <fa-icon [icon]="bellIcon"></fa-icon>
            </span>
          </button>
          <button 
            class="menu-link color-white bold"
            [routerLink]="loginState.user?.name ? '/player' : '/login'">
            <span>{{loginState.user ? (loginState.user.esaData?.userFirstname ? loginState.user.esaData?.userFirstname : loginState.user.name)  : 'Log-in'}}</span>
            <fa-icon [icon]="userIcon"></fa-icon>
          </button>
        </div>
        <div class="mobile-toggle">
          <button type="button" class="menu-link color-white" (click)="toggleDrawer.emit()" *ngIf="loginState.user">
            <fa-icon [icon]="barIcon"></fa-icon>
          </button>
        </div>
      </div>
      <app-sub-nav
        class="sub-nav" 
        [userId]="loginState.user?.id"
        [username]="loginState.user?.esaUsername"
        [stats]="loginState.user?.stats"
        [esaId]="loginState.user?.esaId"
        [fullName]="loginState.user?.name"
        [currentParentPage]="parentPage"
        [isChild]="isChild"
        (logout)="logout()">
      </app-sub-nav>
    </div>
  </div>
  <div class="page-title full-width display-flex" [class.alert-visible]="loginState.user && !loginState.user.esaUsername" *ngIf="page$ | async; let pageState">
    <h1 class="color-white">{{pageState.pageTitle}}</h1>
  </div>
  <div class="no-username-banner full-width display-flex background-orange" *ngIf="loginState.user && !loginState.user.esaUsername">
    <small class="color-white no-margin full-width"><strong>Link your ESA account to access your profile and statistics</strong></small>
    <button type="button" class="button primary mini hover-white" (click)="openUsernameModal(loginState.user)">
      Link Account
    </button>
  </div>
</ng-container>