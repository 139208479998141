import { AlertModel } from './alert.model';
import { UserAttachmentFileAPIModel } from './api-models/api-user.model';
import { NotificationStatCollection, UserIndexModel, UserProfile } from './profile.model';


export interface ResetPasswordParams {
  token: string;
  email: string;
  ctxid: string;
}

export interface PatchFileModel {
  name: string;
  type: string;
  data: string;
  public: boolean;
  community_public: boolean;
}

export interface UserAttachment {
  id: number;
  attacherId: number;
  attacherType: string;
  public: boolean;
  communityPublic: boolean;
  fileId: string;
  name: string;
  data: {[id: string]: any};
  file?: UserAttachmentFileAPIModel
}

export interface ActivateParams extends ResetPasswordParams {
  uid: string;
}

export interface LoginRequest {
  contextId:    number;
  password:     string | null;
  esaUsername:  string | null;
}

export interface LoginResponse {
  contextId:   number | null;
  error:       AlertModel | null;
  ready:       boolean | null;
  token:       string | null;
  user:        UserProfile | null;
}

export interface TokenCheckRequest {
  token: string;
  accountLink?: boolean
}

export interface TokenCheckResponse {
  ready: boolean | null;
  valid: boolean | null;
  error: AlertModel | null;
  user: UserProfile | null;
  contextId: number | null;
  accountLink: boolean;
}

export interface LogoutRequest {
  message: AlertModel | null;
}

export interface RequestResetPasswordRequest {
  esaUsername: string | null;
  resetType: string | null;
}

export interface RequestResetPasswordResponse {
  error:   AlertModel | null;
  message: AlertModel | null;
}

export class ResetPasswordRequest {
  token!: string;
  email!: string;
  password!: string;
  passwordConfirm!: string;

  static toApiData(appData: ResetPasswordRequest): any {
    return {
      token:                  appData.token,
      email:                  appData.email,
      password:               appData.password,
      password_confirmation:  appData.passwordConfirm,
    };
  }
}

export interface ResetPasswordResponse {
  error:   AlertModel | null;
  message: AlertModel | null;
}

export interface ActivateAccountRequest {
  token: string;
  email: string;
  userId: number;
}

export interface ActivateAccountResponse {
  activated:  boolean | null;
  error:      AlertModel | null;
  message:    AlertModel | null;
}

export class LinkEsaAccountRequest {
  email!:    string;
  password!: string;
  username!: string;

  static toApiData(data: LinkEsaAccountRequest): any {
    return {
      email: data.email,
      password: data.password,
      esa_username: data.username
    };
  }
}
export interface LinkEsaAccountResponse {
  message: AlertModel | null;
  error: AlertModel | null;
  esaUsername: string | null;
}

export interface FetchNotificationStatsResponse {
  notificationStats: NotificationStatCollection | null;
  error: AlertModel | null;
}

export interface FetchUserIndexRequest {
  userId: number;
}
export interface FetchUserIndexResponse {
  indexData: UserIndexModel | null;
  error: AlertModel | null;
}

export interface StoreUserIndexRequest {
  file: PatchFileModel;
  userId: number;
  userName: string;
}
export interface StoreUserIndexResponse {
  error: AlertModel | null;
  userId: number;
  userName: string;
}

export interface FetchUserIndexAttachmentRequest {
  userId: number;
  userName: string;
}
export interface FetchUserIndexAttachmentResponse {
  attachmentUrl: string | null;
  pageUrl: string | null;
  error: AlertModel | null;
}