import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faSync, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sync-button',
  templateUrl: './sync-button.component.html',
  styleUrls: ['./sync-button.component.sass']
})
export class SyncButtonComponent {
  @Input() small: boolean | null = false;
  @Input() pending: boolean | null = false;
  @Input() icon: boolean = false;
  @Input() showClear: boolean = false;
  @Input() noBorder = false;
  @Output() handleClick = new EventEmitter();
  @Output() handleClear = new EventEmitter();

  public syncIcon = faSync;
  public closeIcon = faTimes;
}
