import { createAction, props } from '@ngrx/store';
import {
    FetchCoachedUserListRequest,
    FetchCoachedUserListResponse,
    FetchGameTypeListRequest,
    FetchGameTypeListResponse,
    FetchMachineGroupListResponse,
    FetchMachineListRequest,
    FetchMachineListResponse,
    FetchPlayedGameListResponse,
    FetchPlayedMachineGroupListResponse,
    FetchSessionListRequest,
    FetchSessionListResponse,
    FetchSessionRequest,
    FetchSessionResponse
} from 'src/app/models/session.model';

export const FetchGameTypeListRequestAction  = createAction('FETCH_GAME_TYPE_LIST_REQUEST', props<FetchGameTypeListRequest>());
export const FetchGameTypeListResponseAction  = createAction('FETCH_GAME_TYPE_LIST_RESPONSE', props<FetchGameTypeListResponse>());

export const FetchPlayedGameTypeListRequestAction  = createAction('FETCH_PLAYED_TYPE_LIST_REQUEST', props<FetchGameTypeListRequest>());
export const FetchPlayedGameTypeListResponseAction  = createAction('FETCH_PLAYED_TYPE_LIST_RESPONSE', props<FetchPlayedGameListResponse>());

export const FetchMachineGroupListRequestAction  = createAction('FETCH_MACHINE_GROUP_LIST_REQUEST', props<FetchGameTypeListRequest>());
export const FetchMachineGroupListResponseAction  = createAction('FETCH_MACHINE_GROUP_LIST_RESPONSE', props<FetchMachineGroupListResponse>());

export const FetchPlayedMachineGroupListRequestAction  = createAction('FETCH_PLAYED_MACHINE_GROUP_LIST_REQUEST', props<FetchGameTypeListRequest>());
export const FetchPlayedMachineGroupListResponseAction  = createAction('FETCH_PLAYED_MACHINE_GROUP_LIST_RESPONSE', props<FetchPlayedMachineGroupListResponse>());

export const FetchMachineListRequestAction  = createAction('FETCH_MACHINE_LIST_REQUEST', props<FetchMachineListRequest>());
export const FetchMachineListResponseAction  = createAction('FETCH_MACHINE_LIST_RESPONSE', props<FetchMachineListResponse>());

export const FetchSessionListRequestAction  = createAction('FETCH_SESSION_LIST_REQUEST', props<FetchSessionListRequest>());
export const FetchSessionListResponseAction  = createAction('FETCH_SESSION_LIST_RESPONSE', props<FetchSessionListResponse>());

export const SetSessionFiltersAction  = createAction('SET_SESSION_FILTERS', props<FetchSessionListRequest>());

export const FetchCoachedUserListRequestAction  = createAction('FETCH_COACHED_USER_LIST_REQUEST', props<FetchCoachedUserListRequest>());
export const FetchCoachedUserListResponseAction  = createAction('FETCH_COACHED_USER_LIST_RESPONSE', props<FetchCoachedUserListResponse>());

export const FetchSessionRequestAction  = createAction('FETCH_SESSION_REQUEST', props<FetchSessionRequest>());
export const FetchSessionResponseAction  = createAction('FETCH_SESSION_RESPONSE', props<FetchSessionResponse>());