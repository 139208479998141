import { Action, createReducer, on } from '@ngrx/store';
import { AlertModel } from 'src/app/models/alert.model';
import { APIHelper } from 'src/app/models/api-models/api-helper.model';
import * as actions     from '../actions/notification.actions';
import { LogoutAction, ResetDataAction } from '../actions/login.actions';
import { ClearAllErrorsAndMessages } from '../actions/page.actions';
import { NotificationModel } from 'src/app/models/notification.model';

export interface State {
  pending:       boolean | null;
  pendingTasks:  {[id: string]: boolean};
  error:         AlertModel | null;
  message:       AlertModel | null;
  notifications: Array<NotificationModel> | null;
}

const defaultState: State = {
  pending:       false,
  pendingTasks:  {},
  error:         null,
  message:       null,
  notifications: null,
};

const notificationReducer = createReducer(
  defaultState,
  on(actions.FetchNotificationListRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [actions.FetchNotificationListRequestAction.type]: true },
  })),
  on(actions.FetchNotificationListResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(actions.FetchNotificationListRequestAction.type, state.pendingTasks),
    error:        action.error,
    notifications: action.error ? state.notifications : action.notificationArray,
  })),
  on(actions.MarkNotificationReadRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks,
      [action.notificationId ? `NOTIFICATION_PENDING_${action.notificationId}` : actions.FetchNotificationListRequestAction.type]: true },
  })),
  on(actions.MarkNotificationReadResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(
      action.notificationId ? `NOTIFICATION_PENDING_${action.notificationId}` : actions.FetchNotificationListRequestAction.type,
      state.pendingTasks),
    error:        action.error,
    notifications: action.error ? state.notifications : action.notificationId && state.notifications ? state.notifications.map(n => {
      if (n.id === action.notificationId) {
        return action.notificationResponse as NotificationModel;
      }
      return n;
    }) : action.notificationResponse as Array<NotificationModel>,
  })),
  on(actions.DeleteNotificationRequestAction, (state, action) => ({
    ...state,
    pending: true,
    pendingTasks: {...state.pendingTasks, [action.notificationId ? `NOTIFICATION_PENDING_${action.notificationId}` : actions.FetchNotificationListRequestAction.type]: true },
  })),
  on(actions.DeleteNotificationResponseAction, (state, action) => ({
    ...state,
    pending:      false,
    pendingTasks: APIHelper.removePendingTask(
      action.notificationId ? `NOTIFICATION_PENDING_${action.notificationId}` : actions.FetchNotificationListRequestAction.type, state.pendingTasks),
    error:        action.error,
    notifications: action.error || !state.notifications ? state.notifications : action.notificationId ? state.notifications?.filter(n => n.id !== action.notificationId): [],
  })),
  on(ResetDataAction, (state, action) => ({
    ...state,
    ...defaultState
  })),
  on(LogoutAction, (state, action) => ({
    ...state,
    ...defaultState
  })),
  on(ClearAllErrorsAndMessages, state => ({
    ...state,
    message: null,
    error: null
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return notificationReducer(state, action);
}