export class FilterModel {
  filters?: {[id: string]: any} | null;

  static constructFilterString(filters: {[id: string]: string | Array<string>} | null | undefined): string {
    return !filters ? '' : '?' + Object.keys(filters)
      .filter(key => !!filters[key] && (!Array.isArray(filters[key]) || (Array.isArray(filters[key]) && !!filters[key].length)))
      .map(key => `${key}=${Array.isArray(filters[key]) ? (filters[key] as Array<string>).join('|') : filters[key]}`)
      .reduce((acc: string, val: string) =>  acc + (acc ? '&' : '') + val, '');
  }

  static determineFetchFilters(curFilters: {[id: string]: string} | null | undefined, newFilters: {[id: string]: string} | null | undefined): boolean {
    const oldKeys = curFilters ? Object.keys(curFilters) : null;
    const newKeys = newFilters ? Object.keys(newFilters).filter(key => !!newFilters[key]) : null;

    if ((!newKeys && !oldKeys) || newKeys?.length !== oldKeys?.length) {
      return true;
    }
    else if (newKeys && newFilters && curFilters) {
      for(const key of newKeys) {
        if (curFilters[key] !== newFilters[key]) {
          return true;
        }
      }
    }
    return false;
  }
}