import { ActionReducer } from '@ngrx/store';

import { reducer as login, State as LoginState } from './reducers/login.reducer';
import { reducer as page, State as PageState } from './reducers/page.reducer';
import { reducer as session, State as SessionState } from './reducers/session.reducer';
import { reducer as leaderboard, State as LeaderboardState } from './reducers/leaderboard.reducer';
import { reducer as friend, State as FriendState } from './reducers/friend.reducer';
import { reducer as notification, State as NotificationState } from './reducers/notification.reducer';

export interface StoreState {
  login:    LoginState;
  page:     PageState;
  session:  SessionState;
  leaderboard:  LeaderboardState;
  friend:  FriendState;
  notification: NotificationState;
}

export interface ReducerState {
  login:    ActionReducer<LoginState>;
  page:     ActionReducer<PageState>;
  session:  ActionReducer<SessionState>;
  leaderboard:  ActionReducer<LeaderboardState>;
  friend:  ActionReducer<FriendState>;
  notification: ActionReducer<NotificationState>
}

export const store: ReducerState = {
  login,
  page,
  session,
  leaderboard,
  friend,
  notification
};
