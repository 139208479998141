import { Action, createReducer, on } from '@ngrx/store';
import * as actions     from '../actions/page.actions';

export interface State {
  pageTitle:    string | null;
}

const defaultState: State = {
  pageTitle:    null
};

const pageReducer = createReducer(
  defaultState,
  on(actions.SetPageTitle, (state, action) => ({
    ...state,
    pageTitle: action.title
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return pageReducer(state, action);
}