import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialCommonModule } from '../../../../module-common/material-common.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AlertCardComponent } from './alert-card.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialCommonModule,
    FontAwesomeModule
  ],
  exports: [
    AlertCardComponent
  ],
  declarations: [AlertCardComponent],
})
export class AlertCardModule { }
