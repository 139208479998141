<h2 matDialogTitle>{{dialogData.title}}</h2>
<ng-container  *ngIf="login$ | async; let loginState">
  <mat-dialog-content>
    <app-alert-card [alert]="loginState.error" *ngIf="loginState.error"></app-alert-card>
    <app-alert-card class="remove-card-shadow" [alert]="updateSuccess" *ngIf="updateSuccess"></app-alert-card>

    <form [formGroup]="fg" id="edit-setting-form" #editSettingForm="ngForm" (ngSubmit)="onSubmitForm()" novalidate autocomplete="off" *ngIf="!updateSuccess">
      <ng-container *ngIf="dialogData">
        <div class="input-field-container full-width">
          <input
            [type]="dialogData.valueType"
            [placeholder]="dialogData.valuePlaceholder ? dialogData.valuePlaceholder : ''"
            formControlName="value"
            [class.error]="fg.hasError('no-match') && editSettingForm.submitted">
        </div>
        <div class="input-field-container full-width" *ngIf="dialogData.secondaryType">
            <input 
              [type]="dialogData.secondaryType"
              [placeholder]="dialogData.secondaryPlaceholder ? dialogData.secondaryPlaceholder : ''"
              formControlName="secondaryValue"
              [class.error]="fg.hasError('no-match') && editSettingForm.submitted">
        </div>
        <mat-error class="password-error color-white" *ngIf="fg.hasError('no-match') && editSettingForm.submitted">
          Passwords do not match
        </mat-error>
      </ng-container>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button class="button primary hover-white small" type="button" matDialogClose>Close</button>
    <button class="button primary hover-white small white-theme-not-hover" type="submit" form="edit-setting-form" *ngIf="!updateSuccess">
      <ng-container *ngIf="!loginState.pending; else spinner">
        <span>{{dialogData.closeLabel}}</span>
      </ng-container>
    </button>
  </mat-dialog-actions>
</ng-container>

<ng-template #spinner>
  <mat-spinner strokeWidth="4" diameter="26"></mat-spinner>
</ng-template>
