<div class="nav-items" *ngIf="currentParentPage === 'player'">
  <button class="menu-link color-white" routerLinkActive="active" routerLink="player/profile">Profile</button>
  <button class="menu-link color-white" routerLinkActive="active" routerLink="player/index">Index</button>
  <button class="menu-link color-white" routerLinkActive="active" routerLink="player/edit">Edit Profile</button>
  <button class="menu-link color-white" routerLinkActive="active" routerLink="player/settings">Settings</button>
  <button class="menu-link color-white" (click)="logout.emit()">Log-Out</button>
</div>
<app-analysis-filters [userId]="userId" [username]="username" [fullName]="fullName" [esaId]="esaId" *ngIf="currentParentPage === 'analysis' && !isChild"></app-analysis-filters>
<div class="nav-items" *ngIf="currentParentPage === 'analysis' && isChild">
  <button class="menu-link color-white" routerLink="analysis">Return to Analysis</button>
</div>
<app-leaderboard-filters [userId]="userId" [username]="username" [fullName]="fullName" *ngIf="currentParentPage === 'leaderboard'"></app-leaderboard-filters>
<div class="nav-items" *ngIf="currentParentPage === 'friends'">
  <button class="menu-link color-white" routerLinkActive="active" routerLink="friends/all">Friends</button>
  <button class="menu-link color-white" routerLinkActive="active" routerLink="friends/leaderboards">Leaderboards</button>
  <button class="menu-link color-white" routerLinkActive="active" routerLink="friends/invitations">
    <span
      [matBadge]="stats?.invitations?.unread"
      [matBadgeHidden]="!stats?.invitations?.unread"
      matBadgeOverlap="false"
      class="display-inline-block">
      Invitations
    </span>
  </button>
</div>
<div class="nav-items" *ngIf="currentParentPage === 'notifications'">
  <button class="menu-link color-white" routerLinkActive="active" routerLink="notifications" [queryParams]="{ type: 'unread'}">
    <span
      [matBadge]="stats?.notifications?.unread"
      [matBadgeHidden]="!stats?.notifications?.unread"
      matBadgeOverlap="false"
      class="display-inline-block">
      Unread
    </span>
  </button>
  <button class="menu-link color-white" routerLinkActive="active" routerLink="notifications" [queryParams]="{ type: 'all'}">All</button>
</div>