import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule }  from '@angular/forms';

import { EditSettingModalComponent } from './edit-setting-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertCardModule } from '../../components/general/alert-card/alert-card.module';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatProgressSpinnerModule,
    AlertCardModule
  ],
  declarations: [
    EditSettingModalComponent
  ],
})
export class EditSettingModalModule { }
