import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';

/* To prevent anonymous users from accessing pages requiring a login */
@Injectable()
export class TokenRequiredAuthGuardService  {

  constructor(private router: Router) {}

  canActivate(): boolean {
    const storedToken = window.localStorage.getItem(environment.id + '_login_token');

    if (storedToken) {
        return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}

/* To restrict logged in users from accessing pages such as the login page */
@Injectable()
export class HidePublicAuthGuardService  {

  constructor(
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const storedToken = window.localStorage.getItem(environment.id + '_login_token');

    if (!storedToken) {
        return true;
    }

    this.router.navigate(['/player']);
    return false;
  }
}