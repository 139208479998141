<button class="button primary refresh-icon"
    [class.small]="small"
    [class.icon]="icon"
    [class.spin]="pending"
    (click)="handleClick.emit()">
    <fa-icon [icon]="syncIcon"></fa-icon><span> Refresh</span>
</button>
<button class="button primary refresh-icon"
    [class.small]="small"
    [class.icon]="icon"
    [class.no-border]="noBorder"
    *ngIf="showClear"
    (click)="handleClear.emit()">
    <fa-icon [icon]="closeIcon"></fa-icon>
</button>

