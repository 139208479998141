import { HttpErrorResponse } from "@angular/common/http";

export interface APIErrorResponse {
    message: string;
    validation_errors?: {[id: string]: string};
}

export class AlertModel {
    // title!:     string;
    message!:   string;
    type!:      string;
    listItems?: Array<string>;
    link?: string | null;
    linkText?: string | null;

    static handleApiError(apiError: HttpErrorResponse): AlertModel {
      const alert: AlertModel = new AlertModel();
      const fallbackError = 'There was an issue communicating with the server. Please try again later.';
      const error = apiError.error as APIErrorResponse;

      if (!error || !error.message) {
        alert.message = fallbackError;
        alert.type = 'error';
        return alert;
      }

      // alert.title = 'An error occured';
      alert.message = error.message;
      alert.type = 'error';

      if (error?.validation_errors) {
        alert.listItems = Object.keys(error.validation_errors).map((key: string) => {
          return error.validation_errors && error.validation_errors[key] ? error.validation_errors[key] : '';
        });
      }

      return alert;
    }

    static handleApiMessage(message: string, type: string, link: string | null = null, linkText: string | null = null): AlertModel {
        const alert: AlertModel = new AlertModel();

        // alert.title = title;
        alert.message = message;
        alert.type = type;
        alert.link = link;
        alert.linkText = linkText;

        return alert;
    }
}