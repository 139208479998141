import { Context }        from './context.model';

import { AlertModel } from './alert.model';
import { APICommunityStatModel, APIEsaDataModel, APIMessageStatModel, APIUserDataModel, APIUserMetadataModel, APIUserModel, APIUserPatchModel, APIUserStatsModel } from './api-models/api-user.model';
import { APIHelper } from './api-models/api-helper.model';
import { Validators } from '@angular/forms';
import { FilterModel } from './filter.model';
import * as moment from 'moment';
import { CountryCodes } from './country-code.model';

export interface CoachedUserModel {
  userID: string;
  usrLogin: string;
  userFirstname: string;
  userLastname: string;
}

export interface UserStatsExpaned {
  averageMisses: number;
  averageScore: number;
  count: number;
  maxMisses: number;
  maxScore: number;
  minMisses: number;
  minScore: number;
  passAccuracy: number;
  totalHits: number;
  totalMisses: number;
  totalPasses: number;
  totalScore: number;
}

export class UserStats {
  count?:         number;
  firstSession?:  string;
  lastSession?:   string;
  maxMisses?:     number;
  maxPointSessions?: UserStatsExpaned;
  passAccuracy?:  number;
  totalPasses?:   number;
  totalTime?:     string | null;

  static fromApiData(apiData: UserStats): UserStats {
    return {
      ...apiData,
      totalTime: apiData && apiData.totalTime ? moment.duration(apiData.totalTime, 'seconds').humanize() : null,
      passAccuracy: apiData && apiData.passAccuracy ? +apiData.passAccuracy?.toFixed(2) : 0,
      maxPointSessions: apiData && apiData.maxPointSessions ? {
        ...apiData.maxPointSessions,
        averageScore: apiData.maxPointSessions.averageScore ? +apiData.maxPointSessions.averageScore?.toFixed(0) : 0,
        averageMisses: apiData.maxPointSessions.averageMisses ? +apiData.maxPointSessions.averageMisses?.toFixed(0) : 0
      } : apiData.maxPointSessions
    };
  }
}
/**
 * User Data: class containing data structure and utility functions for
 * secondary profile information.
 */
export class UserData {
  profileConfirmed?: boolean;

  static fromApiData(apiData: APIUserDataModel): UserData {
    return APIHelper.APItoJSON<UserData>(apiData);
  }

  static toApiData(data: UserData): APIUserDataModel {
    return APIHelper.JSONtoAPI<APIUserDataModel>(data);
  }
}

export interface UserPatchData {
  email?: string;
  name?: string;
  telephone?: string;
  password?: string;
  userData?: UserData;
  esaData?: APIEsaDataModel;
  metadata?: Array<APIUserMetadataModel>;
}

export class NotificationStatCollection {
  communities!:   APICommunityStatModel;
  messages!:      APIMessageStatModel;
  invitations!:   APIMessageStatModel;
  joinRequests!:  APIMessageStatModel;
  notifications!: APIMessageStatModel;
  friends!:       APIMessageStatModel;

  static fromApi(data: APIUserStatsModel) {
    return {
      communities: data.communities,
      messages: data.messages,
      invitations: data.invitations,
      joinRequests: data['join-requests'],
      notifications: data.notifications,
      friends: data.friends
    };
  }
}

export class UserIndexModel {
  shooting!: number;
  dribbling!: number;
  passing!: number;
  vision!: number;
  stamina!: number;
  acceleration!: number;
  average!: number;

  static fromApiData(apiData: UserIndexModel): UserIndexModel {
    return {
      ...apiData,
      average: Math.ceil(apiData.average)
    };
  }
}

/**
 * User profile: class containing profile data and utility functions to handle
 * communication between the app and API
 */
export class UserProfile {
  apiContext!:   Context | null;
  apiContextId!: number;
  contextType?:  string;
  email!:        string;
  id!:           number;
  name!:         string;
  pivot!:        any;
  stats?:        NotificationStatCollection | null;
  telephone?:    string;
  type!:         string;
  userData?:     UserData | null;
  esaData?:      APIEsaDataModel;
  countryCode?:  string | null;
  countryName?:  string | null;

  esaUsername?: string;
  esaId?:       string;
  metadata?:     Array<APIUserMetadataModel>;

  static toApiData(appData: UserProfile): APIUserPatchModel {
    const toApiData: APIUserPatchModel = {
      context_type: appData.type,
      email:        appData.email,
      name:         appData.name,
    };
    if (appData.telephone) {
      toApiData.telephone = appData.telephone;
    }
    if (appData.userData) {
      toApiData.user_data = UserData.toApiData(appData.userData);
    }
    if (appData.esaData) {
      toApiData.esa_data = appData.esaData;
    }

    if (appData.esaUsername) {
      toApiData.esa_username = appData.esaUsername;
    }

    return toApiData;
  }

  static toApiPatchData(data: UserPatchData): APIUserPatchModel {
    const apiData: APIUserPatchModel = APIHelper.JSONtoAPI<APIUserPatchModel>(data);

    if (data.userData) {
      apiData.user_data = APIHelper.JSONtoAPI<APIUserDataModel>(data.userData);
    }
    return apiData;
  }

  static fromApiData(apiData: APIUserModel): UserProfile {
    const p: UserProfile = new UserProfile();

    p.apiContext             = apiData.api_context ? (new Context()).fromAPI(apiData.api_context) : null;
    p.apiContextId           = apiData.api_context_id;
    p.contextType            = apiData.effective_context_type;
    p.email                  = apiData.email;
    p.esaUsername            = apiData.esa_username;
    p.esaId                  = apiData.esa_data?.userID;
    p.id                     = apiData.id;
    p.name                   = apiData.name;
    p.pivot                  = apiData.pivot;
    p.stats                  = apiData.stats ? NotificationStatCollection.fromApi(apiData.stats) : null;
    p.telephone              = apiData.telephone;
    p.type                   = apiData.type;
    p.userData               = apiData.user_data ? UserData.fromApiData(apiData.user_data) : null;
    p.esaData                = apiData.esa_data;

    const countryMeta        = apiData.metadata ? apiData.metadata.find(m => m.key === 'country_code') : null;
    const countryName        = countryMeta?.value ? CountryCodes.getNameFromISO2Code(countryMeta.value) : null;

    p.countryCode            = countryMeta ? countryMeta.value : null;
    p.countryName            = countryName ? countryName : null;

    return p;
  }
}
type EDIT_MODE_LINK = 'EDIT_MODE_LINK';
type EDIT_MODE_PATCH = 'EDIT_MODE_PATCH';

export interface EditSettingModalData {
  user: UserProfile;
  mode: EDIT_MODE_LINK | EDIT_MODE_PATCH;
  title: string;
  closeLabel: string;
  valueValidators?: Array<Validators>;
  valueInitial?: string;
  valuePlaceholder?: string;
  valueType: string;
  secondryInitial?: string;
  secondaryValidators?: Array<Validators>;
  secondaryPlaceholder?: string;
  secondaryType?: string;
  formValidators?: Array<Validators>;
  patchKey?: keyof UserPatchData;
}
export interface EditSettingModalResponse {
  value: string;
  secondaryValue: string;
};


export interface PatchUserProfileRequest {
  userId: number;
  patchData: UserPatchData;
  resetUserData?: boolean; // used to reset stats following location change
}
export interface PatchUserProfileResponse {
  error:   AlertModel | null;
  profile: UserProfile | null;
  resetUserData?: boolean;
}

export interface FetchUserStatsRequest extends FilterModel {
  userId: number;
}
export interface FetchUserStatsResponse {
  error: AlertModel | null;
  userStats: UserStats | null;
}