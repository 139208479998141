import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { FetchCommunityListResponse, FriendCommunity, InviteUserToCommunityResponse, RemoveUserFromCommunityResponse, RespondUserInvitationResponse } from 'src/app/models/friend.model';
import { FetchGameTypeListResponse } from 'src/app/models/session.model';

import { FriendService } from '../../services/friend.service';

import * as ActionTypes from '../actions/friend.actions';
import { FetchCommunityLeaderboardListRequestAction } from '../actions/leaderboard.actions';
import { FetchNotificationStatsRequestAction } from '../actions/login.actions';
import { StoreState } from '../store';

@Injectable()
export class FriendEffects {
  constructor(
    private actions$: Actions,
    private friendService: FriendService,
    private store: Store<StoreState>,
    private route: Router
  ) {}

  fetchCommunityList$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchCommunityListRequestAction),
    concatMap(action => this.friendService.fetchCommunityList(action).pipe(
      map(res => ActionTypes.FetchCommunityListResponseAction(res)),
    )),
  ));

  fetchCommunityListResponse$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchCommunityListResponseAction),
    filter((req: FetchCommunityListResponse) => !req.error && !req.ownedCommunity),
    tap((req: FetchCommunityListResponse) => {
        this.store.dispatch(ActionTypes.PostCommunityRequestAction({
          userId: req.userId,
          community: {
            name: 'friend-community',
            public: false,
            data: {type: 'friends'}
          }
        }));
    }
    )
  ), { dispatch: false });

  postCommunity$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PostCommunityRequestAction),
    switchMap(action => this.friendService.postCommunity(action).pipe(
      map(res => ActionTypes.PostCommunityResponseAction(res)),
    )),
  ));

  fetchFriendUserList$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchFriendListRequestAction),
    switchMap(action => this.friendService.fetchFriendUserList(action).pipe(
      map(res => ActionTypes.FetchFriendListResponseAction(res)),
    )),
  ));

  inviteUserToCommunity$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.InviteUserToCommunityRequestAction),
    switchMap(action => this.friendService.inviteUserToCommunity(action).pipe(
      map(res => ActionTypes.InviteUserToCommunityResponseAction(res)),
    )),
  ));

  inviteUserToCommunityResponse$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.InviteUserToCommunityResponseAction),
    filter((req: InviteUserToCommunityResponse) => !req.error),
    tap((req: InviteUserToCommunityResponse) => (
        this.store.dispatch(ActionTypes.FetchUserInvitationListRequestAction())
    ))
  ), { dispatch: false });

  inviteMultipleUsersToCommunity$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.InviteMultipleUsersToCommunityRequestAction),
    switchMap(action => this.friendService.inviteMultipleUsersToCommunity(action).pipe(
      map(res => ActionTypes.InviteMultipleUsersToCommunityResponseAction(res)),
    )),
  ));

  fetchUserInvitationList$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FetchUserInvitationListRequestAction),
    switchMap(() => this.friendService.fetchUserInvitationList().pipe(
      map(res => ActionTypes.FetchUserInvitationListResponseAction(res)),
    )),
  ));

  respondUserInvitation$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.RespondUserInvitationRequestAction),
    switchMap(action => this.friendService.respondUserInvitation(action).pipe(
      map(res => ActionTypes.RespondUserInvitationResponseAction(res)),
    )),
  ));

  respondUserInvitationResponse$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.RespondUserInvitationResponseAction),
    filter((req: RespondUserInvitationResponse) => !req.error),
    tap((req: RespondUserInvitationResponse) => {
      this.store.dispatch(FetchNotificationStatsRequestAction());
      if (req.accept) {
        if (req.invitationType === 'friends') {
          this.store.dispatch(ActionTypes.FetchFriendListRequestAction({}));
        }
        else if (req.invitationType === 'leaderboard') {
          this.store.dispatch(FetchCommunityLeaderboardListRequestAction());
        }
      }
    })
  ), { dispatch: false });

  deleteUserInvitation$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteInvitationRequestAction),
    switchMap(action => this.friendService.deleteUserInvitation(action).pipe(
      map(res => ActionTypes.DeleteInvitationResponseAction(res)),
    )),
  ));

  removeFriend$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.RemoveFriendRequestAction),
    switchMap(action => this.friendService.removeFriend(action).pipe(
      map(res => ActionTypes.RemoveFriendResponseAction(res)),
    )),
  ));

  removeUserFromCommunity$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.RemoveUserFromCommunityRequestAction),
    switchMap(action => this.friendService.removeUserFromCommunity(action).pipe(
      map(res => ActionTypes.RemoveUserFromCommunityResponseAction(res)),
    )),
  ));

  removeUserFromCommunityResponse$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.RemoveUserFromCommunityResponseAction),
    filter((req: RemoveUserFromCommunityResponse) => !req.error),
    withLatestFrom(this.store),
    tap(([action, store]: [RemoveUserFromCommunityResponse, StoreState]) => {
      if (action.userId === store.login.user?.id) {
        this.route.navigate(['friends', 'leaderboards']);
      }
    })
  ), { dispatch: false });
}
