<h2 matDialogTitle>Email Address Required</h2>

<mat-dialog-content>
  <p>Please link an email address to login to the ESA Data Hub.</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="button primary small hover-white" matDialogClose>Close</button>
  <a class="button primary small hover-white" matDialogClose="true" href="https://www.ftsgateway.uk/login.php" target="_blank" rel="noopener nofollow">Manage Account</a>
</mat-dialog-actions>
