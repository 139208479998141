<div class="footer-container">
  <div class="footer-navigation full-width">
    <span class="color-white">&copy; Elite Skills Arena</span>
    <div class="nav-items display-inline-block">
      <!--<a class="menu-link color-white" href="https://www.eliteskillsarena.com/" target="_blank">Privacy Policy</a>-->
      <a class="menu-link color-white" href="https://www.eliteskillsarena.com/contact" target="_blank">Contact us</a>
      <!--<a class="menu-link color-white" href="https://www.eliteskillsarena.com/" target="_blank">Terms & Conditions</a>
      <a class="menu-link color-white" href="https://www.eliteskillsarena.com/" target="_blank">Cookie Policy</a>-->
    </div>
  </div>
  <div class="logo-container">
    <img src="assets/logos/esa-logo.svg" alt="Elite Skills Arena" class="full-width" />
  </div>
</div>
