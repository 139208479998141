<h2 matDialogTitle>Edit Country</h2>
<ng-container *ngIf="login$ | async; let loginState">
    <mat-dialog-content>
        <app-alert-card [alert]="loginState.error" *ngIf="loginState.error"></app-alert-card>
        <app-alert-card class="remove-card-shadow" [alert]="updateSuccess" *ngIf="updateSuccess"></app-alert-card>
        <app-alert-card
            [alert]="countrySwitchAlert"
            *ngIf="!updateSuccess && checkForAccountChange(fg.value.countryCode)">
        </app-alert-card>

        <form [formGroup]="fg" class="flex-form" id="edit-setting-form" #editSettingForm="ngForm" (ngSubmit)="onSubmitForm()" novalidate autocomplete="off" *ngIf="!updateSuccess">
            <div class="input-field-container display-inline-block">
                <input type="text"
                    formControlName="country"
                    autocomplete="country"
                    placeholder="Country"
                    (blur)="blurInput()"
                    [matAutocomplete]="country"
                    autocomplete="no" />
                <mat-autocomplete autoActiveFirstOption #country="matAutocomplete"
                                (optionSelected)="setCountryCode($event)">
                    <mat-option class="capitalize-option" *ngFor="let country of filteredCountries" [value]="country.name">
                        {{country.name}}
                    </mat-option>
                </mat-autocomplete>
                <mat-hint class="color-white" *ngIf="!determineFieldValid('countryCode') && editSettingForm.submitted">Please select a country from the list</mat-hint>
            </div>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button class="button primary hover-white small" type="button" matDialogClose>Close</button>
        <button class="button primary hover-white small white-theme-not-hover" type="submit" form="edit-setting-form" *ngIf="!updateSuccess">
            <ng-container *ngIf="!loginState.pending; else spinner">
            <span>Update</span>
            </ng-container>
        </button>
    </mat-dialog-actions>
</ng-container>

<ng-template #spinner>
    <mat-spinner strokeWidth="4" diameter="26"></mat-spinner>
</ng-template>