<div class="logo-container text-align-center full-width">
    <img src="assets/logos/esa-logo.svg" alt="Elite Skills Arena"/>
</div>
<cdk-accordion class="accordion" *ngIf="login$ | async; let loginState">
    <cdk-accordion-item
        *ngFor="let item of items; let index = index;"
        #accordionItem="cdkAccordionItem"
        class="accordion-item"
        role="button"
        tabindex="0"
        [attr.id]="'accordion-header-' + index"
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body-' + index">
        <div class="accordion-item-header" [class.active]="accordionItem.expanded"  (click)="accordionItem.toggle()">
            <span class="nav-selection color-white" *ngIf="item.children">{{ item.title }}</span>
            <button type="button" class="menu-link nav-selection color-white" [routerLink]="item.url" (click)="toggleDrawer.emit()" *ngIf="!item.children">
                {{ item.title }}
            </button>
            <fa-icon [icon]="chevronDownIcon" class="menu-link color-white" *ngIf="item.children"></fa-icon>
        </div>
        <div
            class="accordion-item-body"
            *ngIf="item.children"
            role="region"
            [style.display]="accordionItem.expanded ? '' : 'none'"
            [attr.id]="'accordion-body-' + index"
            [attr.aria-labelledby]="'accordion-header-' + index">
            <button type="button" class="menu-link nav-selection color-white" *ngFor="let child of item.children" [routerLink]="child.url" (click)="toggleDrawer.emit()">
                {{ child.title }}
            </button>
        </div>
    </cdk-accordion-item>
    <cdk-accordion-item #accordionItem="cdkAccordionItem" class="accordion-item" role="button" tabindex="1" id="accordion-header-friend" [attr.aria-expanded]="accordionItem.expanded" aria-controls="accordion-body-friend">
        <div class="accordion-item-header" [class.active]="accordionItem.expanded" (click)="accordionItem.toggle()">
            <span class="nav-selection color-white" >
                <span
                    [matBadge]="loginState?.user?.stats?.invitations?.unread"
                    [matBadgeHidden]="!loginState?.user?.stats?.invitations?.unread"
                    matBadgeOverlap="false"
                    matBadgeSize="small">
                    Friends
                </span>
            </span>
            <fa-icon [icon]="chevronDownIcon" class="menu-link color-white"></fa-icon>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItem.expanded ? '' : 'none'" id="accordion-body-friend" aria-labelledby="accordion-header-friend">
            <button type="button" class="menu-link nav-selection color-white" routerLink="friends" (click)="toggleDrawer.emit()">
                All Friends
            </button>
            <button type="button" class="menu-link nav-selection color-white" routerLink="friends/leaderboards" (click)="toggleDrawer.emit()">
                Leaderboards
            </button>
            <button type="button" class="menu-link nav-selection color-white" routerLink="friends/invitations" (click)="toggleDrawer.emit()">
                <span
                    [matBadge]="loginState?.user?.stats?.invitations?.unread"
                    [matBadgeHidden]="!loginState?.user?.stats?.invitations?.unread"
                    matBadgeOverlap="false"
                    matBadgeSize="small">
                    Invitations
                </span>
            </button>
        </div>
    </cdk-accordion-item>
    <cdk-accordion-item class="accordion-item" role="button">
        <div class="accordion-item-header">
            <button type="button" class="menu-link nav-selection color-white" routerLink="notifications" [queryParams]="{ type: 'unread'}" (click)="toggleDrawer.emit()">
                <span
                    [matBadge]="loginState?.user?.stats?.notifications?.unread"
                    [matBadgeHidden]="!loginState?.user?.stats?.notifications?.unread"
                    matBadgeOverlap="false"
                    matBadgeSize="small">
                    Notifications
                </span>
            </button>
        </div>
    </cdk-accordion-item>
    <cdk-accordion-item class="accordion-item" role="button">
        <div class="accordion-item-header">
            <button type="button" class="menu-link nav-selection color-white" (click)="logout()">Log out</button>
        </div>
    </cdk-accordion-item>
</cdk-accordion>
<!--<app-partner-icons class="esa-partner-icons full-width display-flex align-items-center"></app-partner-icons>-->

